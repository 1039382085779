import { useMemo, type FC, type PropsWithChildren } from "react";
import { useAnnotationDocument } from "../data";
import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { Link, useParams } from "react-router-dom";
import { adminPaths } from "@app/routing/paths";
import { DocumentType } from "@generated/client/graphql";
import { makeAbsoluteUrl } from "@app/routing/utils";

const LoadingAnnotation: FC<
    PropsWithChildren<{
        hasDocumentName?: boolean;
    }>
> = ({ hasDocumentName }) => {
    return (
        <a className="ml-1 inline-flex items-center gap-1">
            [<Skeleton className="inline-block w-3 h-3" />]
            {hasDocumentName ? (
                <>
                    <Skeleton className="inline-block w-11 h-3" />
                </>
            ) : null}
        </a>
    );
};

export const InlineMessageAnnotation: FC<
    PropsWithChildren<{ messageIdx: number; openAiFileId?: string }>
> = ({ children, messageIdx, openAiFileId }) => {
    const { annotationDocument, isPending, isFetched } =
        useAnnotationDocument(openAiFileId);

    const scrollToLink = () => {
        if (!annotationDocument || isPending) return;

        const linkId = makeHtmlId(
            messageIdx,
            annotationDocument?.uniqueUrl ?? "",
        );

        const linkElement = document.getElementById(linkId);
        if (!linkElement) return;

        linkElement.scrollIntoView({ behavior: "smooth" });
    };

    if (isFetched && !annotationDocument) return null;

    if (isPending) {
        return <LoadingAnnotation />;
    }

    return (
        <a
            className="ml-1 inline-flex items-center gap-1 font-bold cursor-pointer"
            onClick={scrollToLink}
        >
            [{children}]
        </a>
    );
};

export const LinkMessageAnnotation: FC<
    PropsWithChildren<{ messageIdx: number; openAiFileId?: string }>
> = ({ children, messageIdx, openAiFileId }) => {
    const { org_uname } = useParams();
    const { annotationDocument, isPending, isFetched } =
        useAnnotationDocument(openAiFileId);

    const documentLink = useMemo(() => {
        if (!annotationDocument) return "#";

        let documentTypePath: string = adminPaths.custom_documents;
        if (
            annotationDocument.type === DocumentType.Policy ||
            annotationDocument.type === DocumentType.MasterPolicy
        ) {
            documentTypePath = adminPaths.policies;
        }
        if (annotationDocument.type === DocumentType.Procedure) {
            documentTypePath = adminPaths.measures;
        }

        const relativePath = `o/${org_uname}/admin/${documentTypePath}/${annotationDocument.uniqueUrl}`;
        const absolutePath = makeAbsoluteUrl(relativePath);

        return absolutePath;
    }, [annotationDocument]);

    if (isFetched && !annotationDocument) return null;

    if (isPending) {
        return <LoadingAnnotation />;
    }

    return (
        <Link
            className="font-bold"
            id={makeHtmlId(messageIdx, annotationDocument?.uniqueUrl ?? "")}
            to={documentLink}
        >
            [{children}] - {annotationDocument?.name}
        </Link>
    );
};

function makeHtmlId(messageIdx: number, uniqueUrl: string) {
    return `msg-${messageIdx}-ann-${uniqueUrl}`;
}
