import { graphql } from "@generated/client";
import {
    type CreateDatapointGroupInput,
    type CreateEntityDatapointGroupsInput,
    type DeleteDatapointGroupMutation,
    type DeleteManyDatapointGroupsMutation,
} from "../../../generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const GetAllDatapointGroupsQuery = graphql(`
    query GetAllDatapointGroups {
        datapointGroups {
            id
            period
            entity {
                id
                name
            }
            datapoints {
                id
                value
            }
            isDeletable
        }
    }
`);

const CreateDatapointGroupMutation = graphql(`
    mutation createDatapointGroup(
        $period: ReportingPeriod!
        $entityId: String!
    ) {
        createDatapointGroup(input: { period: $period, entityId: $entityId }) {
            id
        }
    }
`);

const CreateEntityDatapointGroupsMutation = graphql(`
    mutation createEntityDatapointGroups(
        $input: CreateEntityDatapointGroupsInput!
    ) {
        createEntityDatapointGroups(input: $input) {
            status
            data {
                id
            }
            error {
                message
            }
        }
    }
`);

const DeleteDatapointGroupMutation = graphql(`
    mutation deleteDatapointGroup($id: String!) {
        deleteDatapointGroup(id: $id) {
            status
            data {
                id
            }
            error {
                message
            }
        }
    }
`);

const DeleteManyDatapointGroupsMutation = graphql(`
    mutation deleteManyDatapointGroups($ids: [String!]!) {
        deleteManyDatapointGroups(ids: $ids) {
            status
            data {
                id
            }
            error {
                message
            }
        }
    }
`);

export class GraphQLDatapointGroupRepository {
    get = async () => {
        return graphqlClientKoyeb.request(
            GetAllDatapointGroupsQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    create = async (input: CreateDatapointGroupInput) => {
        return graphqlClientKoyeb.request(
            CreateDatapointGroupMutation,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    createMany = async (input: CreateEntityDatapointGroupsInput) => {
        return graphqlClientKoyeb.request(
            CreateEntityDatapointGroupsMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    delete = async (id: string): Promise<DeleteDatapointGroupMutation> => {
        return graphqlClientKoyeb.request(
            DeleteDatapointGroupMutation,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    deleteMany = async (
        ids: string[],
    ): Promise<DeleteManyDatapointGroupsMutation> => {
        return graphqlClientKoyeb.request(
            DeleteManyDatapointGroupsMutation,
            { ids },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
