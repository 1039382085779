import { GraphQLOrganizationRepository } from "@app/repositories/GraphQLRepositories/OrganizationRepository";
import { useQuery } from "@tanstack/react-query";

const OrganizationRepository = new GraphQLOrganizationRepository();

export const useOrg = (uname: string | null) =>
    useQuery({
        queryFn: () => OrganizationRepository.getOrg().then((res) => res.org),
        queryKey: ["org", uname],
        enabled: !!uname,
    });
