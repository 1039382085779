import { GraphQLAllMyFilesRepository } from "@app/repositories/GraphQLRepositories/AllMyFilesRepository";
import { GraphQLS3FileRepository } from "@app/repositories/GraphQLRepositories/S3FileRepository";
import { useMutation, useQuery } from "@tanstack/react-query";

const AllMyFilesRepository = new GraphQLAllMyFilesRepository();
const FileRepository = new GraphQLS3FileRepository();

export function useGetEvidenceFiles() {
    const { data, ...query } = useQuery({
        queryKey: ["getEvidenceFiles"],
        queryFn: () => AllMyFilesRepository.getEvidenceFiles(),
    });
    return {
        ...query,
        evidenceFiles: data?.evidenceFiles ?? [],
    };
}

export function useExportFiles() {
    const mutation = useMutation({
        mutationKey: ["exportDocuments"],
        mutationFn: FileRepository.exportMany,
    });
    return mutation;
}
