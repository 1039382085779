import { Icon } from "@design-system/Icon";
import { FlexCol } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME, keys } from "./i18n";
import { useDatapointGroupsOfEntity } from "../../data";
import { FC } from "react";

type AddPeriodBoxProps = {
    selectedYear: number;
};

export const AddPeriodBox: FC<AddPeriodBoxProps> = ({ selectedYear }) => {
    const datapointGroupsOfEntity = useDatapointGroupsOfEntity();
    const datapointGroupsOfSelectedYear = datapointGroupsOfEntity.filter(
        (group) => group.period.year === selectedYear,
    );

    const { t } = useTranslation(RESOURCE_NAME);
    return (
        !datapointGroupsOfSelectedYear.length && (
            <FlexCol
                gap="2"
                py="10"
                alignItems="center"
                justifyContent="center"
                br="lg"
                className="bg-neutral-25 border border-neutral-150 text-primary"
            >
                <Icon name="calendar" size="sm" />
                <Text variant="body-sm">{t(keys.add_new_periods_start)}</Text>
            </FlexCol>
        )
    );
};
