import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Xmark } from "iconoir-react";
import { ReactNode } from "react";
import { ButtonType } from "../Button/ButtonShared";
import ButtonSmall from "../Button/ButtonSmall";

interface DropdownProps {
    triggerIcon?:
        | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
        | React.ForwardRefExoticComponent<any>;
    triggerText: string;
    isValueSelected: boolean;
    selectedValue: string;
    children: React.ReactNode;
    onCancelClick?: (event: React.MouseEvent) => void;
}

export default function Dropdown({
    triggerIcon,
    triggerText,
    isValueSelected,
    selectedValue,
    onCancelClick,
    children,
}: DropdownProps) {
    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <ButtonSmall
                    variant={
                        isValueSelected
                            ? ButtonType.DEFAULT
                            : ButtonType.OUTLINED
                    }
                    text={selectedValue || triggerText}
                    IconRight={isValueSelected ? Xmark : triggerIcon}
                    onRightClick={onCancelClick}
                />
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    sideOffset={4}
                    alignOffset={-4}
                    className="border w-[220px] bg-white shadow-dropdown rounded-xl p-1"
                >
                    {children}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
}

export const DropdownRoot = DropdownMenu.Root;
export const DropdownContentLayout = ({
    children,
}: {
    children: ReactNode;
}) => (
    <DropdownMenu.Portal>
        <DropdownMenu.Content
            sideOffset={4}
            alignOffset={-4}
            className="border w-[220px] bg-white shadow-dropdown rounded-xl p-1"
        >
            {children}
        </DropdownMenu.Content>
    </DropdownMenu.Portal>
);
