import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";

import { ReactNode } from "react";

import Checkbox from "../../../../Checkbox";
import BaseItem from "./BaseItem";

export default function CheckboxItem({
    children,
    ...props
}: {
    children: ReactNode;
} & DropdownPrimitive.DropdownMenuCheckboxItemProps) {
    return (
        <BaseItem
            {...props}
            className="cursor-pointer group"
            IconLeft={() => (
                <Checkbox checked={props.checked === true ? true : false} />
            )}
            {...props}
        >
            {children}
        </BaseItem>
    );
}
