import { UserRole } from "@generated/client/graphql";
import i18next from "../../../i18n";

i18next.addResources("en", "RoleSelect", {
    placeholder: "Select role",
    [UserRole.BeavrAdmin]: "Beavr Admin",
    [UserRole.Admin]: "Admin",
    [UserRole.Contributor]: "Contributor",
    [UserRole.Supplier]: "Supplier",
});
i18next.addResources("fr", "RoleSelect", {
    placeholder: "Sélectionner un rôle",
    [UserRole.BeavrAdmin]: "Beavr admin",
    [UserRole.Admin]: "Admin",
    [UserRole.Contributor]: "Contributeur",
    [UserRole.Supplier]: "Fournisseur",
});
