import { HocuspocusProvider } from "@hocuspocus/provider";

import {
    Document,
    Dropcursor,
    Focus,
    Heading,
    Highlight,
    HorizontalRule,
    ImageBlock,
    ImageUpload,
    Placeholder,
    Selection,
    SlashCommand,
    StarterKit,
    Subscript,
    Superscript,
    Table,
    TableCell,
    TableHeader,
    TableRow,
    TaskItem,
    TaskList,
    TextAlign,
    TextStyle,
    Typography,
    Underline,
} from ".";
import { FontSize } from "./FontSize";

interface ExtensionKitProps {
    provider?: HocuspocusProvider | null;
    userId?: string;
    userName?: string;
    userColor?: string;
}

export const ExtensionKit = ({ provider }: ExtensionKitProps) => [
    Document,
    TaskList,
    TaskItem.configure({
        nested: true,
    }),
    Highlight,
    Selection,
    Heading.configure({
        levels: [1, 2, 3, 4, 5, 6],
    }),
    HorizontalRule,
    StarterKit.configure({
        document: false,
        dropcursor: false,
        heading: false,
        horizontalRule: false,
        blockquote: false,
        history: false,
        codeBlock: false,
    }),
    TextStyle,
    FontSize,
    Underline,
    ImageUpload.configure({
        clientId: provider?.document?.clientID,
    }),
    ImageBlock,

    TextAlign.extend({
        addKeyboardShortcuts() {
            return {};
        },
    }).configure({
        types: ["heading", "paragraph"],
    }),
    Subscript,
    Superscript,
    Table,
    TableCell,
    TableHeader,
    TableRow,
    Typography,
    Placeholder.configure({
        includeChildren: true,
        showOnlyCurrent: false,
        placeholder: () => "",
    }),
    SlashCommand,
    Focus,
    Dropcursor.configure({
        width: 2,
        class: "ProseMirror-dropcursor border-black",
    }),
];

export default ExtensionKit;
