import { AssistantStream } from "openai/lib/AssistantStream";
import { AccessHeadersHolder } from "../AccessHeadersHolder";

type AskAiInput = { prompt: string; threadId: string };
export class RestAiRepository {
    decoder = new TextDecoder();

    sendMessage = async (
        prompt: string,
        openAiThreadId: string,
    ): Promise<AssistantStream> => {
        const askAiInput: AskAiInput = { prompt, threadId: openAiThreadId };

        const headers = await AccessHeadersHolder.getHeaders();
        const response = await fetch(
            import.meta.env.VITE_REACT_APP_REST_URL + "ai/thread/sendMessage",
            {
                method: "POST",
                body: JSON.stringify(askAiInput),
                headers: {
                    "Content-Type": "application/json",
                    ...headers,
                },
            },
        );
        if (!response.body) throw new Error('Response incomplete');
        if (!response.ok) throw new Error('Server error')

        return AssistantStream.fromReadableStream(response.body);
    };
}
