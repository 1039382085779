import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";

export const ObjectiveHeaderSkeleton = () => {
    return (
        <FlexCol gap="1">
            <FlexRow py="1.5" className="h-[44px]" alignItems="center">
                <Skeleton h="4" w="full" br="lg" />
            </FlexRow>
            <FlexRow gap="4" alignItems="center" className="h-[26px]">
                <Skeleton h="3" w="12" br="lg" />
                <Skeleton h="3" w="12" br="lg" />
                <Skeleton h="3" w="8" br="lg" />
                <Skeleton h="3" w="12" br="lg" />
            </FlexRow>
        </FlexCol>
    );
};
