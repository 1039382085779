import { graphql } from "@generated/client";
import {
    type CreateManyVersionsInput,
    type DocumentVersionPatch,
    type GetLastVersionByDocumentUrlQuery,
    type MutationUpdateDocumentVersionCheckArgs,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const GetLastVersionByDocumentUrlQuery = graphql(`
    query getLastVersionByDocumentUrl($documentUrl: String!) {
        lastVersion(url: $documentUrl) {
            id
            lastEditedAt
            finalFile {
                signedUrl
                name
                extension
            }
            tipTapDocId
            tipTapJwt
            withEditor
            status
            document {
                id
                description
                name
                type
                cmsDocumentId
                tier
                uniqueUrl
                tier
                children {
                    name
                    type
                    uniqueUrl
                    lastVersion {
                        status
                        tiptapData
                        finalFile {
                            signedUrl
                        }
                    }
                }
                parents {
                    name
                    type
                    uniqueUrl
                    lastVersion {
                        status
                        tiptapData
                        finalFile {
                            signedUrl
                        }
                    }
                }
                templates {
                    tiptapDocId
                    tiptapDocJwt
                    locale
                }
            }
            checks {
                checkType
                checked
                id
            }
            checkpoints {
                timestamp
                data
            }
            status
        }
    }
`);

const UpdateVersionCheckMutation = graphql(`
    mutation UpdateVersionCheck(
        $id: String!
        $set: DocumentVersionCheckPatch!
    ) {
        updateDocumentVersionCheck(id: $id, set: $set) {
            id
            checkType
            checked
        }
    }
`);

const UpdateVersionMutation = graphql(`
    mutation UpdateVersion($versionId: String!, $patch: DocumentVersionPatch!) {
        updateDocumentVersion(id: $versionId, set: $patch) {
            id
        }
    }
`);

const UpdateDocumentDataMutation = graphql(`
    mutation UpdateDocumentData($versionId: String!) {
        updateDocumentData(id: $versionId) {
            id
        }
    }
`);

const AddTipTapDocMutation = graphql(`
    mutation AddTipTapDoc($versionId: String!) {
        addTipTapDoc(id: $versionId) {
            id
        }
    }
`);

const CreateManyVersionsMutation = graphql(`
    mutation createManyVersions($set: CreateManyVersionsInput!) {
        createManyVersions(set: $set) {
            id
        }
    }
`);

export class GraphQLVersionRepository {
    async addTipTapDoc({ versionId }: { versionId: string }) {
        return graphqlClientKoyeb.request(
            AddTipTapDocMutation,
            { versionId },
            await AccessHeadersHolder.getHeaders(),
        );
    }
    async createMany({ documentVersions }: CreateManyVersionsInput) {
        return graphqlClientKoyeb.request(
            CreateManyVersionsMutation,
            { set: { documentVersions } },
            await AccessHeadersHolder.getHeaders(),
        );
    }
    getLastByDocumentURL = async (
        documentUrl: string,
    ): Promise<GetLastVersionByDocumentUrlQuery> =>
        graphqlClientKoyeb.request(
            GetLastVersionByDocumentUrlQuery,
            { documentUrl },
            await AccessHeadersHolder.getHeaders(),
        );
    async update({ id, patch }: { id: string; patch: DocumentVersionPatch }) {
        return graphqlClientKoyeb.request(
            UpdateVersionMutation,
            { versionId: id, patch },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async updateDocumentData(args: { id: string }) {
        return graphqlClientKoyeb.request(
            UpdateDocumentDataMutation,
            { versionId: args.id },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async updateVersionCheck({
        id,
        set,
    }: MutationUpdateDocumentVersionCheckArgs) {
        return graphqlClientKoyeb.request(
            UpdateVersionCheckMutation,
            { id, set },
            await AccessHeadersHolder.getHeaders(),
        );
    }
}
