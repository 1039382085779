import i18next from "i18next";

i18next.addResourceBundle("en", "ReportingPage", {
    page_title: "Reporting",
    page_subtitle: "Manage your reporting campaigns",
    add_period_button_title: "Add new period",
    periods_title: "Periods:",
    no_period_warning: "Select a period to see datapoints",
    no_period: "Add a new period to see datapoints",
    add_period_modal_title: "Add new periods",
    add_period_modal_confirm: "Confirm",
    add_period_modal_no_more_periods: "No more periods to add",
    indicator_table_indicator_column_title: "Indicator",
    indicator_table_requirements_column_title: "Requirements",
    indicator_table_frameworks_column_title: "Frameworks",
    indicator_table_frameworks_column_title_active: "Frameworks ({{count}})",
    indicator_table_tags_column_title: "Tags",
    indicator_table_tags_column_title_active: "Tags ({{count}})",
    indicator_table_subtheme_column_title: "Subtheme",
    indicator_table_assigned_to_column_title: "Owner",
    indicator_table_action_send_reminder_action: "Send reminder",
    indicator_table_action_send_reminder: "Send a reminder to {{count}} users",
    indicator_table_action_assign_owner_action: "Assign to",
    indicator_table_action_assign_owner: "Assign {{count}} indicators",
    indicator_table_action_mark_not_relevant_action: "Not relevant",
    indicator_table_action_mark_not_relevant:
        "Mark {{count}} indicators as not relevant",
    indicator_table_action_selection: "Selection ({{count}})",
    indicator_table_action_select_action: "Select action",
    indicator_table_action_select_act: "Select action",
    apply: "Apply",
    indicator_table_action_update_indicator_success:
        "Successfully updated indicators",
    indicator_table_action_assign_tags_action: "Assign tags",
    see_not_relevant: "See {{count}} not relevant",
    not_relevant_modal_title: "Indicators marked as not relevant",
    set_relevant: "Mark {{count}} as relevant",
    no_data_available: "No data",
    no_data_available_checkbox: "No data available",
    comment_trigger: "Comment",
    update_success: "Data updated successfully",
    update_error: "Error updating data",
    update_no_internet:
        "Error updating data, please check your internet connection and try again",
    export_reporting: "Export reporting",
    export_reporting_files: "Export reporting files",
    period_type: "Type",
    year: "Whole year",
    Year: "Year",
    month: "Month",
    Month: "Month",
    quarter: "Quarter",
    add_period_success: "Period added successfully",
    add_period_error:
        "Error adding period. Make sure the period does not already exist",
    select_periods: "Periods",
    cancel: "Cancel",
    select: "Select",
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
    add_custom_indicators: "Add custom indicators",
    create_indicators_success: "Success creating {{count}} indicators",
    create_indicators_error: "Error creating indicators",
    add_periods_warn: "Start by adding some periods to your entity",
    select_periods_warn: "Select periods to display data",
    to_fill_only: "Missing data",
    no_indicator_found_title: "No indicator for this search",
    no_indicator_found_subtitle:
        "Try changing some parameters or adding indicators",
});

i18next.addResourceBundle("fr", "ReportingPage", {
    page_title: "Reporting",
    page_subtitle: "Gérez vos campagnes de reporting",
    add_period_button_title: "Ajouter une période",
    periods_title: "Périodes:",
    no_period_warning: "Sélectionnez une période pour voir les données",
    no_period: "Ajouter une nouvelle période pour voir les points de données",
    add_period_modal_title: "Ajouter des nouvelles périodes",
    add_period_modal_confirm: "Confirmer",
    add_period_modal_no_more_periods: "Plus de périodes à ajouter",
    indicator_table_indicator_column_title: "Indicateur",
    indicator_table_requirements_column_title: "Contrôles",
    indicator_table_frameworks_column_title: "Référentiels",
    indicator_table_frameworks_column_title_active: "Référentiels ({{count}})",
    indicator_table_tags_column_title: "Tags",
    indicator_table_tags_column_title_active: "Tags ({{count}})",
    indicator_table_subtheme_column_title: "Sous thème",
    indicator_table_assigned_to_column_title: "Propriétaire",
    indicator_table_action_send_reminder_action: "Envoyer un rappel",
    indicator_table_action_send_reminder:
        "Envoyer un rappel à {{count}} utilisateurs",
    indicator_table_action_assign_owner_action: "Assigner à",
    indicator_table_action_assign_owner: "Assigner {{count}} indicateurs",
    indicator_table_action_update_indicator_success:
        "Indicateurs mis à jour avec succès",
    indicator_table_action_mark_not_relevant_action: "Non pertinent",
    indicator_table_action_mark_not_relevant:
        "Marquer {{count}} indicateurs comme non pertinents",
    indicator_table_action_selection: "Sélection ({{count}})",
    indicator_table_action_select_action: "Choisir une action",
    indicator_table_action_assign_tags_action: "Assigner des tags",
    apply: "Enregistrer",
    see_not_relevant: "Voir {{count}} non pertinents",
    not_relevant_modal_title: "Indicateurs marqués comme non pertinents",
    set_relevant: "Marquer {{count}} comme pertinents",
    no_data_available: "Pas de donnée",
    no_data_available_checkbox: "Donnée non disponible",
    comment_trigger: "Commentaire",
    update_success: "Données mises à jour avec succès",
    update_error: "Erreur lors de la mise à jour des données",
    update_no_internet:
        "Erreur lors de la mise à jour des données, veuillez vérifier votre connexion internet et réessayer",
    export_reporting: "Exporter le reporting",
    export_reporting_files: "Exporter les fichiers de reporting",
    period_type: "Type",
    year: "Année entière",
    Year: "Année",
    month: "Mois",
    Month: "Mois",
    quarter: "Quarter",
    add_period_success: "Periode ajoutée avec succès",
    add_period_error:
        "Erreur lors de l'ajout de la période. Assurez vous qu'elle n'éxiste pas déjà",
    select_periods: "Périodes",
    cancel: "Annuler",
    select: "Sélectionner",
    January: "Janvier",
    February: "Février",
    March: "Mars",
    April: "Avril",
    May: "Mai",
    June: "Juin",
    July: "Juillet",
    August: "Août",
    September: "Septembre",
    October: "Octobre",
    November: "Novembre",
    December: "Décembre",
    add_custom_indicators: "Ajouter des indicateurs personnalisés",
    create_indicators_success: "Succès de la création de {{count}} indicateurs",
    create_indicators_error: "Erreur lors de la création d'indicateurs",
    add_periods_warn: "Commencez par ajouter des périodes à votre entité",
    select_periods_warn: "Sélectionnez des périodes pour afficher des données",
    to_fill_only: "Données manquantes",
    no_indicator_found_title: "Aucun indicateur pour cette recherche",
    no_indicator_found_subtitle:
        "Essayez de changer des paramètres ou d'ajouter des indicateurs",
});
