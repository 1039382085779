import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import chroma from "chroma-js";
import { useTranslation } from "react-i18next";
import {
    SupplierQuestionnaire,
    SupplierQuestionnaireStatus,
} from "../../../../generated/client/graphql";
import ActionWithConfirmation from "../../../components/Modal/ActionWithConfirmation";
import { useUpdateSupplierQuestionnaire } from "./hooks/updateSupplierQuestionnaire";

const CloseQuestionnaireButton = ({
    supplierQuestionnaire,
    color,
}: {
    supplierQuestionnaire?: SupplierQuestionnaire;
    color: chroma.Color;
}) => {
    const { t } = useTranslation("SupplierContribution");

    const { updateQuestionnaire, isPending } = useUpdateSupplierQuestionnaire(
        supplierQuestionnaire?.id,
    );
    const navgateToBeavr = () => {
        window.location.href = "https://beavr.tech";
    };
    const showClose =
        supplierQuestionnaire?.status ===
            SupplierQuestionnaireStatus.InProgress ||
        supplierQuestionnaire?.status ===
            SupplierQuestionnaireStatus.NotStarted;
    return showClose ? (
        <FlexRow gap="2">
            <Button variant="outlined" onClick={navgateToBeavr}>
                {t("close_questionnaire_save_and_close")}
            </Button>
            <ActionWithConfirmation
                TriggerButton={({ onClick }) => (
                    <Button
                        loading={isPending}
                        style={{ backgroundColor: color.css() }}
                        onClick={onClick}
                    >
                        {t("send_questionnaire_for_review")}
                    </Button>
                )}
                confirmButton={
                    <Button
                        onClick={() =>
                            updateQuestionnaire({
                                status: SupplierQuestionnaireStatus.Closed,
                            })
                        }
                    >
                        {t("send_questionnaire_for_review")}
                    </Button>
                }
                title={t("send_questionnaire_for_review_title")}
            >
                {t("send_questionnaire_for_review_content")}
            </ActionWithConfirmation>
        </FlexRow>
    ) : (
        <></>
    );
};

export default CloseQuestionnaireButton;
