import { graphql } from "@generated/client";
import {
    type CreateFileInput,
    type GetSuggestedFilesInput,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const CreateEmptyS3FileMutation = graphql(`
    mutation createEmptyS3File($filename: String!) {
        createEmptyS3File(input: { name: $filename }) {
            bucket
            key
            putUrl
            signedUrl
        }
    }
`);

const CreateManyEmptyS3FilesMutation = graphql(`
    mutation createManyEmptyS3Files($filenames: [String!]!) {
        createManyEmptyS3Files(input: { names: $filenames }) {
            bucket
            key
            putUrl
            signedUrl
        }
    }
`);

const CreateS3FilesAsContributorMutation = graphql(`
    mutation createS3FilesAsContributor(
        $userId: String
        $questionnaireId: String
        $filenames: [String!]!
    ) {
        createS3FilesAsContributor(
            input: {
                userId: $userId
                questionnaireId: $questionnaireId
                filenames: $filenames
            }
        ) {
            bucket
            key
            putUrl
            signedUrl
        }
    }
`);

const CreateOneFileMutation = graphql(`
    mutation createOneFile($input: CreateFileInput!) {
        createFile(input: $input) {
            id
            signedUrl
        }
    }
`);

const DeleteS3FileMutation = graphql(`
    mutation deleteS3File($id: String!) {
        deleteFile(id: $id) {
            id
        }
    }
`);

const DeleteS3FilesMutation = graphql(`
    mutation deleteS3Files($ids: [String!]!) {
        deleteFiles(ids: $ids)
    }
`);

const GetSuggestedFilesQuery = graphql(`
    query GetSuggestedFiles($input: GetSuggestedFilesInput!) {
        suggestedFiles(input: $input) {
            types
            file {
                id
                name
                filename
                signedUrl
                fileSize
                mimeType
                extension
                documentVersions {
                    id
                    document {
                        name
                    }
                }
            }
        }
    }
`);

const GetEvidenceFilesQuery = graphql(`
    query GetEvidenceFiles {
        evidenceFiles {
            id
            name
            filename
            signedUrl
            fileSize
            mimeType
            extension
            documentVersions {
                id
                document {
                    name
                }
            }
        }
    }
`);

const ExportFilesMutation = graphql(`
    mutation exportFiles($ids: [String!]!) {
        exportFiles(ids: $ids) {
            signedUrl
        }
    }
`);
export class GraphQLS3FileRepository {
    async uploadToBucket(file: File, { url }: { url: string }) {
        await fetch(url, {
            method: "PUT",
            body: file,
            headers: {
                "Content-Length": file.size.toString(),
                "Access-Control-Allow-Origin": "no-cors",
            },
        });
    }
    async createOneS3File(filename: string) {
        return graphqlClientKoyeb.request(
            CreateEmptyS3FileMutation,
            { filename },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async createManyS3file(filenames: string[]) {
        return graphqlClientKoyeb.request(
            CreateManyEmptyS3FilesMutation,
            { filenames },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async createManyS3fileAsContributor(input: {
        filenames: string[];
        userId?: string;
        questionnaireId?: string;
    }) {
        return graphqlClientKoyeb.request(
            CreateS3FilesAsContributorMutation,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async createOneFile(input: CreateFileInput) {
        return graphqlClientKoyeb.request(
            CreateOneFileMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async deleteOne(id: string) {
        return graphqlClientKoyeb.request(
            DeleteS3FileMutation,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async deleteMany(ids: string[]) {
        return graphqlClientKoyeb.request(
            DeleteS3FilesMutation,
            { ids },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async getSuggestedFiles(input: GetSuggestedFilesInput) {
        return graphqlClientKoyeb.request(
            GetSuggestedFilesQuery,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async getEvidenceFiles() {
        return graphqlClientKoyeb.request(
            GetEvidenceFilesQuery,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    }
    exportMany = async (input: { ids: string[] }) => {
        return graphqlClientKoyeb.request(
            ExportFilesMutation,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
