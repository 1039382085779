import { useSetToast } from "@app/components/Toast";
import { currentUserAtom } from "@app/store/userStore";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Calendar } from "@design-system/Inputs/Calendar";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { GetObjectiveQuery } from "@generated/client/graphql";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@radix-ui/react-popover";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useAtomValue } from "jotai";
import { ChangeEvent, FC, KeyboardEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateObjectiveTarget } from "../../data";
import { Target } from "../Target";
import { RESOURCE_NAME, keys } from "./i18n";

type ObjectiveTargetsProps = {
    objective: GetObjectiveQuery["objective"];
};

export const ObjectiveTargets: FC<ObjectiveTargetsProps> = ({ objective }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    dayjs.extend(LocalizedFormat);
    const totalTargets = objective.targets.length;
    const currentUser = useAtomValue(currentUserAtom);
    const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
    const [isEditingNewValue, setIsEditingNewValue] = useState(false);
    const [newValue, setNewValue] = useState<number | undefined>(undefined);
    const [newDate, setNewDate] = useState<Date | undefined>(undefined);
    const displayDate = newDate ? dayjs(newDate).format("LL") : undefined;
    const handleNewValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "") {
            setNewValue(undefined);
        } else if (!isNaN(Number(event.target.value))) {
            setNewValue(Number(event.target.value));
        }
    };

    const handleNewValueBlur = () => {
        setIsEditingNewValue(false);
    };
    const handleNewValueKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleNewValueBlur();
        }
    };
    const handleValueEditClick = () => setIsEditingNewValue(true);
    const handleAddButtonClick = () => setIsAddMenuOpen(true);
    const handleCancelButtonClick = () => {
        setIsAddMenuOpen(false);
        setNewValue(undefined);
        setNewDate(undefined);
    };
    const { setToastSuccess, setToastError } = useSetToast();
    const handleCreateButtonClick = () => {
        setIsAddMenuOpen(false);
        setIsEditingNewValue(false);
        setNewValue(undefined);
        setNewDate(undefined);
        createObjectiveTarget(
            {
                objectiveId: objective.id,
                value: { number: newValue },
                date: newDate,
            },
            {
                onSuccess: () => {
                    setToastSuccess(t(keys.update_success));
                },
                onError: () => {
                    setToastError(t(keys.update_error));
                },
            },
        );
    };
    const { mutate: createObjectiveTarget } = useCreateObjectiveTarget(
        objective?.id,
    );

    const sortedTargets = useMemo(
        () =>
            objective?.targets?.sort(
                (a, b) =>
                    new Date(a.date).getTime() - new Date(b.date).getTime(),
            ),
        [objective?.targets],
    );

    return (
        <FlexCol w="full" gap="5">
            <FlexRow justifyContent="between" alignItems="end">
                <FlexRow alignItems="center" className="gap-[16px]">
                    <Text variant="body-bold">{t(keys.targets_subtitle)}</Text>
                </FlexRow>
                <Tooltip delayDuration={0}>
                    <Tooltip.Trigger
                        className={cn(
                            !objective.indicatorId ? "cursor-default" : "",
                        )}
                    >
                        <Button
                            size="sm"
                            variant="plain"
                            onClick={handleAddButtonClick}
                            disabled={!objective.indicatorId}
                            className={cn(
                                !objective.indicatorId ? "cursor-default" : "",
                            )}
                        >
                            <Text
                                variant="body-sm"
                                color={
                                    !objective.indicatorId
                                        ? "secondary"
                                        : "dark"
                                }
                            >
                                {t(keys.add_target)}
                            </Text>
                            <Icon
                                name="plus"
                                size="sm"
                                className={cn(
                                    !objective.indicatorId
                                        ? "text-secondary"
                                        : "",
                                )}
                            />
                        </Button>
                    </Tooltip.Trigger>
                    {!objective.indicatorId && (
                        <Tooltip.Content>
                            {t(keys.no_indicator_targets)}
                        </Tooltip.Content>
                    )}
                </Tooltip>
            </FlexRow>
            <hr />
            <FlexRow
                px="3"
                py="2.5"
                gap="3"
                br="lg"
                className="bg-accent-100 border border-accent-450"
            >
                <Icon name="stars" size="sm" className="text-accent-700" />
                <Text variant="body-sm">{t(keys.targets_guidance)}</Text>
            </FlexRow>
            {isAddMenuOpen && (
                <FlexCol
                    p="3"
                    alignItems="start"
                    gap="2"
                    className="rounded-lg border border-tertiary bg-secondary"
                >
                    <FlexRow className="h-[28px]" p="0.5" alignItems="center">
                        {isEditingNewValue ? (
                            <FlexRow alignItems="center">
                                <input
                                    type="number"
                                    value={newValue}
                                    onChange={handleNewValueChange}
                                    onBlur={handleNewValueBlur}
                                    onKeyDown={handleNewValueKeyDown}
                                    autoFocus
                                    className="p-0 text-[14px] border-none w-fit focus:ring-0 text-secondary bg-secondary [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                />
                                <Text
                                    variant="body"
                                    onClick={handleValueEditClick}
                                >
                                    {objective?.indicator?.unit?.shortName}
                                </Text>
                            </FlexRow>
                        ) : !!newValue ? (
                            <Text
                                variant="body"
                                onClick={handleValueEditClick}
                                className="cursor-pointer"
                            >
                                {`${newValue} ${objective?.indicator?.unit?.shortName}`}
                            </Text>
                        ) : (
                            <Text
                                variant="body"
                                color="secondary"
                                className="cursor-pointer"
                                onClick={handleValueEditClick}
                            >
                                {t(keys.define_value, {
                                    unit: objective?.indicator?.unit?.shortName,
                                })}
                            </Text>
                        )}
                    </FlexRow>
                    <FlexRow
                        justifyContent="between"
                        alignItems="center"
                        w="full"
                    >
                        <FlexRow>
                            <Popover>
                                <PopoverTrigger
                                    asChild
                                    className="cursor-pointer"
                                >
                                    {newDate ? (
                                        <FlexRow
                                            w="fit"
                                            className="group"
                                            alignItems="center"
                                            gap="1"
                                        >
                                            <Icon
                                                name="calendar"
                                                className="text-secondary"
                                            />
                                            <FlexRow>
                                                <Text variant="body">
                                                    {displayDate}
                                                </Text>
                                            </FlexRow>
                                        </FlexRow>
                                    ) : (
                                        <FlexRow
                                            p="0.5"
                                            justifyContent="center"
                                            alignItems="center"
                                            className="rounded-xl border border-secondary"
                                            elevation="b-100"
                                            gap="1"
                                        >
                                            <Icon
                                                name="calendar"
                                                size="sm"
                                                className="text-secondary"
                                            />
                                            <Text>{t(keys.add_date)}</Text>
                                        </FlexRow>
                                    )}
                                </PopoverTrigger>
                                <PopoverContent
                                    align="start"
                                    side="bottom"
                                    className="shadow-lg bg-white"
                                >
                                    <Calendar
                                        mode="single"
                                        defaultMonth={
                                            newDate
                                                ? new Date(newDate)
                                                : new Date()
                                        }
                                        selected={
                                            newDate
                                                ? new Date(newDate)
                                                : undefined
                                        }
                                        onSelect={setNewDate}
                                        className="rounded-md border"
                                        locale={
                                            currentUser?.data?.currentUser
                                                ?.locale
                                        }
                                    />
                                </PopoverContent>
                            </Popover>
                        </FlexRow>
                        <FlexRow
                            justifyContent="end"
                            alignItems="center"
                            gap="3"
                        >
                            <Button
                                size="xs"
                                variant="plain"
                                onClick={handleCancelButtonClick}
                            >
                                <Text variant="body-sm">{t(keys.cancel)}</Text>
                            </Button>
                            <Button
                                size="xs"
                                onClick={handleCreateButtonClick}
                                disabled={!newValue}
                            >
                                <Text variant="body-sm">{t(keys.create)}</Text>
                            </Button>
                        </FlexRow>
                    </FlexRow>
                </FlexCol>
            )}
            {(totalTargets === 0 || !objective.indicatorId) && (
                <FlexCol
                    py="10"
                    justifyContent="center"
                    alignItems="center"
                    className="rounded-lg border border-tertiary bg-secondary"
                >
                    <FlexCol alignItems="center" gap="2">
                        <Icon name="flag" size="sm" />
                        <FlexCol gap="1" alignItems="center">
                            <Text variant="body">{t(keys.no_targets)}</Text>
                            <Text variant="body-sm" color="secondary">
                                {!objective.indicatorId
                                    ? t(keys.no_indicator_targets)
                                    : t(keys.target_description)}
                            </Text>
                        </FlexCol>
                    </FlexCol>
                </FlexCol>
            )}
            <FlexCol alignItems="start">
                {sortedTargets.map((target, idx) => (
                    <FlexCol key={target.id} gap="0" alignSelf="stretch">
                        <Target
                            key={target.id}
                            target={target}
                            unitShortName={
                                objective?.indicator?.unit?.shortName || ""
                            }
                            isLast={idx === sortedTargets.length - 1}
                            objectiveId={objective.id}
                        />
                        {idx < sortedTargets.length - 1 && (
                            <FlexRow
                                alignItems="center"
                                className="w-[14px] h-[16px]  border-r"
                            />
                        )}
                    </FlexCol>
                ))}
            </FlexCol>
        </FlexCol>
    );
};
