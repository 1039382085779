import { graphql } from "@generated/client";
import { type FrameworkPatch } from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const GetFrameworksQuery = graphql(`
    query GetFrameworks {
        frameworks {
            id
            name
            description
            cmsId
            stats {
                reqOk
                reqTotal
            }
            activation
            order
            imageUrl
            released
        }
    }
`);

const GetFrameworkQuery = graphql(`
    query GetFramework($id: String!) {
        framework(cmsId: $id) {
            id
            name
            description
            groups {
                name
                subgroups {
                    name
                    sections {
                        name
                        description
                        requirements {
                            id
                            ownerId
                            cmsId
                            name
                            description
                            proofStat {
                                total
                                ok
                            }
                            status
                            order
                        }
                    }
                }
            }
        }
    }
`);

const GetFrameworkDocumentsQuery = graphql(`
    query getFrameworkDocuments($id: String!) {
        frameworkDocuments(id: $id) {
            id
            name
            description
            type
            frameworkSections(frameworkId: $id) {
                tagName
                name
            }
            lastVersion {
                finalFile {
                    signedUrl
                    extension
                }
            }
        }
    }
`);

const UpdateFrameworkMutation = graphql(`
    mutation UpdateFramework($id: String!, $patch: FrameworkPatch!) {
        updateFramework(id: $id, patch: $patch) {
            id
        }
    }
`);

const InitializeFrameworkMutation = graphql(`
    mutation InitializeFramework($id: String!) {
        initializeFramework(id: $id) {
            id
        }
    }
`);

export class GraphQLFrameworkRepository {
    get = async (id: string) => {
        return graphqlClientKoyeb.request(
            GetFrameworkQuery,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };
    getFrameworkDocuments = async (id: string) => {
        return graphqlClientKoyeb.request(
            GetFrameworkDocumentsQuery,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };
    getMany = async () => {
        return graphqlClientKoyeb.request(
            GetFrameworksQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    update = async (id: string, patch: FrameworkPatch) => {
        return graphqlClientKoyeb.request(
            UpdateFrameworkMutation,
            { id, patch },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    initialize = async (id: string) => {
        return graphqlClientKoyeb.request(
            InitializeFrameworkMutation,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
