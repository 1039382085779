import { Spinner } from "@app/components/TipTap/components/ui/Spinner";
import { useDebouncedStableCallback } from "@app/shared/utils/debounce";
import { Button } from "@design-system/Inputs/Button";
import { Textfield } from "@design-system/Inputs/Textfield";
import { Box } from "@design-system/Layout/Box";
import { Flex, FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useAtomValue } from "jotai";
import { useEffect, useRef, type FC, type FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { currentAiThreadIdAtom } from "../ctx";
import { useSendPrompt, useThread } from "../data";
import { RESOURCE_NAME, keys } from "../i18n";
import { AiErrorMessage } from "./AiErrorMessage";
import { AskAiPlaceholder } from "./AskAiPlaceholder";
import { MessageBlock } from "./MessageBlock";
import "../index.css";

export const AiChat: FC = () => {
    const { t } = useTranslation(RESOURCE_NAME);

    const currentAiThreadId = useAtomValue(currentAiThreadIdAtom);
    const {
        thread,
        isPending,
        isError: threadHasError,
    } = useThread(currentAiThreadId);
    const {
        isError: promptHasError,
        isPending: isPromptPending,
        isStreaming,
        sendPrompt,
    } = useSendPrompt();

    const isLoading = isStreaming || isPromptPending;

    const hasError = threadHasError || promptHasError;

    const inputRef = useRef<HTMLInputElement>(null);
    const clearInput = () => {
        if (!inputRef.current) return;
        inputRef.current.value = "";
    };

    const messages = thread?.messages || [];

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const question = inputRef.current?.value;
        if (!question || !thread) return;
        sendPrompt({ prompt: question, thread });
        clearInput();
    };

    const containerRef = useRef<HTMLDivElement>(null);
    const debouncedScrollIntoView = useDebouncedStableCallback(() => {
        const container = containerRef.current;
        if (!container) return;
        container.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);

    useEffect(() => {
        // Create ResizeObserver to detect height changes
        const resizeObserver = new ResizeObserver(debouncedScrollIntoView);

        // Start observing the container
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }
        // Cleanup observer on unmount
        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, [debouncedScrollIntoView, isStreaming, isPromptPending]);

    return hasError ? (
        <Box w="full">
            <AiErrorMessage />
        </Box>
    ) : (
        <FlexCol
            className="bg-secondary p-5"
            gap="3"
            h="full"
            justifyContent="end"
            w="full"
        >
            {isPending ? (
                <Box
                    className="place-content-center"
                    display="grid"
                    h="full"
                    w="full"
                >
                    <Spinner />
                </Box>
            ) : (
                <FlexCol
                    className="overflow-y-scroll scrollbar-hide"
                    maxH="full"
                    w="full"
                    ref={containerRef}
                >
                    {messages.length === 0 ? (
                        <AskAiPlaceholder />
                    ) : (
                        <Flex direction="col-reverse" gap="4">
                            {messages.map((msg, idx) => (
                                <MessageBlock
                                    key={msg.openAiId}
                                    idx={idx}
                                    message={msg}
                                    scrollIntoView={idx === 0}
                                />
                            ))}
                        </Flex>
                    )}
                </FlexCol>
            )}
            {isLoading && <Loader />}
            <form className="[all:unset] w-full" onSubmit={handleSubmit}>
                <FlexRow w="full">
                    <Textfield
                        disabled={isLoading}
                        placeholder={t(keys.ask_me_anything)}
                        type="text"
                        className="w-full"
                        ref={inputRef}
                    />
                    <Button
                        className="rounded-[120px] relative right-8 top-3 min-h-[32px] min-w-[32px]"
                        disabled={isLoading}
                        size="sm"
                        type="submit"
                    >
                        <Button.Icon name="arrowUp" />
                    </Button>
                </FlexRow>
            </form>
        </FlexCol>
    );
};

const Loader = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    return (
        <FlexRow ml="1" mt="2">
            <Text variant="body" color="tertiary" mr="2">
                {t(keys.loader)}
            </Text>
            <div className="loader" />
        </FlexRow>
    );
};
