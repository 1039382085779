import { GraphQLIndicatorRepository } from "@app/repositories/GraphQLRepositories/indicator/IndicatorRepository";
import { GraphQLObjectiveTargetRepository } from "@app/repositories/GraphQLRepositories/ObjectiveTargetRepository";
import { tanstackUnitaryOptimisticUpdateOptionsMaker } from "@app/shared/utils/optimisticUpdates";
import { Indicator2Filter } from "@generated/client/graphql";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { objectiveKeys } from "../data";
import {
    makeOptimisticObjectiveTargetsUpdateOnTargetCreate,
    makeOptimisticObjectiveTargetsUpdateOnTargetDelete,
} from "./services";

export const objectiveTargetKeys = {
    update: (id: string) => ["updateObjectiveTarget", id] as const,
    create: () => ["createObjectiveTarget"] as const,
    delete: (id: string) => ["deleteObjectiveTarget", id] as const,
};

const objectiveTargetRepository = new GraphQLObjectiveTargetRepository();
const indicatorRepository = new GraphQLIndicatorRepository();

export const useUpdateObjectiveTarget = (id: string) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: objectiveTargetKeys.update(id),
        mutationFn: objectiveTargetRepository.update,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: objectiveKeys.all(),
            });
        },
    });

    return mutation;
};

export const useCreateObjectiveTarget = (objectiveId: string) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: objectiveTargetKeys.create(),
        mutationFn: objectiveTargetRepository.create,
        ...tanstackUnitaryOptimisticUpdateOptionsMaker(
            () => objectiveKeys.objectiveDetails(objectiveId),
            makeOptimisticObjectiveTargetsUpdateOnTargetCreate(objectiveId),
        ),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: objectiveKeys.objectiveDetails(objectiveId),
            });
        },
    });

    return mutation;
};

export const useDeleteObjectiveTarget = (id: string, objectiveId: string) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: objectiveTargetKeys.delete(id),
        mutationFn: objectiveTargetRepository.delete,
        ...tanstackUnitaryOptimisticUpdateOptionsMaker(
            () => objectiveKeys.objectiveDetails(objectiveId),
            makeOptimisticObjectiveTargetsUpdateOnTargetDelete(objectiveId),
        ),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: objectiveKeys.all(),
            });
        },
    });

    return mutation;
};
export const useIndicatorsFiltered = (
    filters: Indicator2Filter,
    entityId: string,
) => {
    const { data, isPending } = useQuery({
        queryKey: objectiveKeys.indicatorsFiltered(filters, entityId),
        queryFn: () => indicatorRepository.getFiltered(filters, entityId),
    });

    return { indicators: data, isPending };
};
