import { GetObjectiveQuery, ObjectiveStatus } from "@generated/client/graphql";
import { orderPeriods } from "../ReportingUseCases";
import { tagStatus } from "@design-system/DataDisplay/StatusTagCircular";

export const displayStatus = (status: ObjectiveStatus | undefined) => {
    if (status === ObjectiveStatus.Archived) return tagStatus.validated;
    if (status === ObjectiveStatus.Committed) return tagStatus.validated;
    if (status === ObjectiveStatus.Defined) return tagStatus.defined;
    if (status === ObjectiveStatus.Draft) return tagStatus.todo;
    return tagStatus.todo;
};

export const latestDatapoint = (
    indicator?: GetObjectiveQuery["objective"]["indicator"],
    entityId?: string,
) => {
    if (!indicator || !entityId) return undefined;
    const entityDatapoints = indicator.datapoints?.filter(
        (datapoint) => datapoint.datapointGroup?.entityId === entityId,
    );
    const sortedDatapoints = entityDatapoints?.sort((a, b) =>
        orderPeriods(b.datapointGroup?.period, a.datapointGroup?.period),
    );
    return sortedDatapoints?.[0];
};
