import { useSetToast } from "@app/components/Toast";
import { months, quarters } from "@app/usecases/ReportingUseCases";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { cn } from "@design-system/Utilities";
import { useAtomValue } from "jotai";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { activeReportingEntityIdAtom } from "../../context";
import {
    useCreateDatapointGroup,
    useCreateEntityDatapointGroups,
    useDatapointGroupsOfEntity,
} from "../../data";
import { RESOURCE_NAME, keys } from "./i18n";

type AddPeriodButtonsProps = {
    selectedYear: number;
};

export const AddPeriodButtons: FC<AddPeriodButtonsProps> = ({
    selectedYear,
}) => {
    const datapointGroupsOfEntity = useDatapointGroupsOfEntity();
    const activeReportingEntityId = useAtomValue(activeReportingEntityIdAtom);
    const { setToastSuccess, setToastError } = useSetToast();
    const { mutate: mutateCreate } = useCreateDatapointGroup();
    const { mutate: mutateCreateMany } = useCreateEntityDatapointGroups();

    const getActivePeriodTypes = (year: number | null) => {
        if (!year) {
            return [];
        }
        const periods = datapointGroupsOfEntity.filter(
            (group) => group.period.year === year,
        );
        const periodTypes = [];
        if (
            periods.some(
                (group) =>
                    group.period.year &&
                    !group.period.quarter &&
                    !group.period.month,
            )
        ) {
            periodTypes.push("year");
        }
        if (periods.some((group) => group.period.quarter)) {
            periodTypes.push("quarter");
        }
        if (periods.some((group) => group.period.month)) {
            periodTypes.push("month");
        }
        return periodTypes;
    };

    const handleAddPeriodClick = (type: "year" | "quarter" | "month") => () => {
        if (!selectedYear) {
            return;
        }
        const periodsToAdd = [];
        if (type === "quarter") {
            periodsToAdd.push(
                ...Object.values(quarters).map((quarter) => ({
                    year: selectedYear,
                    quarter,
                })),
            );
            return createManyDatapointGroups(periodsToAdd);
        }
        if (type === "month") {
            periodsToAdd.push(
                ...Object.values(months).flatMap((month) => ({
                    year: selectedYear,
                    month,
                })),
            );
            return createManyDatapointGroups(periodsToAdd);
        }
        createDatapointGroup({
            year: selectedYear,
        });
    };

    const createManyDatapointGroups = (
        periods: {
            year: number;
            month?: string;
            quarter?: string;
        }[],
    ) => {
        const input = {
            entityId: activeReportingEntityId,
            periods,
        };
        mutateCreateMany(input, {
            onSuccess: (data) => {
                if (data?.createEntityDatapointGroups?.error) {
                    setToastError(
                        data?.createEntityDatapointGroups?.error?.message,
                    );
                } else {
                    setToastSuccess(t(keys.add_period_success));
                }
            },
            onError: () => {
                setToastError(t(keys.add_period_error));
            },
        });
    };

    const createDatapointGroup = (period: {
        year: number;
        month?: string;
        quarter?: string;
    }) => {
        mutateCreate(
            {
                entityId: activeReportingEntityId,
                period,
            },
            {
                onSuccess: () => {
                    setToastSuccess(t(keys.add_period_success));
                },
                onError: () => {
                    setToastError(t(keys.add_period_error));
                },
            },
        );
    };

    const { t } = useTranslation(RESOURCE_NAME);
    return (
        <FlexRow
            gap="3"
            className={cn(
                getActivePeriodTypes(selectedYear).length === 3 && "hidden",
            )}
        >
            <Button
                size="sm"
                variant="tonal"
                className={cn(
                    getActivePeriodTypes(selectedYear).includes("year") &&
                        "hidden",
                )}
                onClick={handleAddPeriodClick("year")}
            >
                {t(keys.add_years)}
                <Icon name="plus" size="sm" />
            </Button>
            <Button
                size="sm"
                variant="tonal"
                className={cn(
                    getActivePeriodTypes(selectedYear).includes("quarter") &&
                        "hidden",
                )}
                onClick={handleAddPeriodClick("quarter")}
            >
                {t(keys.add_quarters)}
                <Icon name="plus" size="sm" />
            </Button>
            <Button
                size="sm"
                variant="tonal"
                className={cn(
                    getActivePeriodTypes(selectedYear).includes("month") &&
                        "hidden",
                )}
                onClick={handleAddPeriodClick("month")}
            >
                {t(keys.add_months)}
                <Icon name="plus" size="sm" />
            </Button>
        </FlexRow>
    );
};
