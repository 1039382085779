import { browserDownload } from "@app/lib/utils-browser";
import { GraphQLDatapointRepository as DatapointRepository } from "@app/repositories/GraphQLRepositories/DatapointRepository";
import {
    DatapointGroup,
    GetAllDatapointGroupsQuery,
    GetIndicators2Query,
    ReportingExportFilesInput,
    ReportingExportInput,
    ReportingPeriod,
    User,
} from "@generated/client/graphql";

export type ReportingIndicatorType = NonNullable<
    GetIndicators2Query["indicators2"]
>[0];

export type ReprotingGroupType = NonNullable<
    GetAllDatapointGroupsQuery["datapointGroups"]
>[0];

export type ReportingDatpoint = NonNullable<
    NonNullable<GetIndicators2Query["indicators2"]>[0]["datapoints"]
>[0];

export function isNumCell(unitType: string | undefined): boolean {
    return ["FLOAT", "INTEGER", "NUMBER"].includes(unitType || "");
}

export function isTableCell(unitType: string | undefined): boolean {
    return ["TABLE"].includes(unitType || "");
}

export const reportingTableColumns = [
    "requirements",
    "tags",
    "frameworks",
    "subtheme",
    "assignedTo",
];

export const reportingFrameworks = [
    { name: "EcoVadis", id: "evs-2023" },
    { name: "CSRD", id: "csrd" },
];

export async function exportReporting(
    datapointRepository: DatapointRepository,
    input: ReportingExportInput,
) {
    return datapointRepository.export(input).then((res) => {
        if (res.exportReporting.signedUrl) {
            browserDownload(res?.exportReporting.signedUrl, true);
        }
    });
}

export async function exportReportingFiles(
    datapointRepository: DatapointRepository,
    input: ReportingExportFilesInput,
) {
    return datapointRepository.exportFiles(input).then((res) => {
        if (res.exportReportingFiles.signedUrl) {
            browserDownload(res?.exportReportingFiles.signedUrl);
        }
    });
}

export function hasDatapointValue(
    datapoint: ReportingDatpoint | undefined | null,
    unitType: string | undefined,
    hasNoData: boolean | undefined,
    floatValue: number | undefined,
    stringValue: string | undefined,
    tableValue: { key: string; value: string }[] | undefined,
): boolean {
    return (
        !!datapoint?.value &&
        (hasNoData ||
            (isNumCell(unitType)
                ? floatValue !== undefined
                : isTableCell(unitType)
                  ? !!tableValue && tableValue.length > 0
                  : !!stringValue))
    );
}

export function filterIndicators(
    indicators: ReportingIndicatorType[] | null | undefined,
    searchString: string | undefined,
    toFillSwitch: boolean,
    groupsOfEntitiy: ReprotingGroupType[],
    activeGroupsIds: string[],
): ReportingIndicatorType[] {
    if (!indicators) {
        return [];
    }
    var filteredIndicators = indicators;
    // filter entity
    filteredIndicators = filteredIndicators.filter(
        (indicator) => indicator?.entityConfig?.active,
    );

    // filter search - this could be replaced by fuse
    if (searchString) {
        filteredIndicators = filteredIndicators.filter((indicator) => {
            return (
                indicator?.name
                    ?.toLowerCase()
                    .includes(searchString.toLowerCase()) ||
                indicator?.description
                    ?.toLowerCase()
                    .includes(searchString.toLowerCase())
            );
        });
    }
    if (toFillSwitch) {
        filteredIndicators = filteredIndicators.filter((indicator) =>
            groupsOfEntitiy
                .filter((group) => activeGroupsIds.includes(group.id))
                .some((group) => {
                    const datapoint = indicator.datapoints?.find(
                        (datapoint) =>
                            datapoint.datapointGroup?.id === group.id,
                    );
                    return !hasDatapointValue(
                        datapoint,
                        indicator?.unit?.type,
                        datapoint?.value?.noData,
                        datapoint?.value?.float,
                        datapoint?.value?.string,
                        datapoint?.value?.table,
                    );
                }),
        );
    }

    filteredIndicators = filteredIndicators.filter((indicator) => {
        return ["FLOAT", "INTEGER", "NUMBER", "TEXT", "TABLE"].includes(
            indicator.unit?.type || "",
        );
    });

    return filteredIndicators;
}

export const orderedMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
export const orderedQuarters = ["Q1", "Q2", "Q3", "Q4"];
export const orderedYears = [2024, 2023, 2022, 2021, 2020];

export function orderPeriods(a: ReportingPeriod, b: ReportingPeriod): number {
    if (!a || !b) return 0;
    if (a.year !== b.year)
        return orderedYears.indexOf(b.year) - orderedYears.indexOf(a.year);
    if (!a.month && !a.quarter) return -1;
    if (!b.month && !b.quarter) return 1;
    if (a.quarter && !b.quarter) return -1;
    if (b.quarter && !a.quarter) return 1;
    if (a.month && b.month) {
        return orderedMonths.indexOf(a.month) - orderedMonths.indexOf(b.month);
    }
    if (a.quarter && b.quarter) {
        return (
            orderedQuarters.indexOf(a.quarter) -
            orderedQuarters.indexOf(b.quarter)
        );
    }
    return 0;
}

export function getFilteredSortedDatapointGroupsIds(
    activeDatapointGroupsIds: string[],
    activeReportingEntityId: string,
    datapointGroups: DatapointGroup[],
): string[] {
    return activeDatapointGroupsIds
        .filter((datapointGroupId) =>
            activeReportingEntityId
                ? datapointGroups?.find((elt) => elt.id === datapointGroupId)
                      ?.entity?.id === activeReportingEntityId
                : true,
        )
        .sort((d1, d2) =>
            orderPeriods(
                datapointGroups?.find((elt) => elt.id === d1)?.period,
                datapointGroups?.find((elt) => elt.id === d2)?.period,
            ),
        );
}

export function filterContributors(
    users: User[],
    searchString: string | undefined,
): User[] {
    return searchString
        ? users.filter((user) => {
              return (
                  user?.firstName
                      ?.toLowerCase()
                      .includes(searchString.toLowerCase()) ||
                  user?.lastName
                      ?.toLowerCase()
                      .includes(searchString.toLowerCase())
              );
          })
        : users;
}

export const years = {
    "2020": "2020",
    "2021": "2021",
    "2022": "2022",
    "2023": "2023",
    "2024": "2024",
} as const;

export type Years = keyof typeof years;

export const months = {
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
} as const;

export type Months = keyof typeof months;

export const quarters = {
    Q1: "Q1",
    Q2: "Q2",
    Q3: "Q3",
    Q4: "Q4",
} as const;

export type Quarters = keyof typeof quarters;
