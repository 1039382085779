import { Clerk } from "@clerk/clerk-js";
import { UserRole } from "@generated/client/graphql";
import { atomWithStorage } from "jotai/utils";

type Maybe<T> = T | undefined | null;

let _org_uname: Maybe<string> = undefined;
let _test_role: string = UserRole.Admin;
let _headers: HeadersInit = {};

let clerk = new Clerk(import.meta.env.VITE_CLERK_PUBLISHABLE_KEY);

export const testRoleAtom = atomWithStorage<UserRole>(
    "test-user-role",
    UserRole.Admin,
);

export class AccessHeadersHolder {
    static setOrgUname(org_uname: Maybe<string>) {
        _org_uname = org_uname;
    }
    static setTestRole(test_role: string) {
        _test_role = test_role;
    }

    static async getHeaders(): Promise<HeadersInit> {
        let headers: HeadersInit = { ..._headers };

        await clerk.load();
        const bearer = await clerk.session?.getToken();

        if (window === undefined) {
            return headers;
        }

        headers = { ...headers, pathname: window.location.pathname };

        if (bearer) {
            headers = headers = {
                ...headers,
                authorization: `Bearer ${bearer}`,
                pathname: window.location.pathname,
            };
        }

        if (_org_uname) {
            headers = { ...headers, "x-org-uname": _org_uname };
        }

        if (import.meta.env.VITE_REACT_APP_DEV_NO_AUTH === "true") {
            headers = { ...headers, "x-role-test": _test_role };
        }

        return headers;
    }
}
