import { graphql } from "@generated/client";
import {
    type CreateIndicatorInput,
    type Indicator2Filter,
    type IndicatorPatch,
    type ReportingRemindersInput,
    type UpdateIndicatorInput,
} from "../../../../generated/client/graphql";
import { AccessHeadersHolder } from "../../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../../clients";

const GetIndicators2Query = graphql(`
    query GetIndicators2($entityId: String!) {
        indicators2 {
            id
            name
            description
            calculation
            order
            tags {
                id
                name
            }
            requirements {
                id
                cmsId
                name
            }
            subthemev2 {
                id
                name
                theme {
                    color
                }
            }
            unit {
                id
                name
                shortName
                type
            }
            entityConfig(entityId: $entityId) {
                id
                active
                assignedTo {
                    id
                    firstName
                    lastName
                    email
                }
                entityId
            }
            datapoints {
                id
                value
                comment
                datapointGroup {
                    id
                    entityId
                    period
                }
                proofs {
                    id
                    signedUrl
                    name
                }
            }
            frameworks {
                id
                name
                cmsId
            }
            frameworkSections {
                id
                cmsId
                name
                tagName
                framework {
                    name
                    id
                }
            }
        }
    }
`);

const GetIndicatorsFilteredQuery = graphql(`
    query GetIndicatorsFiltered($filter: Indicator2Filter, $entityId: String!) {
        indicators2(filter: $filter) {
            id
            name
            unit {
                shortName
                type
            }
            subtheme {
                id
                name
                theme {
                    color
                }
            }
            entityConfig(entityId: $entityId) {
                id
                active
            }
        }
    }
`);

const UpdateIndicatorMutation = graphql(`
    mutation updateIndicator($id: String!, $patch: IndicatorPatch!) {
        updateIndicator(id: $id, set: $patch) {
            id
            tags {
                id
                color
                name
            }
            uname
            name
            description
            options {
                name
            }
            order
            theme {
                id
                name
            }
            subtheme {
                id
                name
            }
            unit {
                bvId
                name
                type
            }
            supplierQuestionnaireTemplates {
                id
                name
            }
            documentAcceptance
        }
    }
`);

const UpdateIndicatorsMutation = graphql(`
    mutation updateIndicators($inputs: [UpdateIndicatorInput!]!) {
        updateIndicators(inputs: $inputs)
    }
`);

const SendReportingRemindersMutation = graphql(`
    mutation SendReportingReminders(
        $subject: String!
        $text: String!
        $html: String!
        $test: Boolean!
        $sendToIds: [String!]
        $url: String
    ) {
        SendReportingReminders(
            input: {
                html: $html
                subject: $subject
                text: $text
                sendToIds: $sendToIds
                test: $test
                url: $url
            }
        )
    }
`);

const CreateIndicatorsMutation = graphql(`
    mutation CreateIndicators($inputs: [CreateIndicatorInput!]!) {
        createIndicators(inputs: $inputs)
    }
`);

export class GraphQLIndicatorRepository {
    get = async (entityId: string) => {
        return graphqlClientKoyeb.request(
            GetIndicators2Query,
            { entityId },
            await AccessHeadersHolder.getHeaders(),
        );
    };
    getFiltered = async (filter: Indicator2Filter, entityId: string) => {
        return graphqlClientKoyeb.request(
            GetIndicatorsFilteredQuery,
            { filter, entityId },
            await AccessHeadersHolder.getHeaders(),
        );
    };
    update = async (input: { id: string; patch: IndicatorPatch }) => {
        return graphqlClientKoyeb.request(
            UpdateIndicatorMutation,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };
    updateMany = async (inputs: UpdateIndicatorInput[]) => {
        return graphqlClientKoyeb.request(
            UpdateIndicatorsMutation,
            { inputs },
            await AccessHeadersHolder.getHeaders(),
        );
    };
    sendReminders = async (input: ReportingRemindersInput) => {
        return graphqlClientKoyeb.request(
            SendReportingRemindersMutation,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    createManyCustomIndicators = async (inputs: CreateIndicatorInput[]) => {
        return graphqlClientKoyeb.request(
            CreateIndicatorsMutation,
            { inputs },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
