import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Skeleton } from "@design-system/DataDisplay/Skeleton";

export const ObjectiveCardSkeleton = () => {
    return (
        <FlexCol
            p="4"
            br="lg"
            elevation="b-100"
            className="border border-secondary bg-white h-[176px]"
        >
            <FlexRow alignItems="center" justifyContent="between">
                <FlexRow gap="2" alignItems="center" className="h-[26px]">
                    <Skeleton h="3" w="4" br="lg" />
                    <Skeleton h="3" w="8" br="lg" />
                    <Skeleton h="3" w="16" br="lg" />
                </FlexRow>
                <Skeleton h="2" w="5" br="lg" />
            </FlexRow>
            <FlexCol pt="6" h="full" justifyContent="between">
                <Skeleton h="4" w="full" br="lg" />
                <FlexRow justifyContent="between">
                    <Skeleton h="4" w="12" br="lg" />
                    <Skeleton h="4" w="28" br="lg" />
                </FlexRow>
            </FlexCol>
        </FlexCol>
    );
};
