import NotificationCenter from "@app/components/Notifications/NotificationCenter";
import { Breadcrumb } from "@app/shared/components/Breadcrumb";
import { currentUserAtom } from "@app/store/userStore";
import { Flex } from "@design-system/Layout/Flex";
import { useAtomValue } from "jotai";
import { AskAIModal } from "../AskAIModal";
import TopBarHelp from "./TopBarHelp";
import TopBarProfil from "./TopBarProfil";
import "./i18n";

export default function TopBar() {
    const { data } = useAtomValue(currentUserAtom);

    return (
        <Flex w="full" justifyContent="between" alignItems="center">
            <Flex className="flex-1">
                <Breadcrumb />
            </Flex>
            <div className="flex justify-end items-center space-x-4 flex-1">
                <TopBarHelp />
                <NotificationCenter />
                <AskAIModal />
                <TopBarProfil currentUser={data?.currentUser} />
            </div>
        </Flex>
    );
}
