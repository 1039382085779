import i18next from "@app/i18n";

const enResources = {
    select_subtheme: "Select a subtheme",
    no_subtheme: "No subtheme",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    select_subtheme: "Sélectionnez un sous-thème",
    no_subtheme: "Aucun sous-thème",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "SubthemeTag";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
