import Floatbar from "@app/components/Floatbar";
import { useSetToast } from "@app/components/Toast";
import { browserDownload } from "@app/lib/utils-browser";
import { Button } from "@design-system/Inputs/Button";
import { useTranslation } from "react-i18next";
import { useExportFiles } from "../../data";
import "../../i18n";
import { keys, RESOURCE_NAME } from "../../i18n";
import { EnrichedFile } from "../../service";

const AllMyFilesTableActionBar = ({
    files,
    unset,
}: {
    files: EnrichedFile[];
    unset: () => void;
}) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { mutate, isPending } = useExportFiles();
    const { setToastSuccess, setToastError } = useSetToast();
    const handleDownloadClick = () => {
        mutate(
            { ids: files.map((file) => file.id) },
            {
                onSuccess: (data) => {
                    if (data.exportFiles?.signedUrl) {
                        browserDownload(data?.exportFiles.signedUrl);
                    }
                    setToastSuccess(t(keys.download_success));
                },
                onError: () => {
                    setToastError(t(keys.download_error));
                },
            },
        );
    };
    return (
        <Floatbar
            unset={unset}
            text={t("action", { count: files.length })}
            open={files.length > 0}
            primary={<></>}
            secondary={
                <Button
                    onClick={handleDownloadClick}
                    size="sm"
                    loading={isPending}
                    className="w-28"
                >
                    {t(keys.download)}
                </Button>
            }
        />
    );
};

export default AllMyFilesTableActionBar;
