import { InfoCircle } from "iconoir-react";
import Papa from "papaparse";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { tagsAtomFamily } from "@app/store/tagStore";
import { useAtomValue } from "jotai";
import {
    CreateSupplierInput,
    TagType,
    UserRole,
} from "../../../../../../generated/client/graphql";
import ButtonLarge from "../../../../../components/Button/ButtonLarge";
import { ButtonType } from "../../../../../components/Button/ButtonShared";
import { Tooltip } from "../../../../../components/Tooltip";

export const useCsvImport = (
    onAddSuppliers: (suppliers: CreateSupplierInput[]) => void,
) => {
    const tags = useAtomValue(tagsAtomFamily(TagType.Supplier));
    const onCsvImport = (file: File) => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results: any) {
                const csvSuppliers = results.data.map((row: any) => {
                    const rowTags = row.tags
                        ?.split(",")
                        .map((t: string) => t.trim().toLowerCase());
                    const tagIds = tags.data?.tags
                        .filter((t) => rowTags.includes(t.name.toLowerCase()))
                        .map((t) => t.id);
                    const newSupplier: CreateSupplierInput = {
                        name: row.name || "",
                        pointOfContact: {
                            email: row.email || "",
                            firstName: row.firstname || "",
                            lastName: row.lastname || "",
                            role: UserRole.Contributor,
                        },
                        tagIds,
                    };
                    return newSupplier;
                });
                onAddSuppliers(csvSuppliers);
            },
        });
    };

    return onCsvImport;
};

const ImportCSVButton = ({
    onAddSuppliers,
}: {
    onAddSuppliers: (suppliers: CreateSupplierInput[]) => void;
}) => {
    const { t } = useTranslation("NewSupplierModal");
    const ref = useRef<HTMLInputElement>(null);
    const onCsvImport = useCsvImport(onAddSuppliers);

    return (
        <>
            <input
                ref={ref}
                type="file"
                className="hidden"
                onChange={(e) => {
                    e.target.files && onCsvImport(e.target.files[0]);
                    if (ref.current) {
                        ref.current.files = null;
                        ref.current.value = "";
                    }
                }}
            />
            <ButtonLarge
                variant={ButtonType.TONAL}
                IconRight={({ className }: { className?: string }) => (
                    <Tooltip tooltipContent={t("import_csv_tooltip")}>
                        <InfoCircle className={className} />
                    </Tooltip>
                )}
                onClick={() => ref.current?.click()}
            >
                {t("import_csv")}
            </ButtonLarge>
        </>
    );
};

export default ImportCSVButton;
