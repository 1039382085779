import {
    CreateObjectiveTargetInput,
    GetObjectiveQuery,
    ObjectiveTargetStatus,
} from "@generated/client/graphql";

export function makeOptimisticObjectiveTargetsUpdateOnTargetDelete(
    objectiveId: string,
) {
    return function (patch: string, previousQuery: GetObjectiveQuery) {
        if (!objectiveId) throw new Error("No objectiveId provided");
        const previousTargets = previousQuery.objective?.targets;
        if (!previousTargets) throw new Error("No previous targets found");
        const updatedTargets = previousTargets.filter(
            (target) => target.id !== patch,
        );
        const updatedQuery: GetObjectiveQuery = {
            ...previousQuery,
            objective: {
                ...previousQuery.objective,
                targets: updatedTargets,
            },
        };
        return updatedQuery;
    };
}

export function makeOptimisticObjectiveTargetsUpdateOnTargetCreate(
    objectiveId: string,
) {
    return function (
        patch: CreateObjectiveTargetInput,
        previousQuery: GetObjectiveQuery,
    ) {
        if (!objectiveId) throw new Error("No objectiveId provided");
        const previousTargets = previousQuery.objective?.targets;
        if (!previousTargets) throw new Error("No previous targets found");
        const updatedQuery: GetObjectiveQuery = {
            ...previousQuery,
            objective: {
                ...previousQuery.objective,
                targets: [
                    ...previousTargets,
                    {
                        ...patch,
                        id: "1",
                        value: patch?.value ?? {},
                        status: ObjectiveTargetStatus.Ongoing,
                        date: patch?.date ?? new Date(),
                    },
                ],
            },
        };
        return updatedQuery;
    };
}
