import useHubspotChat from "@app/lib/hubspot";
import {
    AccessHeadersHolder,
    testRoleAtom,
} from "@app/repositories/AccessHeadersHolder";
import RoleSelect from "@app/shared/components/RoleSelect";
import { SignedIn, SignedOut } from "@clerk/clerk-react";
import { Box } from "@design-system/Layout/Box";
import { FlexCol } from "@design-system/Layout/Flex";
import { UserRole } from "@generated/client/graphql";
import { useAtom } from "jotai";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import SaveBar from "../components/SaveBar";
import Toast from "../components/Toast";
import { useTrackingIdentify } from "../lib/tracking/utils";
import SignInPage from "./SignIn";

export const MainLoggedInApp = () => {
    if (import.meta.env.VITE_REACT_APP_DEV_NO_AUTH !== "true") {
        useHubspotChat();
        useTrackingIdentify();
    }
    return (
        <PostHogProvider client={posthog}>
            <SaveBar />
            <Toast />
            <Outlet />
        </PostHogProvider>
    );
};

const LoginRequired = () => {
    const [role, setRole] = useAtom(testRoleAtom);
    const handleTestRoleChange = (role: UserRole) => {
        setRole(role);
        window.location.reload();
    };
    useEffect(() => {
        AccessHeadersHolder.setTestRole(role);
    }, [role]);
    if (import.meta.env.VITE_REACT_APP_DEV_NO_AUTH === "true") {
        return (
            <FlexCol>
                <Box className="border-b bg-warning-300 text-center py-2 space-x-2 flex items-center justify-center">
                    <span>
                        You are in dev mode with no authentication. Make sure to
                        test your code with authentication if necessary. The dev
                        user is in
                    </span>
                    <RoleSelect role={role} setRole={handleTestRoleChange} />
                    <span>mode.</span>
                </Box>
                <SaveBar />
                <Toast />
                <Outlet />
            </FlexCol>
        );
    } else {
        return (
            <>
                <SignedIn>
                    <MainLoggedInApp />
                </SignedIn>
                <SignedOut>
                    <SignInPage />
                </SignedOut>
            </>
        );
    }
};

export default LoginRequired;
