import i18next from "i18next";

const enResources = {
    description_placeholder:
        "Write here a detailed description of your objective.",
    description_subtitle: "How did you set this objective?",
    description_guidance:
        "Provide details about your objective, including how targets were set, whether benchmarks or studies were used, and if it is science-based.",
    hint_text:
        "Provide details about your objective, including how targets were set, whether benchmarks or studies were used, and if it is science-based.",
    targets_subtitle: "What are your targets?",
    targets_guidance:
        "Quantify and date your objectives with ambitious and realistic targets.",
    add_target: "Add",
    define_value: "Define value in {{unit}}",
    cancel: "Cancel",
    create: "Create",
    no_targets: "No target defined",
    no_indicator_targets: "Add an indicator to the objective to define targets",
    target_description: "Add a target to make this objective measurable.",
    add_date: "Add date",

    indicator: "Indicator :",
    unit: "Unit",
    no_indicator_selected: "No indicator attached",
    no_indicator_selected_hint:
        "Choose an indicator to measure and track progress toward this objective.",
    no_indicator_selected_button: "Select Indicator",
    edit: "Edit",
    delete: "Delete",
    latest_value: "Latest value",
    disabled_targets_tooltip: "Cannot be edited if targets are set",
    show_example: "Show example",
    update_success: "Obejctive updated",
    update_error: "Error updating objective",
    latest_period: "Reported based on reporting data from {{period}}",
    no_latest_period: "No reporting data available",
} as const;

type ResourceKeys = keyof typeof enResources;

const frResources = {
    description_placeholder:
        "Précisez ici dans quel cadre cet objectif a été défini.",
    description_guidance:
        "Fournissez des informations précises sur votre objectif : expliquez comment les cibles ont été définies, indiquez si vous vous êtes appuyés sur des études et précisez si l'objectif repose sur des données scientifiques.",
    description_subtitle: "Comment avez-vous fixé cet objectif ?",
    hint_text:
        "Fournissez des détails sur votre objectif, y compris la manière dont les cibles ont été fixées, si des points de repère ou des études ont été utilisés, et s'il est basé sur la science.",
    targets_subtitle: "Quels sont vos objectifs chiffrés?",
    add_target: "Ajouter",
    targets_guidance:
        "Quantifiez et datez vos objectifs avec des cibles ambitieuses et atteignables.",
    define_value: "Définir la valeur en {{unit}}",
    cancel: "Annuler",
    create: "Créer",
    no_targets: "Cet objectif n'est pas chiffré",
    no_indicator_targets:
        "Ajoutez un indicateur à l'objectif pour chiffrer vos objectifs",
    target_description:
        "Ajoutez un chiffre et une date pour rendre cet objectif mesurable.",
    add_date: "Ajouter une date",
    indicator: "Indicateur :",
    unit: "Unité",
    no_indicator_selected: "Pas d'indicateur associé",
    no_indicator_selected_hint:
        "Choisissez un indicateur pour évaluer votre objectif et suivre votre progression.",
    no_indicator_selected_button: "Sélectionner un indicateur",
    edit: "Modifier",
    delete: "Supprimer",
    latest_value: "Dernière valeur",
    disabled_targets_tooltip:
        "Ne peut pas être modifié si des objectifs chiffrés sont définis",
    show_example: "Afficher un exemple",
    update_success: "Objectif mis à jour",
    upddate_error: "Erreur lors de la mise à jour de l'objectif",
    latest_period: "Basé sur les données de reporting de {{period}}",
    no_latest_period: "Aucune donnée reporting disponible",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourceKeys, ResourceKeys>;

export const RESOURCE_NAME = "ObjectiveBody";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
