import { currentUserAtom } from "@app/store/userStore";
import { TCollabThread } from "@hocuspocus/provider";
import { useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUpdateLastCommentedAt } from "../data";
import { useEditorContext } from "./useEditor";
import { useNotifications } from "@app/components/Notifications/useNotifications";

export const useThreads = () => {
    const [threads, setThreads] = useState<TCollabThread[]>([]);
    const { editor, provider, version } = useEditorContext();
    const currentUser = useAtomValue(currentUserAtom).data?.currentUser;

    const resolvedThreads = useMemo(
        () => threads.filter((thread) => thread.resolvedAt),
        [threads],
    );
    const activeThreads = useMemo(
        () => threads.filter((thread) => !thread.resolvedAt),
        [threads],
    );

    const { mutate: updateLastCommentedAt } = useUpdateLastCommentedAt(
        version?.id,
    );
    const { notifyNewCommentOnDoc } = useNotifications();
    const handleModifiedComment = useCallback(() => {
        version?.document?.id &&
            notifyNewCommentOnDoc({
                documentId: version.document.id,
            });
        updateLastCommentedAt();
    }, [notifyNewCommentOnDoc, version?.document?.id, updateLastCommentedAt]);

    useEffect(() => {
        if (!provider) {
            return () => null;
        }

        const getThreads = () => {
            setThreads(provider.getThreads());
        };

        getThreads();

        provider.watchThreads(getThreads);

        return () => {
            provider.unwatchThreads(getThreads);
        };
    }, [provider]);

    const createThread = useCallback(
        (comment: string | undefined) => {
            if (!comment) {
                return;
            }

            if (!editor) {
                return;
            }

            editor
                .chain()
                .focus()
                .setThread({
                    content: comment,
                    data: {
                        user: currentUser,
                    },
                    commentData: {
                        user: currentUser,
                    },
                })
                .run();

            handleModifiedComment();
        },
        [editor, currentUser, handleModifiedComment],
    );

    const removeThread = useCallback(
        (thread: TCollabThread) => {
            editor?.commands.removeThread({
                id: thread?.id,
                deleteThread: true,
            });

            handleModifiedComment();
        },
        [editor, updateLastCommentedAt],
    );

    const resolveThread = useCallback(
        (thread: TCollabThread) => {
            editor?.commands.resolveThread({
                id: thread?.id,
            });

            handleModifiedComment();
        },
        [editor, handleModifiedComment],
    );
    const unresolveThread = useCallback(
        (thread: TCollabThread) => {
            editor?.commands.unresolveThread({
                id: thread?.id,
            });

            handleModifiedComment();
        },
        [editor, handleModifiedComment],
    );
    return {
        threads,
        createThread,
        removeThread,
        resolveThread,
        unresolveThread,
        resolvedThreads,
        activeThreads,
    };
};
