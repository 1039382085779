import Container from "@app/components/Container";

import BodyHeader from "@app/shared/components/BodyHeader";
import EntitySelect from "@app/shared/components/EntitySelect";
import { useEntities } from "@app/store/entityStore";
import SearchBar from "@design-system/Inputs/SearchBar";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { useAtom } from "jotai";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PeriodSelect from "../Reporting/components/PeriodSelect";
import {
    activeReportingEntityIdAtom,
    reportingContributorsSearchFilterAtom,
} from "../Reporting/context";
import ReportingProgressTable from "./components/ReportingProgressTable";
import "./i18n";
import { PeriodManagementModal } from "../Reporting/components/PeriodManagementModal";

const ReportingProgress: FC = () => {
    const { t } = useTranslation("ReportingContributorsPage");
    const { entities, isPending: isEntitiesLoading } = useEntities();
    const [activeReportingEntityId, setActiveReportingEntityId] = useAtom(
        activeReportingEntityIdAtom,
    );
    const [search, setSearch] = useAtom(reportingContributorsSearchFilterAtom);

    useEffect(() => {
        if (
            !activeReportingEntityId &&
            !isEntitiesLoading &&
            entities?.length
        ) {
            setActiveReportingEntityId(entities[0].id);
        }
    }, [entities, activeReportingEntityId]);

    return (
        <Container>
            <BodyHeader title={t("page_title")} subTitle={t("page_subtitle")} />

            <FlexCol p="4" gap="4" alignItems="start" alignSelf="stretch">
                <FlexRow
                    justifyContent="between"
                    alignItems="center"
                    alignSelf="stretch"
                >
                    <FlexRow alignItems="center" gap="5" className="w-[782px]">
                        {!!entities?.length && (
                            <EntitySelect
                                entityId={activeReportingEntityId}
                                setEntityId={setActiveReportingEntityId}
                            ></EntitySelect>
                        )}
                        <PeriodSelect />
                    </FlexRow>
                    <FlexRow gap="4">
                        <SearchBar
                            searchString={search}
                            setSearchString={setSearch}
                            placeholder={t("search_placeholder")}
                        />
                    </FlexRow>
                </FlexRow>

                <ReportingProgressTable />
            </FlexCol>
            <PeriodManagementModal />
        </Container>
    );
};

export default ReportingProgress;
