import { graphql } from "@generated/client";
import { type DocumentPatch } from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const GetDocumentsForSearchQuery = graphql(`
    query GetDocumentsForSearch {
        documents(filter: { activationStatus: RELEVANT }) {
            id
            name
            lastVersion {
                id
                status
            }
            theme {
                id
                name
                color
            }
            subtheme {
                id
                name
            }
            activationStatus
        }
    }
`);

const CreateCustomDocumentMutation = graphql(`
    mutation CreateCustomDocument($input: CreateDocumentV2Input!) {
        createDocument2(input: $input) {
            id
            name
            uniqueUrl
            theme {
                name
                color
            }
        }
    }
`);

const UpdateDocumentsV2Mutation = graphql(`
    mutation updateDocumentsV2($ids: [String!]!, $patch: DocumentPatch!) {
        updateDocumentsV2(ids: $ids, set: $patch) {
            id
        }
    }
`);

export class GraphQLDocumentRepository {
    createCustomDocument = async (input: { name: string }) =>
        graphqlClientKoyeb.request(
            CreateCustomDocumentMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    getDocumentsForSearch = async () => {
        return graphqlClientKoyeb.request(
            GetDocumentsForSearchQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };
    updateMany = async (input: { ids: string[]; patch: DocumentPatch }) => {
        return graphqlClientKoyeb.request(
            UpdateDocumentsV2Mutation,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
