import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { useState } from "react";
import "../../i18n";
import { useAtom } from "jotai";
import { openPeriodManagementModalAtom } from "../../context";
import "./i18n";
import { RESOURCE_NAME, keys } from "./i18n";
import { useTranslation } from "react-i18next";
import { Sidebar } from "./Sidebar";
import { AddPeriodButtons } from "./AddPeriodButtons";
import { AddPeriodBox } from "./AddPeriodBox";
import { PeriodBox } from "./PeriodBox";

export const PeriodManagementModal = () => {
    const [open, setOpen] = useAtom(openPeriodManagementModalAtom);
    const [selectedYear, setSelectedYear] = useState<number>(2024);

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <Modal open={open} onOpenChange={setOpen}>
            <Modal.Overlay scrollable>
                <Modal.Content className="w-[620px] h-[620px]">
                    <Modal.Header className="border-b border-neutral-150">
                        <Modal.Title>{t(keys.modal_title)}</Modal.Title>
                        <Modal.Subtitle>
                            {t(keys.modal_subtitle)}
                        </Modal.Subtitle>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                        <FlexRow className="h-[535px]">
                            <FlexCol className="border-r border-neutral-150">
                                <Sidebar
                                    selectedYear={selectedYear}
                                    setSelectedYear={setSelectedYear}
                                />
                            </FlexCol>
                            <FlexCol
                                gap="3"
                                w="full"
                                px="3"
                                py="4"
                                className="overflow-auto max-h-full"
                            >
                                <Text variant="header3" color="primary">
                                    {selectedYear}
                                </Text>
                                <AddPeriodButtons selectedYear={selectedYear} />
                                <AddPeriodBox selectedYear={selectedYear} />
                                <PeriodBox
                                    type="year"
                                    selectedYear={selectedYear}
                                />
                                <PeriodBox
                                    type="quarter"
                                    selectedYear={selectedYear}
                                />
                                <PeriodBox
                                    type="month"
                                    selectedYear={selectedYear}
                                />
                            </FlexCol>
                        </FlexRow>
                    </Modal.Body>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
