import { queryClient } from "@app/QueryClientWithHeaders";
import { GraphQLDocumentRepository } from "@app/repositories/GraphQLRepositories/DocumentRepository";
import {
    DocumentExpirationStatus,
    DocumentPatch,
    DocumentType,
} from "@generated/client/graphql";
import { atom } from "jotai";
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query";
import { atomFamily, atomWithStorage } from "jotai/utils";
import { documentKeys } from "./queryKeys";

export const documentURLAtom = atom<string | null>(null);

// init
const documentRepository = new GraphQLDocumentRepository();

export const documentsForSearchAtom = atomWithQuery(() => {
    return {
        queryKey: [documentKeys.documentsForSearch],
        queryFn: documentRepository.getDocumentsForSearch,
    };
});

export const createCustomDocumentMutationAtom = atomWithMutation(() => ({
    mutationKey: [documentKeys.createCustomDocument],
    mutationFn: documentRepository.createCustomDocument,
    onSettled: () => {
        queryClient.invalidateQueries({
            queryKey: [documentKeys.documentsForSearch],
        });
    },
}));

export const updateDocumentMutation = atomWithMutation(() => ({
    mutationKey: [documentKeys.updateManyDocuments],
    mutationFn: async ({
        ids,
        patch,
    }: {
        ids: string[];
        patch: DocumentPatch;
    }) => {
        await documentRepository.updateMany({
            ids,
            patch,
        });
    },
    onSettled() {
        queryClient.invalidateQueries({
            queryKey: [documentKeys.GetDocumentsV2],
        });
    },
}));

export const selectedSubthemesAtom = atom<(string | null)[]>([]);
export const searchThemesFilterAtom = atom<string | undefined>(undefined);

type DocumentFilterState = Record<DocumentType, (string | null)[]>;

export const selectedTagsAtomFamily = atomFamily(
    (tenantUname: string | undefined) =>
        atomWithStorage<DocumentFilterState>(
            `documentSelectedTags_${tenantUname}`,
            {
                [DocumentType.Policy]: [],
                [DocumentType.CustomDocument]: [],
                [DocumentType.MasterPolicy]: [],
                [DocumentType.Misc]: [],
                [DocumentType.Procedure]: [],
                [DocumentType.Unknown]: [],
            },
        ),
);

export const selectedUserIdsAtomFamily = atomFamily(
    (tenantUname: string | undefined) =>
        atomWithStorage<DocumentFilterState>(
            `documentSelectedUsers_${tenantUname}`,
            {
                [DocumentType.Policy]: [],
                [DocumentType.CustomDocument]: [],
                [DocumentType.MasterPolicy]: [],
                [DocumentType.Misc]: [],
                [DocumentType.Procedure]: [],
                [DocumentType.Unknown]: [],
            },
        ),
);

type DocumentStatusFilterState = Record<
    DocumentType,
    DocumentExpirationStatus[]
>;

export const selectedStatusesAtomFamily = atomFamily(
    (tenantUname: string | undefined) =>
        atomWithStorage<DocumentStatusFilterState>(
            `documentSelectedStatuses_${tenantUname}`,
            {
                [DocumentType.Policy]: [],
                [DocumentType.CustomDocument]: [],
                [DocumentType.MasterPolicy]: [],
                [DocumentType.Misc]: [],
                [DocumentType.Procedure]: [],
                [DocumentType.Unknown]: [],
            },
        ),
);

type DocumentTierFilterState = Record<DocumentType, number | undefined>;

export const selectedTierAtomFamily = atomFamily(
    (tenantUname: string | undefined) =>
        atomWithStorage<DocumentTierFilterState>(
            `documentSelectedTier_${tenantUname}`,
            {
                [DocumentType.Policy]: undefined,
                [DocumentType.CustomDocument]: undefined,
                [DocumentType.MasterPolicy]: undefined,
                [DocumentType.Misc]: undefined,
                [DocumentType.Procedure]: undefined,
                [DocumentType.Unknown]: undefined,
            },
        ),
);
