import { useSetToast } from "@app/components/Toast";
import {
    GetObjectiveQuery,
    ObjectiveTargetPatch,
    ObjectiveTargetStatus,
} from "@generated/client/graphql";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteObjectiveTarget, useUpdateObjectiveTarget } from "../../data";
import { RESOURCE_NAME, keys } from "./i18n";

export function useTarget(
    target: GetObjectiveQuery["objective"]["targets"][number],
    objectiveId: string,
) {
    const { mutate } = useUpdateObjectiveTarget(target.id);
    const { mutate: deleteTarget } = useDeleteObjectiveTarget(
        target.id,
        objectiveId,
    );
    const [status, setStatus] = useState<ObjectiveTargetStatus>(target.status);
    const [date, setDate] = useState<Date | undefined>(target.date);
    const [value, setValue] = useState<number | undefined>(
        target.value?.number,
    );

    const initState = (
        target: GetObjectiveQuery["objective"]["targets"][number],
    ) => {
        setStatus(target.status);
        setDate(target.date);
        setValue(target.value?.number);
    };

    const targetState = { status, setStatus, date, setDate, value, setValue };

    const { setToastSuccess, setToastError } = useSetToast();
    const { t } = useTranslation(RESOURCE_NAME);

    const handleChange = (patch: ObjectiveTargetPatch) => {
        if (!target.id) return;

        mutate(
            {
                id: target.id,
                patch,
            },
            {
                onSuccess: () => {
                    setToastSuccess(t(keys.update_successs));
                },
                onError: () => {
                    setToastError(t(keys.update_error));
                },
            },
        );
    };

    const handleDelete = () => {
        if (!target.id) return;

        deleteTarget(target.id, {
            onSuccess: () => {
                setToastSuccess(t(keys.delete_success));
            },
            onError: () => {
                setToastError(t(keys.delete_error));
            },
        });
    };
    return {
        targetState,
        handleChange,
        handleDelete,
        initState,
    };
}
