import { Icon } from "@design-system/Icon";
import { Button, type ButtonProps } from "@design-system/Inputs/Button";
import { Box, BoxProps } from "@design-system/Layout/Box";
import {
    FlexCol,
    FlexRow,
    type OrientedFlexProps,
} from "@design-system/Layout/Flex";
import { Text, type TextProps } from "@design-system/Typography/Text";
import { cn, type ClassValue } from "@design-system/Utilities";
import * as RadixDialog from "@radix-ui/react-dialog";
import { forwardRef, type PropsWithChildren } from "react";

const CloseSmall = forwardRef<
    HTMLButtonElement,
    Omit<RadixDialog.DialogCloseProps, "children">
>(({ className, ...props }, ref) => (
    <RadixDialog.Close {...props} ref={ref}>
        <Icon
            name="close"
            className={cn(
                "p-0.5 bg-tertiary hover:bg-secondary text-secondary",
                "rounded-lg",
                "top-[16px] right-[16px] absolute",
                className,
            )}
        />
    </RadixDialog.Close>
));

const Header = forwardRef<
    HTMLDivElement,
    PropsWithChildren<{
        className?: ClassValue;
        /**
         * boolean: if true, a close button will be added to the header
         * @default false
         * */
        withClose?: boolean;
    }>
>(({ children, className, withClose = false }, ref) => (
    <FlexCol
        as="header"
        className={cn(
            "relative",
            "Beavr_modal__content_header_grid_area",
            className,
        )}
        h="fit"
        pb="3"
        pt="5"
        px="5"
        ref={ref}
        w="full"
    >
        {children}
        {!!withClose && <CloseSmall />}
    </FlexCol>
));

const Title = forwardRef<HTMLHeadingElement, TextProps>((props, ref) => (
    <RadixDialog.DialogTitle asChild>
        <Text {...props} as={props.as ?? "h2"} ref={ref} variant="header1" />
    </RadixDialog.DialogTitle>
));

const Subtitle = forwardRef<HTMLParagraphElement, TextProps>((props, ref) => (
    <RadixDialog.DialogDescription asChild>
        <Text
            {...props}
            as={props.as ?? "p"}
            className={cn("text-secondary", props.className)}
            ref={ref}
            variant="body"
        />
    </RadixDialog.DialogDescription>
));

const Trigger = RadixDialog.DialogTrigger;

const Overlay = forwardRef<
    HTMLDivElement,
    RadixDialog.DialogOverlayProps & {
        /**
         * boolean: center the content vertically; if unset,
         * or set to false, the content will be placed at the top
         * of the modal, with a py-10 (padding-top: 56px & padding-bottom: 56px)
         * @default false
         */
        centerContent?: boolean;
        /**
         * boolean: allow the overlay to be scrollable
         * @default false
         */
        scrollable?: boolean;
    }
>(({ centerContent = false, className, scrollable = false, ...props }, ref) => (
    <RadixDialog.Portal>
        <RadixDialog.Overlay
            {...props}
            className={cn(
                "fixed inset-0 top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 flex justify-center",
                "data-[state='open']:animate-[fadeIn_0.3s_ease-in-out] data-[state='closed']:animate-[fadeOut_0.3s_ease-in-out]",
                centerContent ? "items-center" : "py-10",
                scrollable && "overflow-auto",
                className,
            )}
            ref={ref}
        />
    </RadixDialog.Portal>
));

const Footer = forwardRef<HTMLDivElement, OrientedFlexProps>(
    ({ className, ...props }, ref) => (
        <FlexRow
            {...props}
            alignItems="start"
            as="footer"
            className={cn("Beavr_modal__content_footer_grid_area", className)}
            gap="4"
            h="fit"
            justifyContent="end"
            pb="5"
            pt="3"
            px="5"
            ref={ref}
            w="full"
        />
    ),
);

const Close = RadixDialog.Close;

type ModalCTAProps = Omit<ButtonProps, "size">;

/**
 * CancelCTA is a button that closes the modal, already styled.
 */
const CancelCTA = forwardRef<HTMLButtonElement, ModalCTAProps>(
    ({ ...props }, ref) => (
        <RadixDialog.Close asChild>
            <Button {...props} ref={ref} variant="plain" size="lg" />
        </RadixDialog.Close>
    ),
);

/**
 * ConfirmCTA is a button that confirms an action and closes the modal, already styled.
 * If you want to prevent the modal from closing in un uncontrolled modal, you can use
 * the "preventClose" prop.
 */
const ConfirmCTA = forwardRef<
    HTMLButtonElement,
    ModalCTAProps & {
        /**
         * boolean: if true, the modal will not close when the button is clicked
         * (useful for uncontrolled modals)
         * @default false
         */
        preventClose?: boolean;
    }
>(({ preventClose = false, variant = "flatPrimary", ...props }, ref) =>
    preventClose ? (
        <Button {...props} ref={ref} variant="flatPrimary" size="lg" />
    ) : (
        <RadixDialog.Close asChild>
            <Button {...props} ref={ref} variant={variant} size="lg" />
        </RadixDialog.Close>
    ),
);

const Body = forwardRef<HTMLDivElement, BoxProps>(
    ({ className, ...props }, ref) => (
        <Box
            {...props}
            className={cn("Beavr_modal__content_body_grid_area", className)}
            py="3"
            px="5"
            ref={ref}
            w="full"
        />
    ),
);

const Content = forwardRef<HTMLDivElement, RadixDialog.DialogContentProps>(
    (props, ref) => (
        <RadixDialog.DialogContent
            {...props}
            className={cn(
                "bg-white shadow-b-300 rounded-lg",
                "focus:outline-none",
                "grid Beavr_modal__content_grid_areas",
                "w-1/2 h-fit",
                "data-[state='open']:animate-[fadeIn_0.3s_ease-in-out] data-[state='closed']:animate-[fadeOut_0.3s_ease-in-out]",
                props.className,
            )}
            ref={ref}
        />
    ),
);

const Separator = forwardRef<HTMLHRElement, BoxProps>((props, ref) => (
    <Box
        {...props}
        className={cn("border-t border-tertiary", props.className)}
        ref={ref}
    />
));

type ModalComponentType = typeof RadixDialog.Root & {
    Header: typeof Header;
    Title: typeof Title;
    Subtitle: typeof Subtitle;
    Trigger: typeof Trigger;
    Overlay: typeof Overlay;
    Footer: typeof Footer;
    Body: typeof Body;
    Content: typeof Content;
    Close: typeof Close;
    CancelCTA: typeof CancelCTA;
    ConfirmCTA: typeof ConfirmCTA;
    Separator: typeof Separator;
};

export const Modal = RadixDialog.Root as ModalComponentType;

Modal.Header = Header;
Modal.Title = Title;
Modal.Subtitle = Subtitle;
Modal.Trigger = Trigger;
Modal.Overlay = Overlay;
Modal.Footer = Footer;
Modal.Body = Body;
Modal.Content = Content;
Modal.Close = Close;
Modal.CancelCTA = CancelCTA;
Modal.ConfirmCTA = ConfirmCTA;
Modal.Separator = Separator;
