import { Mail, Xmark } from "iconoir-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as BeavrLogo } from "../../../../assets/beavr-h10.svg";
import { ReactComponent as BeavrSmallLogo } from "../../../../assets/top-logo.svg";
import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "Footer", {
    powered_by: "Powered by",
    about: `Beavr is the fast, trusted solution for managing CSR compliance. 
    Beavr helps you strengthen your CSR management system (policies, actions, reporting) and prepare for CSR labels and certifications so that you can win you stakeholders' trust. 
    Dozens of companies already use Beavr to build stakeholders’ trust and boost their brand while cutting costs, time and efforts.`,
    contact_us: "Contact us to learn more",
});
i18next.addResourceBundle("fr", "Footer", {
    powered_by: "Propulsé par",
    about: `Beavr est la solution rapide et fiable pour gérer la conformité RSE.
    Beavr vous aide à renforcer votre système de management RSE (politiques, actions, reporting) et à vous préparer aux labels et certifications RSE pour gagner la confiance de vos parties prenantes.
    Des dizaines d'entreprises utilisent déjà Beavr pour renforcer la confiance de leurs parties prenantes et booster leur marque tout en réduisant les coûts, le temps et les efforts.`,
    contact_us: "Contactez-nous pour en savoir plus",
});

const Footer = ({ logoSrc }: { logoSrc?: string }) => {
    const { t } = useTranslation("Footer");
    return (
        <div className=" w-full border-t border-tertiary">
            <div className="pt-[60px] pb-[120px] flex justify-center space-x-[40px] ">
                <div className="w-[300px]">
                    {logoSrc && (
                        <div className="flex space-w-4 items-center">
                            <img className="max-h-[32px]" src={logoSrc} />
                            <Xmark className="text-black" />
                            <BeavrSmallLogo />
                        </div>
                    )}
                    <div className=" text-sm whitespace-nowrap">
                        {t("powered_by")}
                    </div>
                    <BeavrLogo className="h-3 text-green" />
                </div>
                <div className="space-y-5">
                    <div className=" text-lg font-regular max-w-[780px]">
                        {t("about")}
                    </div>
                    <div className="flex space-x-2">
                        <Mail className="w-5 h-5 text-green" />
                        <a
                            className="text-green"
                            href="mailto:contact@beavr.tech"
                        >
                            {t("contact_us")}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
