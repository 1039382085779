import i18next from "@app/i18n";

const enResources = {
    questionnaire_title: "Last questionnaire",
    questionnaire_title_active: "Last questionnaire ({{count}})",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    questionnaire_title: "Dernier questionnaire",
    questionnaire_title_active: "Dernier questionnaire ({{count}})",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "SupplierQuestionnaireMultiSelect";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
