import { useTranslation } from "react-i18next";
import Avatar from "../../../User/Avatar/index.tsx";

import { TCollabThread } from "@hocuspocus/provider";
import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import { CheckCircle2, MoreHorizontal, Trash2, Undo2 } from "lucide-react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useThreads } from "../../../../screens/Document/Edit/hooks/useComments.tsx";
import BaseItem from "../../../Dropdown/components/dropdowns/items/BaseItem.tsx";
import DropdownContentLayout from "../../../Dropdown/components/dropdowns/layout";
import { Tooltip } from "../../../Tooltip/index.tsx";
import "./i18n";

const CommentBox = ({ thread }: { thread: TCollabThread }) => {
    const { t } = useTranslation("DocumentEditorSideBar");
    dayjs.extend(relativeTime);
    dayjs.extend(LocalizedFormat);
    const { removeThread, resolveThread, unresolveThread } = useThreads();

    const formatDate = (inputDateStg: string) => {
        const inputDate = new Date(inputDateStg);
        const daysDiff = dayjs(thread?.comments[0]?.updatedAt).diff(
            Date.now(),
            "day",
        );
        if (daysDiff === 0) {
            return "Today";
        } else if (daysDiff == 1) {
            return "Yesterday";
        } else {
            return dayjs(inputDate).format("LL");
        }
    };

    return (
        <div className="flex py-3 px-4 items-start gap-2.5 self-stretch rounded-xl border border-tertiary bg-secondary shadow-sm w-full">
            {thread?.data?.user && (
                <Tooltip
                    tooltipContent={`${thread?.data?.user?.firstName} ${thread?.data?.user?.lastName}`}
                    side="left"
                >
                    <Avatar
                        size="medium"
                        initials={thread?.data?.user?.initials || "??"}
                    />
                </Tooltip>
            )}
            <div className="flex flex-col items-start flex-1 overflow-hidden">
                <div className="flex justify-between items-center self-stretch">
                    <div className="flex items-center gap-2">
                        <div className="font-bold text-sm">
                            {thread?.data?.user?.firstName || ""}
                        </div>
                        <div className="text-secondary text-xs">
                            {formatDate(thread?.comments[0]?.updatedAt)}
                        </div>
                    </div>

                    <div className="flex items-center gap-3">
                        <div className="flex p-0.5 items-center">
                            {thread?.resolvedAt ? (
                                <Tooltip tooltipContent={t("unresolve")}>
                                    <Undo2
                                        className="w-4 h-4"
                                        onClick={() => unresolveThread(thread)}
                                    />
                                </Tooltip>
                            ) : (
                                <Tooltip tooltipContent={t("mark_as_resolved")}>
                                    <CheckCircle2
                                        className="w-4 h-4"
                                        onClick={() => resolveThread(thread)}
                                    />
                                </Tooltip>
                            )}
                        </div>
                        <DropdownMenu modal={false}>
                            <DropdownPrimitive.Trigger>
                                <div className="flex items-center justify-center gap-2 hover:bg-tertiary h-6 w-6 rounded-lg">
                                    <MoreHorizontal className="w-4 h-4" />
                                </div>
                            </DropdownPrimitive.Trigger>
                            <DropdownContentLayout
                                align="end"
                                className="w-[240px]"
                            >
                                <BaseItem
                                    IconLeft={Trash2}
                                    onClick={() => removeThread(thread)}
                                >
                                    {t("delete_comment")}
                                </BaseItem>
                            </DropdownContentLayout>
                        </DropdownMenu>
                    </div>
                </div>
                <div className="text-sm break-words whitespace-pre-wrap w-full">
                    <ReactMarkdown
                        className="markdown text-base font-regular"
                        children={thread?.comments[0]?.content || ""}
                        remarkPlugins={[remarkGfm]}
                    />
                </div>
            </div>
        </div>
    );
};

export default CommentBox;
