import { useTheme } from "@app/store/themeStore";
import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME, keys } from "./i18n";
import { Ellipsis } from "@design-system/Typography/Ellipsis";

export const SubthemeTag: FC<{
    themeId: string;
    subthemeId?: string;
    isTrigger?: boolean;
    disabled?: boolean;
    className?: string;
}> = ({ themeId, subthemeId, isTrigger, disabled = false, className }) => {
    const currentTheme = useTheme(themeId);
    const currentSubtheme =
        subthemeId &&
        currentTheme?.subthemes?.find((subtheme) => subtheme.id === subthemeId);

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexRow
            alignItems="center"
            justifyContent="center"
            className={cn(
                "border border-neutral-200 rounded-xl p-0.5 max-h-[26px]",
                disabled && "pointer-events-none cursor-default",
                isTrigger && "cursor-pointer",
                className,
            )}
            style={{
                color: currentTheme?.color,
            }}
        >
            <Icon name="tagEmptyCircle" size="sm" />
            <Ellipsis asChild hasTooltip>
                <Text variant="body-sm" color="primary" px="1">
                    {currentSubtheme
                        ? currentSubtheme?.name
                        : isTrigger
                          ? t(keys.select_subtheme)
                          : t(keys.no_subtheme)}
                </Text>
            </Ellipsis>
        </FlexRow>
    );
};
