import { ColumnDef } from "@tanstack/react-table";
import { EditPencil, InfoCircle, Trash } from "iconoir-react";
import { useTranslation } from "react-i18next";
import {
    GetSupplierIndicatorsDocument,
    Indicator,
} from "../../../generated/client/graphql";

import { useMemo } from "react";
import { useGraphQL } from "../../QueryClientWithHeaders";

import { useSetAtom } from "jotai";
import ActionDropdown from "../../components/Table/ActionDropdown";
import ActionItemDanger from "../../components/Table/ActionItemDanger";
import CheckboxCell from "../../components/Table/CheckboxCell";
import Header from "../../components/Table/Header";
import CheckboxHeader from "../../components/Table/Header/Checkbox";
import ColorTag from "../../components/Tags/ColorTag";
import { Tooltip } from "../../components/Tooltip";
import { ActionDropdownMenuItem } from "../ActionDropdown";
import DocumentModeFilter from "../Dropdown/dropdowns/DocumentMode/filter";
import DocumentModeSelect from "../Dropdown/dropdowns/DocumentMode/select";
import ThemesFilter from "../Dropdown/dropdowns/Themes/filter";
import { useQuestionnairesCol } from "./columns_suppliers";
import { updateIndicatorAtom } from "./components/Update";
import { useUpdateIndicator } from "./hooks";
import "./i18n";

const selectCol: ColumnDef<Indicator> = {
    id: "select",
    header: ({ table }) => {
        const { t } = useTranslation("IndicatorTable");
        return <CheckboxHeader table={table} title={t("idx")} />;
    },
    cell: ({ row }) => (
        <CheckboxCell row={row} text={`${row.original.order}`} />
    ),
    enableSorting: false,
    enableHiding: false,
    meta: {
        colWidth: "7%",
    },
};

const themeCol: ColumnDef<Indicator> = {
    id: "theme",
    filterFn: "arrIncludesSome",
    accessorKey: "theme.id",
    header: () => {
        return <ThemesFilter />;
    },
    meta: {
        colWidth: "15%",
    },
    cell: ({ row }) => {
        return (
            <div className=" max-w-fit">
                <ColorTag
                    color="green"
                    text={
                        row.original.subtheme?.name ||
                        row.original.theme?.name ||
                        ""
                    }
                />
            </div>
        );
    },
};
const nameCol: ColumnDef<Indicator> = {
    id: "name",
    accessorKey: "name",
    meta: {
        colWidth: "40%",
    },
    header: ({ column }) => {
        const { t } = useTranslation("IndicatorTable");
        return <Header title={t("indicator")} column={column} />;
    },
    cell: ({ row }) => {
        const { t } = useTranslation("IndicatorTable");
        return (
            <div className="flex space-x-2 items-center justify-between">
                <div className="text-sm font-bold">{row.original.name}</div>

                <div>
                    <Tooltip
                        side="left"
                        sideOffset={10}
                        tooltipContent={
                            <div className="space-y-2  ">
                                <p className="text-white text-justify">
                                    <span className="font-bold">
                                        {t("unit")}:{" "}
                                    </span>
                                    {row.original.unit?.name}
                                </p>
                                {["SELECT", "MULTI_SELECT"].includes(
                                    row.original.unit?.type || "",
                                ) && (
                                    <div>
                                        <div className="text-sm text-white font-bold">
                                            Options:
                                        </div>
                                        {row.original.options?.map((o) => (
                                            <div className="text-sm text-white">
                                                {o.name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {row.original.description && (
                                    <p className="text-white text-justify w-[300px]">
                                        <span className="font-bold">
                                            {t("description")}:{" "}
                                        </span>
                                        {row.original.description}
                                    </p>
                                )}
                            </div>
                        }
                    >
                        <InfoCircle
                            className="h-4 w-4"
                            id={`desc-${row.original.id}`}
                        />
                    </Tooltip>
                </div>
            </div>
        );
    },
};

const useDocumentAcceptanceCol = (
    getIndicatorsKey: any[],
): ColumnDef<Indicator> =>
    useMemo(
        () => ({
            accessorKey: "documentAcceptance",
            header: () => {
                return <DocumentModeFilter />;
            },
            cell: ({ row }) => {
                const { updateIndicator } = useUpdateIndicator(
                    row.original.id,
                    getIndicatorsKey,
                );

                return (
                    <DocumentModeSelect
                        mode={row.original.documentAcceptance || undefined}
                        onModeChange={(m) =>
                            updateIndicator({ documentAcceptance: m })
                        }
                    />
                );
            },
        }),
        [getIndicatorsKey],
    );
const actionCol: ColumnDef<Indicator> = {
    id: "actions",
    meta: {
        colWidth: "5%",
    },
    cell: ({ row }) => {
        const { t } = useTranslation("IndicatorTable");
        const setUpdateIndicator = useSetAtom(updateIndicatorAtom);
        return (
            <ActionDropdown>
                <ActionItemDanger icon={Trash} title={t("delete")} disabled />
                <ActionDropdownMenuItem
                    Icon={EditPencil}
                    text={t("edit")}
                    onClick={() =>
                        setUpdateIndicator(row.original as Indicator)
                    }
                />
            </ActionDropdown>
        );
    },
};

export const useColumns = (): ColumnDef<Indicator>[] => {
    const key = useGraphQL.getKey(GetSupplierIndicatorsDocument);
    const documentAcceptanceCol = useDocumentAcceptanceCol(key);
    const questionnaireCol = useQuestionnairesCol(key);
    return useMemo(() => {
        return [
            selectCol,
            themeCol,
            nameCol,
            documentAcceptanceCol,
            questionnaireCol,
            actionCol,
        ];
    }, []);
};
