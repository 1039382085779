import i18next from "@app/i18n";

const enResources = {
    title: "My files",
    subtitle:
        "Track all the files you and your team have uploaded to the platform.",
    searchPlaceholder: "Search files",
    name_col_header: "Name",
    created_at_col_header: "Created at",
    type_col_header: "Type",
    type_col_header_active: "Type ({{count}})",
    type_document: "Document",
    type_Policy: "Policy",
    type_Measure: "Measure",
    type_Custom: "Custom Document",
    type_Csrd: "Csrd datapoint",
    type_Indicator: "Indicator",
    file_source_col: "Source",
    file_source_col_active: "Source ({{count}})",
    file_source_uploaded: "File upload",
    file_source_platform: "Beavr",
    used_in_links_col_header: "Links",
    no_file_found_title: "No file found",
    no_file_found_subtitle: "Try adding files or using another search term.",
    ongoing: "In progress",
    validated: "Validated",
    not_material: "Not material",
    not_started: "Not started",
    source_tooltip:
        "Either this file was uploaded to the Beavr platform or the platform was used to redact it",
    action_other: "Download {{count}} files",
    action_one: "Download {{count}} file",
    download: "Download",
    download_success: "Download successful",
    download_error: "Download failed",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    title: "Mes fichiers",
    subtitle:
        "Trouvez tous les fichiers que vous et votre équipe avez téléchargés sur la plateforme.",
    searchPlaceholder: "Rechercher des fichiers",
    name_col_header: "Nom",
    created_at_col_header: "Créé le",
    type_col_header: "Type",
    type_col_header_active: "Type ({{count}})",
    type_document: "Document",
    type_Policy: "Politique",
    type_Measure: "Mesure",
    type_Custom: "Document personnalisé",
    type_Csrd: "Point de donnée CSRD",
    type_Indicator: "Indicateur",
    file_source_col: "Source",
    file_source_col_active: "Source ({{count}})",
    file_source_uploaded: "Téléchargement",
    file_source_platform: "Beavr",
    used_in_links_col_header: "Liens",
    no_file_found_title: "Aucun fichier trouvé",
    no_file_found_subtitle:
        "Essayez d'ajouter des fichiers ou d'utiliser un autre terme de recherche.",
    ongoing: "En cours",
    validated: "Validé",
    not_material: "Non matériel",
    not_started: "Non commencé",
    source_tooltip:
        "Soit ce fichier a été téléchargé sur la plateforme Beavr, soit la plateforme a été utilisée pour le rédiger",
    action_other: "Télécharger {{count}} fichiers",
    action_one: "Télécharger {{count}} fichier",
    download: "Télécharger",
    download_success: "Téléchargement réussi",
    download_error: "Le téléchargement a échoué",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((k) => [k, k]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "AllFiles";

i18next.addResources("en", RESOURCE_NAME, enResources);
i18next.addResources("fr", RESOURCE_NAME, frResources);
