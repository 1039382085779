import { StatusTagCircular } from "@design-system/DataDisplay/StatusTagCircular";
import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import {
    ObjectiveTarget,
    ObjectiveTargetStatus,
} from "@generated/client/graphql";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { Text } from "@design-system/Typography/Text";
import { FC } from "react";

type TargetExampleDisplayProps = {
    target: ObjectiveTarget;
    unitShortName: string;
    isLast: boolean;
};

export const TargetExampleDisplay: FC<TargetExampleDisplayProps> = ({
    target,
    unitShortName,
    isLast,
}) => {
    const displayStatus =
        target.status === ObjectiveTargetStatus.Reached
            ? "validated"
            : target.status === ObjectiveTargetStatus.Ongoing
              ? "inProgress"
              : "missed";
    dayjs.extend(LocalizedFormat);

    const displayDate = dayjs(target.date).format("LL");

    return (
        <FlexRow gap="7" alignItems="center" className="w-full text-primary">
            <FlexRow
                justifyContent="center"
                alignItems="center"
                className="w-[28px] h-[28px] rounded-full border border-neutral-tertiary shrink-0"
            >
                <Icon
                    name={isLast ? "target2" : "flag"}
                    className="text-neutral-500"
                    size="xs"
                />
            </FlexRow>
            <FlexRow gap="3" alignItems="center">
                <StatusTagCircular status={displayStatus} />
                <FlexRow gap="1" alignItems="center">
                    <Text variant="body">{target.value}</Text>
                    <Text variant="body">{unitShortName}</Text>
                </FlexRow>
            </FlexRow>
            <FlexRow gap="6" alignItems="center">
                <Icon
                    name="arrowRight"
                    size="lg"
                    className="text-neutral-300"
                />
                <FlexRow gap="1" alignItems="center">
                    <Icon name="calendar" size="sm" />
                    <Text variant="body">{displayDate}</Text>
                </FlexRow>
            </FlexRow>
        </FlexRow>
    );
};
