import { GraphQLThemeRepository } from "@app/repositories/GraphQLRepositories/ThemeRepository";
import { GraphQLUnitRepository } from "@app/repositories/GraphQLRepositories/UnitRepository";
import { useQuery } from "@tanstack/react-query";
import { reportingKeys } from "../../data";

const keys = {
    createAll: [...reportingKeys.all, "create"] as const,
    loadUnits: () => [...keys.createAll, "loadUnits"] as const,
    loadThemesAndSubthemes: () =>
        [...keys.createAll, "loadThemesAndSubthemes"] as const,
};
const themeRepository = new GraphQLThemeRepository();
const unitRepository = new GraphQLUnitRepository();

export const useThemesAndSubtheme = () => {
    const { data } = useQuery({
        queryKey: keys.loadThemesAndSubthemes(),
        queryFn: async () => themeRepository.get(),
    });

    return {
        themes: data?.themes,
        subthemes: data?.themes.flatMap((theme) => theme.subthemes),
    };
};

export const useUnits = () => {
    const { data } = useQuery({
        queryKey: keys.loadUnits(),
        queryFn: async () => unitRepository.getAll(),
    });

    return data?.units;
};
