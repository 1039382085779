import { useSetToast } from "@app/components/Toast";
import { useUpdateObjective } from "@app/screens/Objectives/data";
import { SubthemeTagSelect } from "@app/shared/components/SubthemeTagSelect";
import { ThemeTagSelect } from "@app/shared/components/ThemeTagSelect";
import UserChipSelect from "@app/shared/components/UserChipSelect";
import { UserTag } from "@app/shared/components/UserTag";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { GetObjectiveQuery, ObjectiveStatus } from "@generated/client/graphql";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "./i18n";
import { NameInput } from "./NameInput";
import { StatusTagSelect } from "./StatusTagSelect";

type ObjectiveHeaderProps = {
    objective: GetObjectiveQuery["objective"];
};

export const ObjectiveHeader: FC<ObjectiveHeaderProps> = ({ objective }) => {
    const { mutate } = useUpdateObjective(objective?.id);
    const { t } = useTranslation(RESOURCE_NAME);
    const { setToastSuccess, setToastError } = useSetToast();

    const handleUserIdChange = (userId: string) => {
        const newOwnerId = !!userId ? userId : null;
        if (objective.ownerId === newOwnerId) return;
        mutate(
            { id: objective.id, ownerId: newOwnerId },
            {
                onSuccess: () => {
                    setToastSuccess(t(keys.success_update));
                },
                onError: () => {
                    setToastError(t(keys.error_update));
                },
            },
        );
    };

    const handleStatusChange = (status: ObjectiveStatus) => {
        if (objective.status === status) return;
        mutate(
            { id: objective.id, status },
            {
                onSuccess: () => {
                    setToastSuccess(t(keys.success_update));
                },
                onError: () => {
                    setToastError(t(keys.error_update));
                },
            },
        );
    };

    const handleThemeChange = (themeId: string) => {
        if (objective.themeId === themeId) return;
        mutate(
            { id: objective.id, themeId },
            {
                onSuccess: () => {
                    setToastSuccess(t(keys.success_update));
                },
                onError: () => {
                    setToastError(t(keys.error_update));
                },
            },
        );
    };

    const handleSubThemeChange = (subthemeId: string) => {
        if (objective.subthemeId === subthemeId) return;
        mutate(
            { id: objective.id, subthemeId },
            {
                onSuccess: () => {
                    setToastSuccess(t(keys.success_update));
                },
                onError: () => {
                    setToastError(t(keys.error_update));
                },
            },
        );
    };

    return (
        <FlexCol gap="1">
            <NameInput objective={objective} key={objective.id} />
            <FlexRow gap="4" alignItems="center">
                <ThemeTagSelect
                    themeId={objective.themeId}
                    setThemeId={handleThemeChange}
                    disabled={!!objective?.targets.length}
                    disabledTooltip={t(keys.disabled_targets_tooltip)}
                />
                <SubthemeTagSelect
                    themeId={objective.themeId}
                    subthemeId={objective.subthemeId || undefined}
                    setSubthemeId={handleSubThemeChange}
                    disabled={!!objective?.targets.length}
                    disabledTooltip={t(keys.disabled_targets_tooltip)}
                />
                <StatusTagSelect
                    status={objective.status}
                    setStatus={handleStatusChange}
                />
                <UserChipSelect
                    userId={objective.ownerId}
                    setUserId={handleUserIdChange}
                    trigger={
                        <FlexRow w="fit">
                            <UserTag
                                userId={objective.ownerId || undefined}
                                noUserText={t(keys.unassigned)}
                            />
                        </FlexRow>
                    }
                />
            </FlexRow>
        </FlexCol>
    );
};
