import { graphql } from "@generated/client";
import { type IndicatorEntityConfigPatch } from "../../../../generated/client/graphql";
import { AccessHeadersHolder } from "../../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../../clients";

const UpdateIndicatorEntityConfigMutation = graphql(`
    mutation updateIndicatorEntityConfig(
        $indicatorEntityConfigId: String!
        $set: IndicatorEntityConfigPatch!
    ) {
        updateIndicatorEntityConfig(
            indicatorEntityConfigId: $indicatorEntityConfigId
            set: $set
        )
    }
`);

const UpdateManyIndicatorEntityConfigMutation = graphql(`
    mutation updateManyIndicatorEntityConfig(
        $indicatorEntityConfigIds: [String!]!
        $set: IndicatorEntityConfigPatch!
    ) {
        updateManyIndicatorEntityConfig(
            indicatorEntityConfigIds: $indicatorEntityConfigIds
            set: $set
        )
    }
`);

export class GraphQLIndicatorEntityConfigRepository {
    update = async (input: {
        indicatorEntityConfigId: string;
        set: IndicatorEntityConfigPatch;
    }) => {
        await graphqlClientKoyeb.request(
            UpdateIndicatorEntityConfigMutation,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    updateMany = async (inputs: {
        indicatorEntityConfigIds: string[];
        set: IndicatorEntityConfigPatch;
    }) => {
        return graphqlClientKoyeb.request(
            UpdateManyIndicatorEntityConfigMutation,
            inputs,
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
