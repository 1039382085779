import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { AiThreadMessageRole } from "@generated/client/graphql";
import { type FC } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ThreadMessage } from "../types";
import {
    InlineMessageAnnotation,
    LinkMessageAnnotation,
} from "./MessageAnnotation";

const AiResponse: FC<{ message: string }> = ({ message }) => {
    return (
        <ReactMarkdown
            className="flex flex-col gap-4 text-base font-regular text-left w-full max-h-fit text-neutral-700"
            remarkPlugins={[remarkGfm]}
            components={{
                table: ({ children }) => (
                    <table className="w-full border rounded-md">
                        {children}
                    </table>
                ),
                th: ({ children }) => (
                    <th className="p-2 text-left font-bold border-b">
                        {children}
                    </th>
                ),
                td: ({ children }) => (
                    <td className="p-2 text-left border-b">{children}</td>
                ),
                p: ({ children }) => <Text variant="body">{children}</Text>,
                strong: ({ children }) => (
                    <Text as="span" variant="body-bold">
                        {children}
                    </Text>
                ),
                ol: ({ children }) => (
                    <ol className="pl-5 list-decimal list-outside">
                        {children}
                    </ol>
                ),
                ul: ({ children }) => (
                    <ul className="pl-5 list-disc list-outside">{children}</ul>
                ),
                li: ({ children }) => (
                    <li className="text-base list-item mb-2 last:mb-0">
                        {children}
                    </li>
                ),
                a: ({ children, href }) => {
                    if (!href) return null;

                    const [messageIdx] = href.split("-");
                    const openAiFileId = href.replace(`${messageIdx}-`, "");

                    return (
                        <InlineMessageAnnotation
                            messageIdx={Number(messageIdx)}
                            openAiFileId={openAiFileId}
                        >
                            {children}
                        </InlineMessageAnnotation>
                    );
                },
                // same for images
                img: ({ alt }) => <Box>{alt} - img</Box>,
            }}
        >
            {message}
        </ReactMarkdown>
    );
};

export const MessageBlock: FC<{
    idx: number;
    message: ThreadMessage;
    scrollIntoView: boolean;
}> = ({ idx, message }) => {
    return (
        <FlexCol>
            <FlexRow
                {...(message.role === AiThreadMessageRole.User
                    ? { justifyContent: "end" }
                    : {})}
            >
                {message.role === AiThreadMessageRole.User && (
                    <FlexRow
                        px="3"
                        py="3"
                        alignItems="center"
                        justifyContent="end"
                        className="rounded-b-[16px] rounded-tl-[16px] border border-tertiary bg-primary shadow-sm max-w-[250px]"
                    >
                        <Text variant="body">{message.content}</Text>
                    </FlexRow>
                )}
                {message.role === AiThreadMessageRole.Assistant && (
                    <AiResponse message={message.content} />
                )}
            </FlexRow>
            <FlexCol>
                {message.annotations?.map((annotation, annotationIdx) => (
                    <LinkMessageAnnotation
                        messageIdx={idx}
                        openAiFileId={annotation.openAiFileId}
                    >
                        {annotationIdx + 1}
                    </LinkMessageAnnotation>
                ))}
            </FlexCol>
        </FlexCol>
    );
};
