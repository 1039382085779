import "./i18n";

import {
    ExportDocumentsDocument,
    ExportFrameworkRequirementsDocument,
    Requirement,
} from "@generated/client/graphql";
import Fuse, { FuseResult } from "fuse.js";
import { useEffect, useMemo } from "react";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import { browserDownload } from "../../../../lib/utils-browser";

import { GraphQLFrameworkRepository } from "@app/repositories/GraphQLRepositories/FrameworkRepository";
import { adminPaths } from "@app/routing/paths";
import { useBreadcrumb } from "@app/shared/components/Breadcrumb/useBreadcrumb";
import { computeStats } from "@app/usecases/RequirementUseCases";
import { skipToken } from "@tanstack/query-core";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useFrameworks } from "../../data";
import { useFrameworkPageState } from "./pageState";

const frameworkRepository = new GraphQLFrameworkRepository();

const frameworkKeys = {
    all: ["framework"] as const,
    one: (id?: string) => [...frameworkKeys.all, id] as const,
};

const frameworkDocumentsKeys = {
    all: ["frameworkDocs"] as const,
    one: (id?: string) => [...frameworkDocumentsKeys.all, id] as const,
};

export const useFilteredFramework = (frameworkId?: string) => {
    const { state } = useFrameworkPageState();

    const { data, ...query } = useQuery({
        queryKey: frameworkKeys.one(frameworkId),
        queryFn: frameworkId
            ? () => frameworkRepository.get(frameworkId)
            : skipToken,
    });

    const framework = data?.framework;
    const options = {
        includeScore: true,
        ignoreLocation: true,
        threshold: 0,
        keys: ["name", "description", "cmsId", "group", "subgroup", "section"],
    };

    framework?.groups?.forEach((group) => {
        group.subgroups?.forEach((subGroup) => {
            subGroup?.sections?.forEach((section) => {
                section.requirements = Object.values(section.requirements).sort(
                    (a, b) => a.order - b.order,
                );
            });
        });
    });

    const fuse = useMemo(
        () =>
            new Fuse(
                data?.framework?.groups
                    ?.map((group) =>
                        group.subgroups?.map((sg) =>
                            sg.sections.map((s) =>
                                s.requirements.map((req) => ({
                                    ...req,
                                    group: group.name,
                                    subgroup: sg.name,
                                    section: s.name,
                                })),
                            ),
                        ),
                    )
                    .flat(3) || [],
                options,
            ),
        [data?.framework],
    );

    const filterRequirement = (
        req: any,
        fuseResults: FuseResult<any>[] | undefined,
    ) => {
        if (
            fuseResults &&
            !fuseResults.some((item) => item.item?.id === req.id)
        ) {
            return false;
        }
        if (state.statusFilter && !state.statusFilter.includes(req.status)) {
            return false;
        }
        if (
            state.ownerIdsFilter &&
            !state.ownerIdsFilter.includes(req.ownerId)
        ) {
            return false;
        }
        return true;
    };
    const filteredFramework = useMemo(() => {
        var filteredFramework = structuredClone(framework);
        if (!filteredFramework) {
            return undefined;
        }

        const fuseResults = state.searchFilter
            ? fuse.search(state.searchFilter)
            : undefined;
        filteredFramework.groups?.forEach((group) => {
            group.subgroups?.forEach((subgroup) => {
                subgroup.sections.forEach((section) => {
                    section.requirements = section.requirements.filter((req) =>
                        filterRequirement(req, fuseResults),
                    );
                });
                subgroup.sections = subgroup.sections.filter(
                    (section) => !!section.requirements.length,
                );
            });
            group.subgroups = group.subgroups?.filter(
                (subgroup) => !!subgroup.sections.length,
            );
        });
        filteredFramework.groups = filteredFramework.groups?.filter(
            (group) => !!group.subgroups?.length,
        );

        return filteredFramework;
    }, [
        state.searchFilter,
        framework,
        state.statusFilter,
        state.ownerIdsFilter,
    ]);

    const requirements =
        filteredFramework?.groups?.flatMap((group) =>
            group.subgroups?.flatMap((subgroup) =>
                subgroup.sections.flatMap((section) => section.requirements),
            ),
        ) || [];

    const stats = useMemo(
        () => computeStats((requirements || []) as Requirement[]),
        [requirements],
    );
    return { framework: filteredFramework, stats, ...query };
};

export const useFrameworkExport = (frameworkId?: string) => {
    const exportRequirementsMutation = useGraphQLMutation(
        ExportFrameworkRequirementsDocument,
    );

    const exportRequirements = () => {
        frameworkId &&
            exportRequirementsMutation
                .mutateAsync({ id: frameworkId })
                .then((res) => {
                    if (res.exportFrameworkRequirements?.signedUrl) {
                        browserDownload(
                            res?.exportFrameworkRequirements.signedUrl,
                        );
                    }
                });
    };

    return {
        exportRequirements,
        isExportingRequirements: exportRequirementsMutation.isPending,
    };
};

export const useFrameworkDocument = (frameworkId?: string) => {
    const { data, isLoading } = useQuery({
        queryKey: frameworkKeys.one(frameworkId),
        queryFn: frameworkId
            ? () => frameworkRepository.getFrameworkDocuments(frameworkId)
            : skipToken,
    });

    const exportDocumentsMutation = useGraphQLMutation(ExportDocumentsDocument);

    const exportDocuments = (ids: string[]) => {
        exportDocumentsMutation.mutateAsync({ ids }).then((res) => {
            if (res.exportDocuments?.signedUrl) {
                browserDownload(res?.exportDocuments.signedUrl);
            }
        });
    };

    return {
        documents: data?.frameworkDocuments,
        isLoading,
        exportDocuments,
        exportDocumentsLoading: exportDocumentsMutation.isPending,
    };
};

export function useFramework(id?: string) {
    const { data, ...query } = useQuery({
        queryKey: frameworkKeys.one(id),
        queryFn: id ? () => frameworkRepository.get(id) : skipToken,
    });

    return {
        ...query,
        framework: data?.framework,
    };
}

export function useFrameworkBreadcrumb(id?: string) {
    const { framework_id } = useParams();
    const setBreadcrumb = useBreadcrumb();
    const { framework } = useFramework(id ?? framework_id);
    const { frameworks } = useFrameworks();

    useEffect(() => {
        const frameworkList =
            frameworks
                // take only released ones
                ?.filter((f) => f.releaseStatus === "released")
                // format it for breadcrumb
                .map((f) => ({ name: f.name ?? "...", param: f.cmsId })) || [];

        setBreadcrumb(
            id ?? adminPaths.framework_id_param,
            framework?.name ?? "...",
            frameworkList,
        );
    }, [id, framework_id, setBreadcrumb, frameworks]);
}
