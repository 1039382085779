import { graphql } from "@generated/client";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const GetSupplierQuestionnaireTemplatesQuery = graphql(`
    query getSupplierQuestionnaireTemplates {
        supplierQuestionnaireTemplates {
            id
            name
        }
    }
`);

const GetSuppliersQuery = graphql(`
    query GetSuppliers {
        suppliers {
            id
            name
            tags {
                id
                name
            }
            users {
                id
                email
                firstName
                lastName
            }
            lastQuestionnaire {
                id
                status
                createdAt
                closedAt
                lastUpdatedAt
                lastReminderSentAt
                template {
                    id
                    name
                }
                stats {
                    total
                    answered
                    progress
                }
            }
        }
    }
`);

export class GraphQLSupplierRepository {
    async getSupplierQuestionnaireTemplates() {
        return graphqlClientKoyeb.request(
            GetSupplierQuestionnaireTemplatesQuery,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async getSuppliers() {
        return graphqlClientKoyeb.request(
            GetSuppliersQuery,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    }
}
