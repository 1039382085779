import { type GraphQLS3FileRepository as S3FileRepository } from "@app/repositories/GraphQLRepositories/S3FileRepository";
import { type GraphQLVersionRepository as VersionRepository } from "@app/repositories/GraphQLRepositories/VersionRepository";

import {
    CreateFileInput,
    DocumentType,
    DocumentVersionStatus,
    GetLastVersionByDocumentUrlQuery,
    UserRole,
    type DocumentVersion,
} from "@generated/client/graphql";

import { NewS3File, enrichS3File } from "../FileUseCases";

type GetVersionWhere = {
    documentUrl: string | null;
};

export type S3FileAssociatedDocument = {
    id: string;
    hasAlreadyEntry: boolean;
    lastVersionId?: string;
    name: string;
    status?: DocumentVersionStatus;
    theme?: { name: string; color: string };
};

export type S3FileFromBulkUpload = {
    associatedDocuments?: S3FileAssociatedDocument[];
    file: CreateFileInput;
};

export const EditValidationType = {
    SendForReview: "SendForReview",
    ApproveDirectly: "ApproveDirectly",
    CsReview: "CsReview",
    PendingCsReview: "PendingCsReview",
    Approved: "Approved",
    Unknown: "Unknown",
};

export type GetVersionQuery = Omit<
    GetLastVersionByDocumentUrlQuery,
    "lastVersion"
> & {
    version?: DocumentVersion | null;
};

export async function getVersion(
    { versionRepository }: { versionRepository: VersionRepository },
    { documentUrl }: GetVersionWhere,
): Promise<GetVersionQuery> {
    if (!documentUrl) throw new Error("Document URL is required");

    const { lastVersion, ...res } =
        await versionRepository.getLastByDocumentURL(documentUrl);

    const version = lastVersion as DocumentVersion | undefined | null;
    return {
        ...res,
        version,
    };
}

export function extractVersionChecks(version?: DocumentVersion | null) {
    return version?.checks ?? [];
}

export function extractEditValidationType(
    version: DocumentVersion | null | undefined,
    role: UserRole | null | undefined,
) {
    const status = version?.status;
    const doctype = version?.document?.type;
    const tier = version?.document?.tier;

    if (
        status === DocumentVersionStatus.Draft ||
        status === DocumentVersionStatus.Rejected
    ) {
        if (
            doctype === DocumentType.MasterPolicy ||
            doctype === DocumentType.Policy ||
            tier === 1
        ) {
            return EditValidationType.SendForReview;
        } else {
            return EditValidationType.ApproveDirectly;
        }
    } else if (status === DocumentVersionStatus.PendingBeavrReview) {
        if (role === UserRole.BeavrAdmin) {
            return EditValidationType.CsReview;
        } else {
            return EditValidationType.PendingCsReview;
        }
    } else if (status === DocumentVersionStatus.Approved) {
        return EditValidationType.Approved;
    } else {
        return EditValidationType.Unknown;
    }
}

export function versionRequiresValidation(version?: DocumentVersion | null) {
    return version?.document?.type === DocumentType.MasterPolicy;
}

export function versionChecksRatio(version?: DocumentVersion | null) {
    const checks = extractVersionChecks(version);
    const totalChecks = checks.length;
    const checkedChecks = checks.reduce(
        (acc, { checked }) => acc + (checked ? 1 : 0),
        0,
    );

    return {
        num: totalChecks === 0 ? 1 : checkedChecks / totalChecks,
        total: totalChecks,
        str: `${checkedChecks}/${totalChecks}`,
    };
}

export async function saveVersionData(
    { versionRepository }: { versionRepository: VersionRepository },
    versionId: string,
) {
    return versionRepository.updateDocumentData({ id: versionId });
}

export async function sendForReview(
    { versionRepository }: { versionRepository: VersionRepository },
    version: DocumentVersion,
) {
    if (version.withEditor)
        await versionRepository.updateDocumentData({ id: version.id });
    return versionRepository.update({
        id: version.id,
        patch: {
            status: DocumentVersionStatus.PendingBeavrReview,
            generatePdf: !!version.withEditor,
        },
    });
}

export async function reject(
    { versionRepository }: { versionRepository: VersionRepository },
    versionId: string,
    { userRole }: { userRole?: UserRole },
) {
    if (userRole !== UserRole.BeavrAdmin) {
        throw new Error("Only admins can reject versions");
    }
    return versionRepository.update({
        id: versionId,
        patch: {
            status: DocumentVersionStatus.Rejected,
        },
    });
}

export async function setToDraft(
    { versionRepository }: { versionRepository: VersionRepository },
    versionId: string,
) {
    return versionRepository.update({
        id: versionId,
        patch: {
            status: DocumentVersionStatus.Draft,
        },
    });
}

export async function approve(
    { versionRepository }: { versionRepository: VersionRepository },
    version: DocumentVersion,
) {
    const shouldGeneratePdf =
        version.withEditor &&
        (version.status === DocumentVersionStatus.Draft ||
            version.status === DocumentVersionStatus.Rejected ||
            version.status === DocumentVersionStatus.PendingBeavrReview);

    return versionRepository.update({
        id: version.id,
        patch: {
            status: DocumentVersionStatus.Approved,
            generatePdf: shouldGeneratePdf,
        },
    });
}

export async function initializeVersionTipTapDoc(
    { versionRepository }: { versionRepository: VersionRepository },
    filter: GetVersionWhere,
) {
    const { version } = await getVersion({ versionRepository }, filter);
    if (!version) throw new Error("Version not found");
    if (version.tipTapDocId) return;

    return versionRepository.addTipTapDoc({ versionId: version?.id });
}

export async function updateFinalFile(
    {
        s3FileRepository,
        versionRepository,
    }: {
        s3FileRepository: S3FileRepository;
        versionRepository: VersionRepository;
    },
    { versionId }: { versionId: string },
    { file }: { file: File },
) {
    const { createEmptyS3File: newS3File } =
        await s3FileRepository.createOneS3File(file.name);

    const createFileInput = await enrichS3File(file, newS3File);
    const [updatedVersion] = await Promise.all([
        versionRepository.update({
            id: versionId,
            patch: {
                file: createFileInput,
                lastEditedAt: new Date(),
            },
        }),
        newS3File.putUrl &&
            s3FileRepository.uploadToBucket(file, {
                url: newS3File.putUrl,
            }),
    ]);

    return updatedVersion;
}

export function updateLastEditedTime(
    { versionRepository }: { versionRepository: VersionRepository },
    { versionId }: { versionId: string },
) {
    return versionRepository.update({
        id: versionId,
        patch: {
            lastEditedAt: new Date(),
        },
    });
}

export function updateExpiresAtDate(
    { versionRepository }: { versionRepository: VersionRepository },
    { versionId }: { versionId: string },
    { expirationDate }: { expirationDate: string },
) {
    return versionRepository.update({
        id: versionId,
        patch: {
            expirationDate: new Date(expirationDate),
        },
    });
}

export async function bulkFileUploadToS3(
    {
        s3FileRepository,
    }: {
        s3FileRepository: S3FileRepository;
    },
    { files }: { files: File[] },
): Promise<NewS3File[]> {
    // Create a map filename 2 file, it'll be helpful later to track the files
    const fileNames = files.map((file) => file.name);
    // create empty files on our DB
    const { createManyEmptyS3Files } = await s3FileRepository
        .createManyS3file(fileNames)
        .catch(() => {
            throw new Error("Failed to create empty files");
        });
    // upload files to S3
    await Promise.all(
        createManyEmptyS3Files.map(({ putUrl }, idx) => {
            const file = files[idx];
            return (
                putUrl && s3FileRepository.uploadToBucket(file, { url: putUrl })
            );
        }),
    ).catch((e: Error) => {
        console.log(e);
        throw new Error(
            `Failed to upload files: ${createManyEmptyS3Files} to S3 with error : ${e?.name}, ${e?.message}`,
        );
    });

    return Promise.all(
        files.map((file, idx) =>
            enrichS3File(file, createManyEmptyS3Files[idx]),
        ),
    );
}

export async function createOrUpdateManyVersionsFromBulkS3FilesUpload(
    {
        versionRepository,
    }: {
        versionRepository: VersionRepository;
    },
    files: S3FileFromBulkUpload[],
) {
    // Get all files that have associated documents
    const filesWithDocuments = files.filter(
        (file) => file.associatedDocuments?.length,
    );
    if (!filesWithDocuments.length) return;

    // Get versions to create and make input for mutation
    const versionsToCreate = filesWithDocuments.flatMap((newFile) => {
        // Line 306 garantee that file.associatedDocuments is not null
        const inputs = newFile.associatedDocuments!.map((doc) => ({
            documentId: doc.id,
            versionDetails: { file: newFile.file, status: doc.status },
        }));
        return inputs;
    });

    // Create versions
    await versionRepository.createMany({
        documentVersions: versionsToCreate,
    });
}
