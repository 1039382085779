// Follows recommendations of https://tkdodo.eu/blog/effective-react-query-keys

import { useSetToast } from "@app/components/Toast";
import { orderSort } from "@app/lib/utils";
import { GraphQLRequirementRepository } from "@app/repositories/GraphQLRepositories/RequirementRepository";
import {
    GetRequirementQuery,
    Relevance,
    Requirement,
    RequirementFilter,
    RequirementPatch,
} from "@generated/client/graphql";
import {
    keepPreviousData,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { filterAtom, paginationAtom } from "../context";

const requirementKeys = {
    all: ["requirements"] as const,
    filtered: (filter: RequirementFilter, take?: number, skip?: number) =>
        [...requirementKeys.all, { filter, take, skip }] as const,
    filteredStats: (filter: RequirementFilter) =>
        [...requirementKeys.all, "stats", filter] as const,
    detail: (id: string) => [...requirementKeys.all, "detail", id] as const,
};

// init
const requirementRepository = new GraphQLRequirementRepository();

// will be used in the table component
export const useFilteredRelevantdRequirements = () => {
    const filter = useAtomValue(filterAtom);
    const pagination = useAtomValue(paginationAtom);

    const query = useQuery({
        queryKey: requirementKeys.filtered(
            filter,
            pagination.pageSize,
            pagination.pageIndex * pagination.pageSize,
        ),
        queryFn: async () => {
            // theme id should be passed here for backend filtering
            return requirementRepository.getMany({
                filter: { ...filter, relevance: Relevance.Relevant },
                take: pagination.pageSize,
                skip: pagination.pageIndex * pagination.pageSize,
            });
        },
        placeholderData: keepPreviousData,
    });

    return {
        ...query,
        data: (query?.data?.requirements.data || []) as Requirement[],
        count: query?.data?.requirements.count,
    };
};

export const useRequirementStats = () => {
    const filter = useAtomValue(filterAtom);

    const query = useQuery({
        queryKey: requirementKeys.filteredStats({
            ...filter,
            relevance: Relevance.Relevant,
        }),
        queryFn: async () => {
            return requirementRepository.getStats({
                ...filter,
                relevance: Relevance.Relevant,
            });
        },
        placeholderData: keepPreviousData,
    });

    return query;
};

export const useAllNotRelevantRequirements = () => {
    const filter = { relevance: Relevance.NotRelevant };
    const query = useQuery({
        queryKey: requirementKeys.filtered(filter),
        queryFn: async () => {
            return requirementRepository.getAllNotRelevant();
        },
    });

    return {
        ...query,
        data: orderSort(query.data?.requirements.data as Requirement[]),
    };
};

export const useUpdateRequirements = () => {
    const client = useQueryClient();
    const { setToastSuccess } = useSetToast();
    const { t } = useTranslation("RequirementTable");

    return useMutation({
        mutationFn: requirementRepository.updateMany,
        onSuccess: () => {
            setToastSuccess(t("success_message"));

            client.invalidateQueries({
                queryKey: requirementKeys.all,
            });
        },
    });
};

export const useUpdateRequirement = () => {
    const client = useQueryClient();
    const { mutate: mutateMany } = useUpdateRequirements();

    const mutate = (id: string, patch: RequirementPatch) => {
        client.setQueryData<GetRequirementQuery>(
            requirementKeys.detail(id),
            (data) => {
                if (data) {
                    return {
                        requirement: {
                            ...data.requirement,
                            ...patch,
                        },
                    };
                }
                return data;
            },
        );
        mutateMany({ ids: [id], patch });
    };

    return { mutate };
};
