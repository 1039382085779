import { ReactComponent as BeavrFull } from "@app/assets/beavr-full-logo.svg";
import { ButtonType } from "@app/components/Button/ButtonShared";
import ButtonXLarge from "@app/components/Button/ButtonXLarge";
import { cn } from "@app/lib/utils";
import { type FC, type PropsWithChildren, type SVGProps } from "react";
import { gridTemplateAreas, textType } from "./utils";

/**
 * Basic Heasder component with the Beavr logo.
 * Regardless of the order it is in, it will be placed at the top of the
 * screen.
 *
 * @returns {JSX.Element} A page header with the Beavr logo.
 */
const Header: FC = () => (
    <header className="w-screen p-4 h-auto [grid-area:navbar]">
        <BeavrFull className="h-7" />
    </header>
);

/**
 * Error page body component that acts as a container for child components.
 * Horizontally centered in the middle of the screen. It has a fixed with
 * of 400px; flex column container, centers its children horizontally, and
 * has a top padding of 6.
 *
 * @example
 * <ErrorPage>
 *  <ErrorPage.Body>
 *   <p>Some content here</p>
 *   <ErrorPage.CTA onCLick={doSomething} />
 *  </ErrorPage.Body>
 * <ErrorPage>
 *
 * @param {React.PropsWithChildren<{}>} props - The props containing children elements.
 * @returns {JSX.Element} A container div with custom styling.
 */
const Body: FC<PropsWithChildren> = ({ children }) => (
    <div className="w-[400px] pt-6 [grid-area:body] justify-self-center flex flex-col items-center">
        {children}
    </div>
);

/**
 * Text component that displays text with a specified style.
 * It displays the text according to the specified type:
 * - errorCode: title-like text (hero-3), and mb 2
 * - errorMessage: subtitle-like text (hero-5) and mb 4
 * - errorDescription: body-like text (text-base) and mb 7
 *
 * @example
 * <ErrorPage.Text text="An error occurred" type="errorCode" />
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.text - The text to display.
 * @param {keyof typeof textType} [props.type="errorCode"] - The type of text style to apply.
 * @returns {JSX.Element} A styled text component.
 */
const Text: FC<{ text: string; type?: keyof typeof textType }> = ({
    text,
    type = "errorCode",
}) => {
    const { Comp, style } = textType[type];
    return <Comp className={style}>{text}</Comp>;
};

type ImageProps = {
    Image: FC<
        SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }
    >;
    className?: string;
};

/**
 * Renders an image component with the provided image and optional
 * className, in case additional styling is needed. The image component is styled with mb 7.
 *
 * @example
 * import { ReactComponent as SomeSvgComponent } from "@app/assets/illustrations/lighthouse.svg";
 *
 * <ErrorPage.Image Image={SomeSvgComponent} className="custom-class" />
 *
 * @param {ImageProps} props - The props for the Image component.
 * @param {FC<SVGProps<SVGSVGElement> & { title?: string | undefined }>} props.Image - The SVG component to render.
 * @param {string} [props.className] - Optional className to apply to the image component.
 * @return {JSX.Element} The rendered image component.
 */
const Image: FC<ImageProps> = ({ Image, className }) => (
    <Image className={cn("mb-7", className)} />
);

/**
 * Renders a call-to-action button component with the provided onClick function and children.
 * Typically used to prompt the user to visit another page.
 *
 * @example
 * <ErrorPage.CTA onClick={goToHomepage}>
 *   Go to homepage
 * </ErrorPage.CTA>
 *
 * @param {PropsWithChildren<{ onClick: () => void }>} props - The props for the CTA component.
 * @param {ReactNode} props.children - The content to be rendered inside the button.
 * @param {() => void} props.onClick - The function to be called when the button is clicked.
 * @return {JSX.Element} The rendered call-to-action button component.
 */
const CTA: FC<PropsWithChildren<{ onClick: () => void }>> = ({
    children,
    onClick,
}) => (
    <ButtonXLarge onClick={onClick} variant={ButtonType.TONAL}>
        {children}
    </ButtonXLarge>
);

type ErrorPageComponent = FC<PropsWithChildren<{ className?: string }>> & {
    Body: typeof Body;
    Image: typeof Image;
    CTA: typeof CTA;
    Text: typeof Text;
    Header: typeof Header;
};

/**
 * The ErrorPage component that serves as the main container for the
 * error screen, and provides the parts of the layout.
 *
 * @example
 * <ErrorPage>
 *   <ErrorPage.Header />
 *   <ErrorPage.Body>
 *     <ErrorPage.Text text="An error occurred" />
 *     <ErrorPage.Image Image={SomeSvgComponent} />
 *     <ErrorPage.CTA onClick={() => console.log('Retry')}>
 *       Retry
 *     </ErrorPage.CTA>
 *   </ErrorPage.Body>
 * </ErrorPage>
 *
 * @param {React.PropsWithChildren<{}>} props - The props containing children elements.
 * @returns {JSX.Element} The main container for the error screen.
 *
 * @property {typeof Body} Body - The Body component.
 * @property {typeof Image} Image - The Image component.
 * @property {typeof CTA} CTA - The CTA component.
 * @property {typeof Text} Text - The Text component.
 * @property {typeof Header} Header - The Header component.
 */
const ErrorPage: ErrorPageComponent = ({ children, className }) => {
    return (
        <div
            className={cn(
                "h-screen grid [grid-template-rows:auto_1fr]",
                className,
            )}
            style={{
                gridTemplateAreas: gridTemplateAreas,
            }}
        >
            {children}
        </div>
    );
};

ErrorPage.Body = Body;
ErrorPage.CTA = CTA;
ErrorPage.Image = Image;
ErrorPage.Header = Header;
ErrorPage.Text = Text;

export default ErrorPage;
