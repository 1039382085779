import i18next from "@app/i18n";

const enResources = {
    ask_ai: "Ask Cleo",
    ask_me_anything: "Ask me anything",
    conversation: "Conversation {{ index }}",
    error_creating_thread: "Error creating thread. Please try again later",
    hystory: "History",
    loader: "Loading",
    new_thread_btn: "New chat",
    error_title: "What's going on with Cleo ?",
    error_description: "Please reload the page or try again later",
    error_cta: "Reload page",
    no_threads_msg:
        "Welcome! There are no chats with Cleo yet, start a new chat!",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    ask_ai: "Demander à Cleo",
    ask_me_anything: "Posez-moi une question",
    conversation: "Conversation {{ index }}",
    error_creating_thread:
        "Erreur lors de la création du fil. Veuillez réessayer plus tard",
    hystory: "Historique",
    loader: "En attente",
    new_thread_btn: "Nouvelle conversation",
    error_title: "Que se passe-t-il avec Cleo ?",
    error_description: "Veuillez recharger la page ou réessayez plus tard",
    error_cta: "Recharger la page",
    no_threads_msg:
        "Bienvenue! Il n'y a pas encore de tchats avec Cleo, commencez un nouveau tchat!",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "AskAiModal";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
