import i18next from "@app/i18n";

const enResources = {
    search_placeholder: "Search objectives...",
    create_objective: "New objective",
    toast_success: "Objective created successfully",
    toast_error_entity: "Please select an entity",
    toast_error_theme: "Please select a theme",
    toast_error_unknown: "Failed to create objective",
    themes: "Themes",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    search_placeholder: "Rechercher des objectifs...",
    create_objective: "Nouvel objectif",
    toast_success: "Objectif créé avec succès",
    toast_error_entity: "Veuillez sélectionner une entité",
    toast_error_theme: "Veuillez sélectionner un thème",
    toast_error_unknown: "Impossible de créer l'objectif",
    themes: "Thèmes",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "ObjectivesPageSideBar";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
