import Container from "@app/components/Container";
import BodyHeader from "@app/shared/components/BodyHeader";
import EntitySelect from "@app/shared/components/EntitySelect";
import { FlexCol } from "@design-system/Layout/Flex";

import { usePageviewTrack } from "@app/shared/planhat";
import { useEntities } from "@app/store/entityStore";
import { Switch } from "@design-system/Inputs/Switch";
import { useAtom, useAtomValue } from "jotai";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import AddCustomIndicatorModal from "./components/CreateCustomIndicatorsModal";
import { ExportModal } from "./components/ExportModal";
import InactiveIndicatorsModal from "./components/InactiveIndicatorsModal";
import IndicatorsTable from "./components/IndicatorsTable";
import { PeriodManagementModal } from "./components/PeriodManagementModal";
import PeriodSelect from "./components/PeriodSelect";
import ReportingFilters from "./components/ReportingFilters";
import { activeReportingEntityIdAtom, toFillSwitchAtom } from "./context";
import ReportingHeaderButton from "./header";
import { useSetReportingFilters } from "./hooks";
import "./i18n";

const ReportingV2: FC = () => {
    const { t } = useTranslation("ReportingPage");
    const { entities } = useEntities();
    const activeReportingEntityId = useAtomValue(activeReportingEntityIdAtom);

    const { setActiveReportingEntityId } = useSetReportingFilters();

    usePageviewTrack("pageview_reporting");
    const [toFill, setToFill] = useAtom(toFillSwitchAtom);
    return (
        <Container>
            <BodyHeader
                dataChameleon={{
                    key: "reporting",
                    type: "tutorial",
                }}
                title={t("page_title")}
                subTitle={t("page_subtitle")}
                action={<ReportingHeaderButton />}
            />

            <FlexCol
                p="4"
                gap="4"
                alignItems="start"
                alignSelf="stretch"
                className="overflow-auto"
            >
                <ReportingFilters>
                    {!!entities?.length && (
                        <EntitySelect
                            entityId={activeReportingEntityId}
                            setEntityId={setActiveReportingEntityId}
                        ></EntitySelect>
                    )}
                    <PeriodSelect />
                    <Switch
                        checked={toFill}
                        onChange={setToFill}
                        label={t("to_fill_only")}
                        type="helper"
                        labelPosition="right"
                    />
                </ReportingFilters>
                <IndicatorsTable />
            </FlexCol>
            <PeriodManagementModal />
            <ExportModal />
            <InactiveIndicatorsModal />
            <AddCustomIndicatorModal />
        </Container>
    );
};

export default ReportingV2;
