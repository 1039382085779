import LoadingTable from "@app/components/Table/LoadingTable";
import { useTheme } from "@app/store/themeStore";
import SearchBar from "@design-system/Inputs/SearchBar";
import { FlexCol } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import {
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    Table,
} from "@design-system/Table";
import { GetObjectiveQuery, Indicator } from "@generated/client/graphql";
import { useAtom } from "jotai";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { isIndicatorSelectModalOpenAtom } from "../../ctx";
import { useIndicatorsFiltered } from "../../data";
import { useColumns } from "./columns";
import { keys, RESOURCE_NAME } from "./i18n";

type IndicatorSelectModalProps = {
    objective: GetObjectiveQuery["objective"];
};

export const IndicatorSelectModal: FC<IndicatorSelectModalProps> = ({
    objective,
}) => {
    const theme = useTheme(objective.themeId);
    const subthemeIds = theme?.subthemes?.map((subtheme) => subtheme.id) || [];
    const acceptedUnitTypes = ["INTEGER", "FLOAT"];
    const { indicators, isPending } = useIndicatorsFiltered(
        {
            subthemeIds,
        },
        objective.entityId,
    );
    const { t } = useTranslation(RESOURCE_NAME);
    const [searchString, setSearchString] = useState<string | undefined>(
        undefined,
    );

    const filteredIndicators = useMemo(() => {
        const indicatorsActiveOnEntity = indicators?.indicators2?.filter(
            (indicator) => !!indicator.entityConfig?.active,
        );
        const numericIndicators = indicatorsActiveOnEntity?.filter((ind) =>
            acceptedUnitTypes.includes(ind.unit?.type || ""),
        );
        return !searchString
            ? numericIndicators
            : numericIndicators?.filter(
                  (ind) =>
                      ind.name
                          .toLowerCase()
                          .includes(searchString.toLowerCase()) ||
                      ind?.subtheme?.name
                          .toLowerCase()
                          .includes(searchString.toLowerCase()),
              );
    }, [indicators, searchString]);

    const [isIndicatorSelectModalOpen, setIsIndicatorSelectModalOpen] = useAtom(
        isIndicatorSelectModalOpenAtom,
    );

    const columns = useColumns(objective.id);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const tableOptions = {
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        state: {
            pagination,
        },
        autoResetPageIndex: false,
    };

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: 10,
        });
    }, [indicators, searchString]);

    const handleCancelClick = () => {
        setIsIndicatorSelectModalOpen(false);
    };

    return (
        <Modal open={isIndicatorSelectModalOpen}>
            <Modal.Overlay scrollable>
                <Modal.Content className="w-fit">
                    <Modal.Header>
                        <Modal.Title>{t(keys.title)}</Modal.Title>
                        <Modal.Subtitle>{t(keys.subtitle)}</Modal.Subtitle>
                    </Modal.Header>
                    <Modal.Body>
                        <FlexCol w="full" gap="4">
                            <SearchBar
                                searchString={searchString}
                                setSearchString={setSearchString}
                                className="w-full"
                            />
                            {filteredIndicators && !isPending && (
                                <Table
                                    columns={columns}
                                    data={filteredIndicators as Indicator[]}
                                    options={tableOptions}
                                    className="w-full"
                                />
                            )}
                            {isPending && <LoadingTable />}
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA onClick={handleCancelClick}>
                            {t(keys.cancel)}
                        </Modal.CancelCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
