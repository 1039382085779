import Container from "@app/components/Container";
import BodyHeader from "@app/shared/components/BodyHeader";
import { Icon } from "@design-system/Icon";
import SearchBar from "@design-system/Inputs/SearchBar";
import { FlexCol } from "@design-system/Layout/Flex";
import {
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    Table,
} from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useColumns } from "./columns";
import AllMyFilesTableActionBar from "./components/AllMyFilesTableActionBar";
import { searchAtom, selectedFileSourceAtom, selectedUsedInAtom } from "./ctx";
import { useGetEvidenceFiles } from "./data";
import { keys, RESOURCE_NAME } from "./i18n";

export default function AllFiles() {
    const { t } = useTranslation(RESOURCE_NAME);
    const { evidenceFiles, isPending } = useGetEvidenceFiles();
    const [searchString, setSearchString] = useAtom(searchAtom);
    const [rowSelection, setRowSelection] = useState({});
    const columns = useColumns();
    const filteredEvidenceFiles = useMemo(() => {
        return evidenceFiles.filter(
            (file) =>
                !searchString ||
                file?.name
                    ?.toLowerCase()
                    .includes(searchString.toLowerCase()) ||
                file.filename
                    .toLowerCase()
                    .includes(searchString.toLowerCase()) ||
                (!!file.documentVersions?.length &&
                    file.documentVersions[0]?.document?.name
                        ?.toLowerCase()
                        .includes(searchString.toLowerCase())),
        );
    }, [evidenceFiles, searchString]);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const selectedUsedIn = useAtomValue(selectedUsedInAtom);
    const selectedFileSource = useAtomValue(selectedFileSourceAtom);

    const columnFilters = useMemo(() => {
        const filters = [];
        if (!!selectedUsedIn?.length) {
            filters.push({ id: "linksType", value: selectedUsedIn });
        }
        if (!!selectedFileSource?.length) {
            filters.push({ id: "source", value: selectedFileSource });
        }
        return filters;
    }, [selectedUsedIn, selectedFileSource]);

    const tableOptions = {
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        onRowSelectionChange: setRowSelection,
        state: {
            rowSelection,
            pagination,
            columnFilters,
        },
        autoResetPageIndex: false,
    };

    useEffect(() => {
        setPagination({ ...pagination, pageIndex: 0 });
    }, [selectedUsedIn, selectedFileSource]);

    const selectedFiles = Object.keys(rowSelection)
        .filter((k) => rowSelection[k as keyof typeof rowSelection])
        .map((idx) => {
            return filteredEvidenceFiles[Number(idx)];
        });
    const unset = () => setRowSelection({});
    return (
        <Container>
            <BodyHeader title={t(keys.title)} subTitle={t(keys.subtitle)} />
            <FlexCol className="p-4 space-y-4">
                <SearchBar
                    placeholder={t(keys.searchPlaceholder)}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    className="w-[250px]"
                />
                <FlexCol>
                    <Table
                        data={filteredEvidenceFiles}
                        columns={columns}
                        loading={isPending}
                        options={tableOptions}
                        className={cn(
                            filteredEvidenceFiles.length === 0 &&
                                "rounded-b-none border-b-0",
                        )}
                    />
                    {filteredEvidenceFiles.length === 0 && !isPending && (
                        <FlexCol
                            py="10"
                            justifyContent="center"
                            alignItems="center"
                            className="bg-neutral-25 rounded-sm border-b border-r border-l border-neutral-150 rounded-t-none"
                        >
                            <FlexCol
                                gap="2"
                                alignItems="center"
                                className="my-8"
                            >
                                <Icon name="search" />
                                <FlexCol gap="1" alignItems="center">
                                    <Text variant="body-sm-bold">
                                        {t(keys.no_file_found_title)}
                                    </Text>
                                    <Text
                                        variant="body-sm"
                                        className="font-light"
                                    >
                                        {t(keys.no_file_found_subtitle)}
                                    </Text>
                                </FlexCol>
                            </FlexCol>
                        </FlexCol>
                    )}
                </FlexCol>
                <AllMyFilesTableActionBar
                    files={selectedFiles || []}
                    unset={unset}
                />
            </FlexCol>
        </Container>
    );
}
