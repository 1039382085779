import i18next from "@app/i18n";

const enResources = {
    update_successs: "Objective target updated successfully",
    update_error: "Failed to update objective target",
    reached: "Reached",
    ongoing: "Ongoing",
    missed: "Missed",
    delete_success: "Objective target deleted successfully",
    delete_error: "Failed to delete objective target",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    update_successs: "Objectif mis à jour avec succès",
    update_error: "Impossible de mettre à jour l'objectif",
    reached: "Atteint",
    ongoing: "En cours",
    missed: "Manqué",
    delete_success: "Cible supprimée avec succès",
    delete_error: "Impossible de supprimer la cible",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "ObjectivePage";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
