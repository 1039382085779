import { AccessHeadersHolder } from "@app/repositories/AccessHeadersHolder";
import { graphqlClientKoyeb } from "@app/repositories/clients";
import { graphql } from "@generated/client";
import {
    type GetUserOrganisationsQueryVariables,
    type UpdateOrgCustomizationMutationVariables,
    type UpdateOrgMutationVariables,
} from "@generated/client/graphql";

const UpdateOrgMutation = graphql(`
    mutation updateOrg(
        $tenantId: String!
        $name: String
        $addBeavrUserIds: [String!]
        $removePermissionIds: [String!]
        $type: OrgType
    ) {
        updateOrgConfig(
            input: {
                id: $tenantId
                set: {
                    name: $name
                    addBeavrUserIds: $addBeavrUserIds
                    removePermissionIds: $removePermissionIds
                    type: $type
                }
            }
        ) {
            tenantId
        }
    }
`);

const GetUserOrganisationsQuery = graphql(`
    query GetUserOrganisations(
        $filter: OrgsFilter
        $skip: Float!
        $take: Float
        $orderBy: OrgsSort
    ) {
        orgs(filter: $filter, skip: $skip, take: $take, orderBy: $orderBy) {
            count
            data {
                type
                tenantId
                tenant {
                    id
                    uname
                    name
                }
                beavrUserPermissions {
                    id
                    user {
                        id
                        firstName
                        lastName
                        initials
                        avatar {
                            signedUrl
                        }
                    }
                }
                userPermissions {
                    id
                    user {
                        id
                        firstName
                        lastName
                        initials
                        avatar {
                            signedUrl
                        }
                    }
                }
            }
        }
    }
`);

const GetBeavrAdminQuery = graphql(`
    query getBeavrAdmin {
        beavrUsers {
            id
            firstName
            lastName
        }
    }
`);

const GetOrgQuery = graphql(`
    query GetOrg {
        org {
            tenantId
            type
            tenant {
                name
            }
            customization {
                hexColor
                logo {
                    id
                    signedUrl
                    name
                    fileSize
                }
            }
        }
    }
`);

const UpdateOrgCustomizationMutation = graphql(`
    mutation UpdateOrgCustomization(
        $tenantId: String!
        $name: String
        $logoFile: CreateFileInput
        $hexColor: String
    ) {
        updateOrgCustomization(
            input: {
                id: $tenantId
                set: { name: $name, hexColor: $hexColor, logoFile: $logoFile }
            }
        ) {
            tenantId
            tenant {
                name
            }
            customization {
                hexColor
                logo {
                    id
                    signedUrl
                    name
                    fileSize
                }
            }
        }
    }
`);

export class GraphQLOrganizationRepository {
    getUserOrgs = async (input: GetUserOrganisationsQueryVariables) =>
        graphqlClientKoyeb.request(
            GetUserOrganisationsQuery,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    getOrg = async () =>
        graphqlClientKoyeb.request(
            GetOrgQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    getBeavrInternalUsers = async () =>
        graphqlClientKoyeb.request(
            GetBeavrAdminQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );

    updateOrganization = async (input: UpdateOrgMutationVariables) => {
        return graphqlClientKoyeb.request(
            UpdateOrgMutation,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };
    updateOrgCustomization = async (
        input: UpdateOrgCustomizationMutationVariables,
    ) =>
        graphqlClientKoyeb.request(
            UpdateOrgCustomizationMutation,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
}
