import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import Select from "@design-system/Inputs/Select";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { UserRole } from "@generated/client/graphql";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import "./i18n";

const RoleSelect: FC<
    PropsWithChildren<{
        role: UserRole | undefined;
        setRole: (role: UserRole) => void;
    }>
> = ({ role, setRole }) => {
    const { t } = useTranslation("RoleSelect");

    const roles = [
        { id: UserRole.BeavrAdmin, name: t(UserRole.BeavrAdmin) },
        { id: UserRole.Admin, name: t(UserRole.Admin) },
        { id: UserRole.Contributor, name: t(UserRole.Contributor) },
    ];

    return (
        <Select
            value={role}
            onValueChange={(role: UserRole) => {
                setRole(role);
            }}
        >
            <Select.Trigger asChild>
                <Button variant="outlined" className="w-[140px]" size="sm">
                    <Icon name="user" size="sm" />
                    <Ellipsis asChild hasTooltip>
                        <Text variant="body-sm-bold" truncate>
                            {roles.find((r) => r.id === role)?.name ||
                                t("placeholder")}
                        </Text>
                    </Ellipsis>
                    <Icon name="angleDown" size="sm" />
                </Button>
            </Select.Trigger>
            <Select.Content className="max-h-[260px] overflow-auto">
                {roles.map((role) => (
                    <Select.Item key={role.id} value={role.id}>
                        <div className="group-hover:text-brand_hover text-sm">
                            {role.name}
                        </div>
                    </Select.Item>
                ))}
            </Select.Content>
        </Select>
    );
};

export default RoleSelect;
