import { useAtom } from "jotai";
import { atomFamily, atomWithStorage } from "jotai/utils";
import { useParams } from "react-router-dom";

export const selectedTagsAtomFamily = atomFamily(
    (orgUname: string | undefined) =>
        atomWithStorage<(string | null)[]>(
            `reportingSelectedTags_${orgUname}`,
            [],
        ),
);

export const useSelectedTagsAtom = () => {
    const { org_uname } = useParams();
    return useAtom(selectedTagsAtomFamily(org_uname));
};

export const selectedQuestionnaireIdsAtomFamily = atomFamily(
    (orgUname: string | undefined) =>
        atomWithStorage<(string | null)[]>(
            `reportingSelectedQuestionnaire_${orgUname}`,
            [],
        ),
);

export const useSelectedQuestionnaireIdsAtom = () => {
    const { org_uname } = useParams();
    return useAtom(selectedQuestionnaireIdsAtomFamily(org_uname));
};
