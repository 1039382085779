import { GraphQLDashboardRepository as DashboardRepository } from "@app/repositories/GraphQLRepositories/DashboardRepository";
import {
    DashboardPlanStepType,
    GetDashboardPlanStepsQuery,
    UserRole,
} from "@generated/client/graphql";

export type Step = Omit<
    GetDashboardPlanStepsQuery["dashboardPlanSteps"][number],
    "id"
> & {
    id: string | null;
};

type Steps = Record<DashboardPlanStepType, Step>;

export const OVERDUE_THREASHOLD_IN_WEEKS = 2;

export async function getDashboardPlanSteps({
    dashboardRepository,
}: {
    dashboardRepository: DashboardRepository;
}) {
    // fetch the data from the server
    const { dashboardPlanSteps } = await dashboardRepository.getPlanSteps();

    // create skeleton object
    const steps = Object.values(DashboardPlanStepType).reduce(
        (acc, stepType) => {
            acc[stepType] = {
                id: null,
                stepType,
                dueDate: null,
                checked: false,
            };
            return acc;
        },
        {} as Steps,
    );

    // fill in the data rfom the server
    dashboardPlanSteps.forEach(({ id, stepType, dueDate, checked }) => {
        const formattedDate = dueDate ? new Date(dueDate) : null;

        steps[stepType] = {
            id,
            stepType,
            dueDate: formattedDate,
            checked,
        };
    });

    return steps;
}

export async function updateDashboardPlanStep(
    dashboardRepository: DashboardRepository,
    { id, stepType, dueDate, checked }: Step,
    { userRole }: { userRole?: UserRole | null },
) {
    if (!userRole) throw new Error("User role is required");
    if (userRole !== UserRole.BeavrAdmin)
        throw new Error("User is not authorized to update dashboard steps");

    const formattedDate = dueDate ? new Date(dueDate) : null;

    return dashboardRepository.upsertPlanStep({
        id,
        stepType,
        dueDate: formattedDate,
        checked,
    });
}

export function getRemainingWeeks(dueDate: Date) {
    const now = new Date();
    const diff = dueDate.getTime() - now.getTime();

    return Math.ceil(diff / (1000 * 60 * 60 * 24 * 7));
}

export function getExpiringOrOvedueStepsCount(
    steps: Steps | undefined,
    { threashold = OVERDUE_THREASHOLD_IN_WEEKS } = {},
) {
    return steps
        ? Object.values(steps).filter(
              (step) =>
                  !step.checked &&
                  step.dueDate &&
                  getRemainingWeeks(step.dueDate) <= threashold,
          ).length
        : 0;
}
