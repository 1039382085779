import { useSetToast } from "@app/components/Toast";
import { useUpdateObjective } from "@app/screens/Objectives/data";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { Indicator } from "@generated/client/graphql";
import { ColumnDef } from "@tanstack/react-table";
import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { isIndicatorSelectModalOpenAtom } from "../../ctx";
import { RESOURCE_NAME, keys } from "./i18n";

const nameCol: ColumnDef<Indicator> = {
    id: "name",
    accessorKey: "name",
    meta: {
        colWidth: "400px",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t(keys.indicators);
    },
    cell: ({ row }) => {
        return <Text variant="body-sm">{row.original.name}</Text>;
    },
};

const unitCol: ColumnDef<Indicator> = {
    id: "unit",
    meta: {
        colWidth: "120px",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t(keys.unit);
    },
    cell: ({ row }) => {
        return (
            <Text variant="body-sm">{row.original.unit?.shortName || ""}</Text>
        );
    },
};

const subthemeCol: ColumnDef<Indicator> = {
    id: "subtheme",

    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t(keys.subtheme);
    },
    cell: ({ row }) => {
        return (
            <FlexRow
                alignItems="center"
                justifyContent="center"
                className="border border-neutral-200 rounded-xl p-0.5 w-fit"
                style={{
                    color: row.original.subtheme?.theme?.color,
                }}
                elevation="b-100"
            >
                <Icon name="tagEmptyCircle" size="sm" />
                <Text
                    variant="body-sm"
                    color="primary"
                    px="1"
                    className="  text-primary text-sm"
                    style={{ textWrap: "nowrap" }}
                >
                    {row.original.subtheme?.name}
                </Text>
            </FlexRow>
        );
    },
};

const actionCol: (objectiveId: string) => ColumnDef<Indicator> = (
    objectiveId: string,
) => {
    return {
        id: "select",
        header: () => {
            return "";
        },
        cell: ({ row }) => {
            const { mutate } = useUpdateObjective(objectiveId);
            const { setToastSuccess, setToastError } = useSetToast();
            const { t } = useTranslation(RESOURCE_NAME);
            const setIsIndicatorSelectModalOpen = useSetAtom(
                isIndicatorSelectModalOpenAtom,
            );
            const handleIndicatorSetClick = () => {
                mutate(
                    {
                        id: objectiveId,
                        indicatorId: row.original.id,
                    },
                    {
                        onSuccess: () => {
                            setToastSuccess(t(keys.update_success));
                        },
                        onError: () => {
                            setToastError(t(keys.update_error));
                        },
                    },
                );
                setIsIndicatorSelectModalOpen(false);
            };
            return (
                <Button
                    onClick={handleIndicatorSetClick}
                    size="sm"
                    className="w-7 h-7"
                >
                    <Icon name="arrowRight" size="sm" className="text-white" />
                </Button>
            );
        },
    };
};

export const useColumns = (objectiveId: string): ColumnDef<Indicator>[] => {
    const columns = [nameCol, unitCol, subthemeCol, actionCol(objectiveId)];
    return columns;
};
