import i18next from "@app/i18n";

const enResources = {
    title: `How can I help you?`,
    text: `To get started, initiate a new chat or choose one from the list.`,
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    title: `Comment puis-je vous aider ?`,
    text: `Pour commencer, écrivez dans le chat ou choisissez un sujet de conversation ci-dessous.`,
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "AskAiPlaceholder";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
