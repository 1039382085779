import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { Eye } from "iconoir-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DocumentAcceptance,
    GetSupplierIndicatorsDocument,
    Indicator,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import ButtonSmall from "../../../Button/ButtonSmall";
import BaseItem from "../../../Dropdown/components/dropdowns/items/BaseItem";
import DropdownContentLayout from "../../../Dropdown/components/dropdowns/layout";
import BaseTrigger from "../../../Dropdown/components/triggers/Base";
import DocumentModeSelect from "../../../Dropdown/dropdowns/DocumentMode/select";
import TemplatesSetterFloatbar from "../../../Dropdown/dropdowns/SupplierQuestionnaireTemplate/selectFloatbar";
import Floatbar from "../../../Floatbar";
import { useUpdateIndicators } from "../../hooks";

enum Actions {
    SET_TAGS = "ASSIGN_QUESTIONNAIRE",
    SET_DOCUMENT_MODE = "SET_DOCUMENT_MODE",
}
const ActionBarSupplier = ({
    indicators,
    unset,
}: {
    indicators: Indicator[];
    unset: () => void;
}) => {
    const { t } = useTranslation("IndicatorTableSavebar");

    const [activeAction, setActiveAction] = useState<Actions>();

    const { updateIndicators, isPending } = useUpdateIndicators(
        useGraphQL.getKey(GetSupplierIndicatorsDocument),
    );

    const [questionnaireIds, setQuestionnaireIds] = useState<string[]>([]);
    const onSetTagsApply = () => {
        indicators &&
            updateIndicators(indicators?.map((i) => i.id) || [], {
                questionnaireIds: questionnaireIds,
            });
    };

    const [documentMode, setDocumentMode] = useState<DocumentAcceptance>();
    const onSetDocumentModeApply = () => {
        indicators &&
            updateIndicators(indicators?.map((i) => i.id) || [], {
                documentAcceptance: documentMode,
            });
    };
    useEffect(() => {
        setQuestionnaireIds([]);
        setDocumentMode(undefined);
    }, []);
    return (
        <Floatbar
            unset={unset}
            open={indicators.length > 0}
            primary={
                <ButtonSmall onClick={() => setActiveAction(Actions.SET_TAGS)}>
                    {t("assign_questionnaires_button")}
                </ButtonSmall>
            }
            secondary={
                <DropdownMenu>
                    <BaseTrigger>{t("more")}</BaseTrigger>
                    <DropdownContentLayout>
                        <BaseItem
                            onClick={() =>
                                setActiveAction(Actions.SET_DOCUMENT_MODE)
                            }
                            IconLeft={() => (
                                <Eye className="text-green h-4 w-4" />
                            )}
                        >
                            {t("set_document_mode_button")}
                        </BaseItem>
                    </DropdownContentLayout>
                </DropdownMenu>
            }
            actions={[
                {
                    id: Actions.SET_TAGS,
                    text: t("assign_questionnaires_text", {
                        count: indicators.length,
                    }),
                    selector: (
                        <div>
                            <TemplatesSetterFloatbar
                                templateIds={questionnaireIds}
                                onTemplateIdsChange={setQuestionnaireIds}
                            />
                        </div>
                    ),
                    apply: (
                        <ButtonSmall
                            loading={isPending}
                            onClick={() => onSetTagsApply()}
                        >
                            {t("apply")}
                        </ButtonSmall>
                    ),
                },
                {
                    id: Actions.SET_DOCUMENT_MODE,
                    text: t("set_document_mode_text", {
                        count: indicators.length,
                    }),
                    selector: (
                        <DocumentModeSelect
                            mode={documentMode}
                            onModeChange={setDocumentMode}
                        />
                    ),
                    apply: (
                        <ButtonSmall
                            loading={isPending}
                            disabled={!documentMode}
                            onClick={onSetDocumentModeApply}
                        >
                            {t("apply")}
                        </ButtonSmall>
                    ),
                },
            ]}
            activeAction={activeAction}
            setActiveAction={setActiveAction}
        ></Floatbar>
    );
};

export default ActionBarSupplier;
