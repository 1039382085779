import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "DocumentModeDropdown", {
    placeholder: "Document",
    UNKNOWN: "Unknown",
    NOT_ALLOWED: "Not allowed",
    OPTIONAL: "Optional",
    REQUIRED: "Required",
    SEVERAL: "Multiple",
    SEVERAL_REQUIRED: "Multiple, one required",
    REQUIRED_IF_TRUE: "Required if true",
    REQUIRED_IF_FALSE: "Required if false",
});

i18next.addResourceBundle("fr", "DocumentModeDropdown", {
    placeholder: "Document",
    UNKNOWN: "Inconnu",
    NOT_ALLOWED: "Non autorisé",
    OPTIONAL: "Optionnel",
    REQUIRED: "Obligatoire",
    SEVERAL: "Plusieurs",
    SEVERAL_REQUIRED: "Plusieurs, un obligatoire",
    REQUIRED_IF_TRUE: "Obligatoire si vrai",
    REQUIRED_IF_FALSE: "Obligatoire si faux",
});
