import { Button } from "@design-system/Inputs/Button";
import { FlexCol } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { DefinedQueryObserverResult } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DocumentExtType,
    DocumentVersion,
    GetDocumentByUrlQuery,
} from "../../../../../generated/client/graphql";
import FullFrameBlock from "../../../../components/Layouts/FullFrame/Block";
import InnerLayout from "../../../../components/Layouts/FullFrame/Block/InnerLayout";
import PreviewTemplateModal from "../../../../shared/components/PreviewTemplateModal/PreviewTemplateModal";
import GetStartedChip from "./GetStartedChip";
import OptionStartFromPrevious from "./Option/OptionStartFromPrevious";
import OptionStartFromScratch from "./Option/OptionStartFromScratch";
import OptionStartFromTemplate from "./Option/OptionStartFromTemplate";
import OptionUploadFile from "./Option/OptionUploadFile";
import {
    previewTemplateModalOpenAtom,
    previewTemplateModalTemplatesAtom,
} from "./ctx";
import "./i18n";

const CreateVersionBlock = ({
    getDocumentQuery,
    lastVersion,
}: {
    getDocumentQuery: DefinedQueryObserverResult<
        GetDocumentByUrlQuery,
        unknown
    >;
    lastVersion?: DocumentVersion;
}) => {
    const [expanded, setExpanded] = useState(false);
    const expandedOrFirst = expanded || !lastVersion;
    const { t } = useTranslation("CreateDocumentVersionBlock");
    const type = getDocumentQuery.data?.documentByUrl?.type;
    const setOpen = useSetAtom(previewTemplateModalOpenAtom);
    const setTemplates = useSetAtom(previewTemplateModalTemplatesAtom);

    const handleOpenPreviewTemplateModalClick = () => {
        setOpen(true);
        setTemplates(
            getDocumentQuery.data?.documentByUrl?.templates ?? undefined,
        );
    };

    const hasTemplate =
        !!getDocumentQuery.data?.documentByUrl?.templateLocales?.length;

    return (
        <div className="group flex flex-col">
            {!lastVersion && (
                <div className=" self-center">
                    <GetStartedChip />
                </div>
            )}
            <FullFrameBlock>
                <InnerLayout className="flex space-x-5">
                    <div className="flex-grow space-y-1">
                        <div className="text-lg font-bold">
                            {!lastVersion
                                ? t(`${type}_start_new_version_title_first`)
                                : t(`${type}_start_new_version_title`)}
                        </div>
                        <div className="text-sm font-regulat">
                            {!lastVersion
                                ? t(`${type}_start_new_version_desc_first`)
                                : t(`${type}_start_new_version_desc`)}
                        </div>
                    </div>
                    <FlexCol
                        gap="2"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {!expandedOrFirst && (
                            <Button
                                variant="tonal"
                                size="sm"
                                onClick={() => setExpanded(true)}
                                className="w-full"
                            >
                                <Text variant="body-sm-bold">
                                    {t(`start_button`)}
                                </Text>
                            </Button>
                        )}
                        {hasTemplate && (
                            <Button
                                variant="tonal"
                                size="sm"
                                onClick={handleOpenPreviewTemplateModalClick}
                            >
                                <Text variant="body-sm-bold">
                                    {t(`preview_template`)}
                                </Text>
                            </Button>
                        )}
                    </FlexCol>
                </InnerLayout>
                {expandedOrFirst && (
                    <InnerLayout>
                        <div className="rounded-lg border border-tertiary">
                            <OptionUploadFile
                                getDocumentQuery={getDocumentQuery}
                            />
                            {getDocumentQuery.data?.documentByUrl?.extType ===
                                DocumentExtType.Text && (
                                <>
                                    {hasTemplate && (
                                        <OptionStartFromTemplate
                                            document={
                                                getDocumentQuery?.data
                                                    ?.documentByUrl
                                            }
                                        />
                                    )}
                                    <OptionStartFromScratch
                                        getDocumentQuery={getDocumentQuery}
                                    />
                                    {lastVersion && (
                                        <OptionStartFromPrevious
                                            getDocumentQuery={getDocumentQuery}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </InnerLayout>
                )}
            </FullFrameBlock>
            <div className="relative group-last:hidden">
                <div className="absolute w-[34px]  h-5 border-r border-seconday" />
            </div>
            <PreviewTemplateModal
                showCTA={true}
                docId={getDocumentQuery.data.documentByUrl?.id}
            />
        </div>
    );
};

export default CreateVersionBlock;
