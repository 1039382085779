export type OnboardingProgress = {
    discoverBeavr: boolean;
    addUsers: boolean;
    configureSustainabilityProfile: boolean;
    assignResponsibles: boolean;
    /**
     * This is temporary and will be removed once the feature
     * is available for all users. So far, only Beavr Admins
     * can upload documents in batches.
     */
    uploadDocuments: boolean | null;
};

export const defaultProgress: OnboardingProgress = {
    discoverBeavr: false,
    addUsers: false,
    configureSustainabilityProfile: false,
    assignResponsibles: false,
    uploadDocuments: null,
};

export class LocalStorageOnboardingProgressRepository {
    private readonly onboardingProgressKey = "onboardingProgress";
    private readonly sectionCollapsedKey = "sectionCollapsed";

    getOnboardingProgress = (): OnboardingProgress => {
        const data = localStorage.getItem(this.onboardingProgressKey);
        try {
            if (data) {
                return JSON.parse(data) as OnboardingProgress;
            }
            return defaultProgress;
        } catch (e) {
            console.error(e);
            return defaultProgress;
        }
    };

    setOnboardingProgress = (
        progress: OnboardingProgress = defaultProgress,
    ) => {
        localStorage.setItem(
            this.onboardingProgressKey,
            JSON.stringify(progress),
        );
    };

    getIsOnboardingSectionCollapsed = () => {
        const data = localStorage.getItem(this.sectionCollapsedKey);
        try {
            if (data) {
                return JSON.parse(data) as boolean;
            }
            return false;
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    setIsOnboardingSectionCollapsed = (isCollapsed: boolean) => {
        localStorage.setItem(
            this.sectionCollapsedKey,
            JSON.stringify(isCollapsed),
        );
    };
}
