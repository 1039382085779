import { AccessHeadersHolder } from "@app/repositories/AccessHeadersHolder";
import { graphqlClientKoyeb } from "@app/repositories/clients";
import { graphql } from "@generated/client";
import {
    type GetRequirementsQueryVariables,
    Relevance,
    type RequirementFilter,
    type RequirementPatch,
} from "@generated/client/graphql";

const GetRequirementsQuery = graphql(`
    query GetRequirements($filter: RequirementFilter, $take: Int, $skip: Int) {
        requirements(filter: $filter, take: $take, skip: $skip) {
            count
            data {
                id
                order
                name
                description
                status
                subtheme {
                    id
                    name
                    themeId
                    theme {
                        color
                    }
                }
                proofs {
                    document {
                        name
                        status
                    }
                }
                proofStat {
                    total
                    ok
                }
                frameworkSections {
                    name
                    tagName
                    framework {
                        name
                    }
                }
                ownerId
                owner {
                    firstName
                    lastName
                }
                relevance
            }
        }
    }
`);

const GetRequirementStatsQuery = graphql(`
    query GetRequirementStats($filter: RequirementFilter) {
        requirementStats(filter: $filter) {
            total
            ok
            notOk
            unassigned
            assigned
        }
    }
`);

const UpdateRequirementsMutation = graphql(`
    mutation updateRequirements($ids: [String!]!, $set: RequirementPatch!) {
        updateRequirements(ids: $ids, set: $set) {
            id
        }
    }
`);

export class GraphQLRequirementRepository {
    getMany = async (input: GetRequirementsQueryVariables) => {
        return graphqlClientKoyeb.request(
            GetRequirementsQuery,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getStats = async (filter: RequirementFilter) => {
        return graphqlClientKoyeb.request(
            GetRequirementStatsQuery,
            { filter },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getAllNotRelevant = async () => {
        return graphqlClientKoyeb.request(
            GetRequirementsQuery,
            { filter: { relevance: Relevance.NotRelevant }, take: 1000 },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    updateMany = async ({
        ids,
        patch,
    }: {
        ids: string[];
        patch: RequirementPatch;
    }) => {
        return graphqlClientKoyeb.request(
            UpdateRequirementsMutation,
            { ids, set: patch },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
