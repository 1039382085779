import {
    AiThreadMessageRole,
    GetAiThreadQuery,
} from "@generated/client/graphql";
import { type AnnotationDelta } from "openai/resources/beta/threads/messages.mjs";
import { Thread } from "./types";

/**
 * This funciton works directly with the result of the gql query.
 * Maybe it should be split and scattered between services and utils
 * but to avoid confusion I'm keeping it here.
 *
 * It takes the previous query and returns a new query with the updated first message.
 *
 * @param text - The text to append to the first message
 * @param query - The query to update
 * @returns query - The updated query
 */
export function updateFirstMessageOfThreadQuery(
    {
        text,
        annotations,
    }: { text: string; annotations: { text: string; openAiFileId: string }[] },
    query: GetAiThreadQuery,
) {
    if (!query.aiThread.messages?.length) throw new Error("No messages found");
    const firstMessage = query.aiThread.messages[0];

    const textWithCleanedAnnotations = replaceAnnotationInText(
        text,
        annotations,
        1,
    );
    const updatedFirstMessage = {
        ...firstMessage,
        content: {
            ...firstMessage.content,
            text: {
                ...firstMessage.content.text,
                annotations:
                    firstMessage.content.text.annotations.concat(annotations),
                value:
                    firstMessage.content.text.value +
                    textWithCleanedAnnotations,
            },
        },
    };

    return {
        ...query,
        aiThread: {
            ...query.aiThread,
            messages: [
                updatedFirstMessage,
                ...query.aiThread.messages.slice(1),
            ],
        },
    };
}

/**
 * This function works directly with the result of the gql query.
 * Maybe it should be split and scattered between services and utils
 * but to avoid confusion I'm keeping it here.
 *
 * It prepends a message to the messages of a thread query.
 *
 * @param message - The message including a role and a text
 * @param query - The query to update
 * @returns query - The updated query
 */
export function prependMessageToThreadQuery(
    { role, text }: { role: AiThreadMessageRole; text: string },
    query: GetAiThreadQuery,
): GetAiThreadQuery {
    const prevMessages = query.aiThread.messages || [];
    const newMessage = {
        openAiId: Math.random().toString(36).substring(2, 15),
        role,
        content: {
            text: {
                value: text,
                annotations: [],
            },
        },
    };

    return {
        ...query,
        aiThread: {
            ...query.aiThread,
            messages: [newMessage, ...prevMessages],
        },
    };
}

export const makeThread = <T extends GetAiThreadQuery["aiThread"]>(
    openAiThread: T,
): Thread => ({
    id: openAiThread.id,
    openAiThreadId: openAiThread.openAiThreadId,
    updatedAt: openAiThread.updatedAt,
    messages:
        openAiThread.messages?.map(
            (
                {
                    openAiId,
                    role,
                    content: {
                        text: { value, annotations },
                    },
                },
                idx,
            ) => ({
                openAiId,
                role,
                content: replaceAnnotationInText(value, annotations, idx),
                annotations,
            }),
        ) || [],
});

export function makeAnnotation<Ann extends AnnotationDelta>(ann: Ann) {
    if (ann.type !== "file_citation" || !ann.file_citation) return null;

    return {
        text: ann.text,
        openAiFileId: ann.file_citation.file_id,
    };
}

export function filterAnnotation(
    annotation: any,
): annotation is { text: string; openAiFileId: string } {
    return (
        typeof annotation?.text === "string" &&
        typeof annotation?.openAiFileId === "string"
    );
}

export function replaceAnnotationInText<
    Ann extends {
        text: string;
        openAiFileId: string;
    },
>(text: string, annotations: Ann[], messageIdx: number) {
    annotations?.forEach((ann, annotationIdx) => {
        text = text.replace(
            ann.text,
            `[${annotationIdx + 1}](${messageIdx}-${ann.openAiFileId})`,
        );
    });

    return text;
}
