import { Button } from "@design-system/Inputs/Button";
import { Textfield } from "@design-system/Inputs/Textfield";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { useSetAtom } from "jotai";
import { useRef, type FC, type FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { currentAiThreadIdAtom } from "../ctx";
import { useAsyncCreateEmptyThread, useSendPrompt } from "../data";
import { keys, RESOURCE_NAME } from "../i18n";
import { AskAiPlaceholder } from "./AskAiPlaceholder";

export const FirstPrompt: FC<{ onSubmit: (prompt: string) => void }> = ({
    onSubmit,
}) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!inputRef.current) return;

        onSubmit(inputRef.current.value);
        inputRef.current.value = "";
    };
    return (
        <FlexCol
            alignItems="center"
            className="bg-secondary p-5"
            h="full"
            gap="3"
            justifyContent="center"
            w="full"
        >
            <AskAiPlaceholder />
            <form className="w-full" onSubmit={handleSubmit}>
                <FlexRow w="full">
                    <Textfield
                        ref={inputRef}
                        placeholder={t(keys.ask_me_anything)}
                        type="text"
                        className="w-full"
                    />
                    <Button
                        className="rounded-[120px] relative right-8 top-3 min-h-[32px] min-w-[32px]"
                        size="sm"
                        type="submit"
                    >
                        <Button.Icon name="arrowUp" />
                    </Button>
                </FlexRow>
            </form>
        </FlexCol>
    );
};

export function useFirstPrompt() {
    const setCurrentThreadId = useSetAtom(currentAiThreadIdAtom);

    const { sendPrompt } = useSendPrompt();
    const { createThreadAsync } = useAsyncCreateEmptyThread();

    const createFirstThread = async (prompt: string) => {
        const thread = await createThreadAsync();
        await sendPrompt({ prompt, thread });
        setCurrentThreadId(thread.id);
    };

    return { createFirstThread };
}
