import i18next from "../../../i18n";

i18next.addResources("en", "EditDocument", {
    ready_for_approval: "Ready for approval",
    validate: "Validate",
    back_to_edit: "Back to edit mode",
    download_document: "Download document",
    pdf_generate: "Generate PDF and download",
    copy_template_to_cp: "Copy template to CP",
    save_template_to_cms:
        "Save template to cms (this will impact ALL customers)",
    save_and_close: "Save and close",
    close: "Close",
    more: "More",
    view_history: "Show version history",
    ready_for_approval_modal_title: "Check before validate.",
    historic_version_warning:
        "This is a historic version and cannot be modified directly",
    close_historic_mode: "Close historic mode",
    no_preview:
        "This document format cannot be viewed directly.<br />Please download it to access the content.",

    ready_for_approval_modal_subtitle:
        "Before validating your policy, make sure it contains all the expected elements and complies with the expectations of evaluators and certifiers",
    ready_for_approval_modal_check_1:
        "<bold>Sustainability issues:</bold> Attached documentation clearly lays out principles and commitments on the topics addressed",
    ready_for_approval_modal_check_2:
        "<bold>Scope:</bold> Scope of application, i.e. sites and activities of the organization covered by the policy, is clearly defined in the policy",
    ready_for_approval_modal_check_3:
        "<bold>Objectives:</bold> Policy specifies quantitative objectives associated with dates for the designated topics",
    ready_for_approval_modal_check_4:
        "<bold>Governance:</bold> Policy details the distribution of roles and responsibilities across the organization relative to the designated topic, as well as the policy implementation process",
    ready_for_approval_modal_check_5:
        "<bold>Revision:</bold> Policy specifies the distribution and revision mechanisms, including the frequency of revision",

    update_bar_description:
        "This document is not editable. Please upload a new document to make updates.",
    update_bar_button_label: "Update document",
    pending_review_warning: "Document under review",
    approved_version_banner: "This document is approved and cannot be edited",
});

i18next.addResources("fr", "EditDocument", {
    ready_for_approval: "Prêt pour la validation",
    validate: "Valider",
    back_to_edit: "Retour au mode édition",
    download_document: "Télécharger le document",
    pdf_generate: "Télécharger en pdf",
    save_template_to_cms:
        "Enregistrer le modèle dans le CMS. (Cela affectera TOUS les clients)",
    save_and_close: "Enregistrer et fermer",
    close: "Fermer",
    more: "Plus",
    ready_for_approval_modal_title: "Vérifier avant de valider.",
    view_history: "Voir l'historique des versions",
    historic_version_warning:
        "Ce document est une version historique et ne peut pas être directement modifié",
    close_historic_mode: "Quitter le mode historique",
    no_preview:
        "Ce format de document ne peut pas être visualisé directement.<br />Veuillez le télécharger pour accéder au contenu.",

    ready_for_approval_modal_subtitle:
        "Avant de valider votre politique, assurez-vous qu’elle contient tous les éléments attendus et est conforme aux attentes des évaluateurs et certificateurs",
    ready_for_approval_modal_check_1:
        "<bold>Enjeux de durabilité:</bold> La politique couvre couvre clairement les enjeux clés de durabilité identifiés pour l’entreprise",
    ready_for_approval_modal_check_2:
        "<bold>Périmètre:</bold> Le périmètre, c'est-à-dire les sites et les activités de l'organisation couverts par la politique, est clairement défini dans la politique",
    ready_for_approval_modal_check_3:
        "<bold>Objectifs:</bold> La politique spécifie des objectifs quantitatifs associés à des dates pour le sujet désigné",
    ready_for_approval_modal_check_4:
        "<bold>Gouvernance:</bold> La politique précise la répartition des rôles et des responsabilités au sein de l'organisation par rapport au sujet désigné, ainsi que le processus de mise en œuvre de la politique",
    ready_for_approval_modal_check_5:
        "<bold>Révision:</bold> La politique spécifie les mécanismes de distribution et de révision, y compris la fréquence de révision",

    update_bar_description:
        "Ce document n'est pas modifiable. Veuillez télécharger un nouveau document pour effectuer des mises à jour.",
    update_bar_button_label: "Mettre à jour le document",
    pending_review_warning: "Document en cours de révision",
    approved_version_banner:
        "Ce document est approuvé et ne peut pas être modifié",
});
