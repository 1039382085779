import Floatbar from "@app/components/Floatbar";
import DataTable from "@app/components/Table/DataTable";
import LoadingTable from "@app/components/Table/LoadingTable";
import { useNavigation } from "@app/lib/navigation";
import SendReminderModal from "@app/pages/Reporting/components/IndicatorsTable/sendReminderModal";
import {
    openReminderModalAtom,
    selectedUsersAtomFamily,
    usersToSendReminderAtom,
} from "@app/pages/Reporting/context";
import { getFilteredReportingContributors } from "@app/pages/Reporting/data";
import { Button } from "@design-system/Inputs/Button";
import { Text } from "@design-system/Typography/Text";
import {
    Row,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { User } from "generated/client/graphql";
import { useAtom, useSetAtom } from "jotai";
import { useEffect, type FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "../../i18n";
import { useColumns } from "./columns";

const ReportingProgressTable: FC = () => {
    const { org_uname } = useParams();
    const { t } = useTranslation("ReportingContributorsPage");
    const { data, isPending } = getFilteredReportingContributors();
    const setOpenReminderModal = useSetAtom(openReminderModalAtom);
    const columns = useColumns();

    const table = useReactTable<User>({
        data: (data as User[]) || [],
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        autoResetPageIndex: false,
    });
    const { navigateFromHome } = useNavigation();
    const [_, setSelectedUsers] = useAtom(selectedUsersAtomFamily(org_uname));
    const redirectToFilteredOverview = (row: Row<User>) => {
        navigateFromHome(`reporting/overview`);
        setSelectedUsers([row.original?.id]);
    };
    const [usersToSendReminder, setUsersToSendReminder] = useAtom(
        usersToSendReminderAtom,
    );

    useEffect(() => {
        setUsersToSendReminder(
            table.getSelectedRowModel().flatRows.map((r) => r.original),
        );
    }, [table.getSelectedRowModel()]);
    const selectedUsers = table
        .getSelectedRowModel()
        .flatRows.map((r) => r.original);

    const handleActionClick = (_: any) => {
        setOpenReminderModal(true);
    };

    return (
        <>
            {isPending ? (
                <LoadingTable />
            ) : (
                <DataTable
                    onRowClick={(row) => {
                        redirectToFilteredOverview(row);
                    }}
                    table={table}
                    rowClassName={() =>
                        "hover:bg-secondary h-[64px] cursor-pointer"
                    }
                    type="no-v-padding"
                />
            )}
            <Floatbar
                open={selectedUsers.length > 0}
                primary={
                    <Button onClick={handleActionClick}>
                        <Text variant="body-bold" color="white">
                            {t("send_reminder")}
                        </Text>
                    </Button>
                }
                secondary={<></>}
                unset={table.resetRowSelection}
            ></Floatbar>
            <SendReminderModal users={usersToSendReminder}></SendReminderModal>
        </>
    );
};

export default ReportingProgressTable;
