import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import {
    GetObjectiveQuery,
    ObjectiveTargetStatus,
} from "@generated/client/graphql";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME, keys } from "./i18n";
import { Ellipsis } from "@design-system/Typography/Ellipsis";

type TargetDisplayProps = {
    objective?: GetObjectiveQuery["objective"];
};

export const TargetDisplay: FC<TargetDisplayProps> = ({ objective }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const indicator = objective?.indicator;
    const targets = objective?.targets;
    const sortedTargets = targets?.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );
    const ongoingTargets = sortedTargets?.filter(
        (target) => target.status === ObjectiveTargetStatus.Ongoing,
    );
    const missedTargets = sortedTargets?.filter(
        (target) => target.status === ObjectiveTargetStatus.Missed,
    );
    const reachedTargets = sortedTargets?.filter(
        (target) => target.status === ObjectiveTargetStatus.Reached,
    );
    const targetToDisplay =
        ongoingTargets?.[0] ||
        reachedTargets?.[reachedTargets.length - 1] ||
        missedTargets?.[missedTargets.length - 1];

    dayjs.extend(LocalizedFormat);

    const displayDate = targetToDisplay?.date
        ? dayjs(targetToDisplay.date).format("LL")
        : "";

    return targetToDisplay && indicator ? (
        <FlexRow gap="3" alignItems="center" className="w-full">
            <FlexRow
                justifyContent="center"
                alignItems="center"
                className="w-[28px] h-[28px] rounded-full border border-neutral-150 shrink-0"
            >
                <Icon name="flag" className="text-neutral-500" size="xs" />
            </FlexRow>
            <Text variant="body" color="primary" className="w-full max-w-fit">
                {targetToDisplay?.value?.number} {indicator?.unit?.shortName}
            </Text>
            <FlexRow gap="1.5" alignItems="center" className="truncate">
                <Icon name="calendar" size="sm" className="text-primary" />
                <Ellipsis asChild hasTooltip>
                    <Text variant="body" color="primary">
                        {displayDate}
                    </Text>
                </Ellipsis>
            </FlexRow>
        </FlexRow>
    ) : (
        <Text variant="body" color="tertiary">
            {t(keys.no_target)}
        </Text>
    );
};
