import { useSetToast } from "@app/components/Toast";
import { useDisplayedPeriod } from "@app/pages/Reporting/hooks";
import { useUpdateObjective } from "@app/screens/Objectives/data";
import { orderPeriods } from "@app/usecases/ReportingUseCases";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { DropdownMenu } from "@design-system/Inputs/DropdownMenu";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { GetObjectiveQuery } from "@generated/client/graphql";
import { useSetAtom } from "jotai";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isIndicatorSelectModalOpenAtom } from "../../ctx";
import { RESOURCE_NAME, keys } from "./i18n";

type IndicatorDisplayProps = {
    objective?: GetObjectiveQuery["objective"];
};

export const IndicatorDisplay: FC<IndicatorDisplayProps> = ({ objective }) => {
    const setIsIndicatorSelectModalOpen = useSetAtom(
        isIndicatorSelectModalOpenAtom,
    );
    const { setToastSuccess, setToastError } = useSetToast();
    const { mutate } = useUpdateObjective(objective?.id);
    const handleEditIndicatorClick = () => {
        setIsIndicatorSelectModalOpen(true);
    };

    const handleDeleteIndicatorClick = () => {
        if (!objective) return;
        mutate(
            {
                id: objective.id,
                indicatorId: null,
            },
            {
                onSuccess: () => {
                    setToastSuccess(t(keys.update_success));
                },
                onError: () => {
                    setToastError(t(keys.update_error));
                },
            },
        );
    };

    const { getDetailedDisplayedPeriod } = useDisplayedPeriod();

    const { t } = useTranslation(RESOURCE_NAME);
    const latestDatapoint = useMemo(() => {
        const entityDatapoints = objective?.indicator?.datapoints?.filter(
            (dp) => dp.datapointGroup?.entityId === objective?.entityId,
        );
        const sortedDatapoints = entityDatapoints
            ?.sort((d1, d2) =>
                orderPeriods(
                    d2.datapointGroup?.period,
                    d1.datapointGroup?.period,
                ),
            )
            .filter((dp) => !dp.value.noData);
        return !!sortedDatapoints?.length ? sortedDatapoints[0] : undefined;
    }, [objective?.indicator]);

    const latestValue = latestDatapoint?.value?.float || "-";
    const latestPeriod = getDetailedDisplayedPeriod(
        latestDatapoint?.datapointGroup?.period,
    );

    return objective?.indicator ? (
        <FlexCol
            w="full"
            px="5"
            py="4"
            br="lg"
            alignItems="start"
            className="border border-secondary"
        >
            <FlexRow alignItems="center" className="gap-[32px]" w="full">
                <FlexRow
                    p="1"
                    justifyContent="between"
                    alignItems="start"
                    gap="3"
                    h="full"
                >
                    <FlexRow h="full" w="full" alignItems="center" gap="2">
                        <Text className="text-2xl font-bold">
                            {latestValue}
                        </Text>
                        <Text variant="body">
                            {objective?.indicator.unit?.shortName}
                        </Text>
                    </FlexRow>
                    <FlexRow alignItems="start">
                        <Tooltip delayDuration={0}>
                            <Tooltip.Trigger>
                                <Icon
                                    name="info"
                                    size="sm"
                                    className="text-secondary"
                                />
                            </Tooltip.Trigger>

                            <Tooltip.Content>
                                {!!latestDatapoint
                                    ? t(keys.latest_period, {
                                          period: latestPeriod,
                                      })
                                    : t(keys.no_latest_period)}
                            </Tooltip.Content>
                        </Tooltip>
                    </FlexRow>
                </FlexRow>
                <FlexCol alignItems="end" gap="3" w="full" py="1">
                    <FlexRow alignItems="start" w="full">
                        <Text variant="body" color="secondary">
                            {t(keys.indicator)}
                        </Text>
                    </FlexRow>
                    <FlexRow alignItems="start" w="full">
                        <Text variant="body">{objective?.indicator.name}</Text>
                    </FlexRow>
                </FlexCol>
                <FlexCol h="full" alignSelf="stretch">
                    {!!objective?.targets?.length ? (
                        <Tooltip delayDuration={0}>
                            <Tooltip.Trigger className="h-full">
                                <Icon
                                    name="more"
                                    size="sm"
                                    className="text-tertiary cursor-default"
                                />
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                {t(keys.disabled_targets_tooltip)}
                            </Tooltip.Content>
                        </Tooltip>
                    ) : (
                        <DropdownMenu>
                            <DropdownMenu.Trigger>
                                <Icon
                                    name="more"
                                    size="sm"
                                    className="text-primary"
                                />
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Content align="end">
                                <DropdownMenu.Item
                                    onClick={handleEditIndicatorClick}
                                >
                                    <FlexRow w="full" h="full" gap="2">
                                        <DropdownMenu.ItemIcon
                                            name="edit"
                                            size="sm"
                                        />
                                        <Text variant="body-sm">
                                            {t(keys.edit)}
                                        </Text>
                                    </FlexRow>
                                </DropdownMenu.Item>
                                <DropdownMenu.Item
                                    className="data-[highlighted]:bg-[#FEEFEC] data-[highlighted]:text-error-500"
                                    onClick={handleDeleteIndicatorClick}
                                >
                                    <FlexRow w="full" h="full" gap="2">
                                        <DropdownMenu.ItemIcon
                                            name="trash"
                                            size="sm"
                                        />

                                        <Text variant="body-sm">
                                            {t(keys.delete)}
                                        </Text>
                                    </FlexRow>
                                </DropdownMenu.Item>
                            </DropdownMenu.Content>
                        </DropdownMenu>
                    )}
                </FlexCol>
            </FlexRow>
        </FlexCol>
    ) : (
        <FlexCol
            w="full"
            p="8"
            gap="4"
            className="border border-secondary bg-neutral-25"
            br="lg"
            alignItems="center"
        >
            <FlexCol gap="1" alignItems="center">
                <Text variant="body-sm" color="primary" className="font-[500]">
                    {t(keys.no_indicator_selected)}
                </Text>
                <Text variant="body-sm" color="secondary">
                    {t(keys.no_indicator_selected_hint)}
                </Text>
            </FlexCol>
            <Button
                variant="outlined"
                size="xs"
                className="w-fit rounded-xl"
                onClick={handleEditIndicatorClick}
                disabled={!!objective?.targets.length}
            >
                <FlexRow gap="1" alignItems="center">
                    <Text variant="body-sm">
                        {t(keys.no_indicator_selected_button)}
                    </Text>
                    <Icon name="plus" size="sm" />
                </FlexRow>
            </Button>
        </FlexCol>
    );
};
