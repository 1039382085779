import { useEffect, useState } from "react";

import { Supplier } from "@app/screens/Supplier/Suppliers/data";
import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { Eye, EyeClosed } from "iconoir-react";
import { atom, useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import {
    SupplierQuestionnaire,
    TagType,
} from "../../../../../../../../generated/client/graphql";
import ButtonSmall from "../../../../../../../components/Button/ButtonSmall";
import BaseItem from "../../../../../../../components/Dropdown/components/dropdowns/items/BaseItem";
import DropdownContentLayout from "../../../../../../../components/Dropdown/components/dropdowns/layout";
import BaseTrigger from "../../../../../../../components/Dropdown/components/triggers/Base";
import TemplateSetterFloatbar from "../../../../../../../components/Dropdown/dropdowns/SupplierQuestionnaireTemplate/setterFloatbar";
import TagsSetterFloatbar from "../../../../../../../components/Dropdown/dropdowns/Tags/setterFloatbar";
import Floatbar from "../../../../../../../components/Floatbar";
import SupplierReminder from "../../../../../../../components/Reminder/SupplierReminder";
import {
    useCloseOngoingQuestionnaires,
    useCreateQuestionnaires,
    useUpdateSuppliers,
} from "../../../hooks";
import "./i18n";

export enum Actions {
    SET_TAGS = "SET_TAGS",
    START_QUESTIONNAIRES = "START_QUESTIONNAIRES",
    CLOSE_OPEN_QUESTIONNAIRE = "CLOSE_QUESTIONNAIRES",
    SEND_EMAIL = "SEND_EMAIL",
}

export const supplierSaveBarActionAtom = atom<Actions | undefined>(undefined);
const ActionBar = ({
    suppliers,
}: // unset,
{
    suppliers: Supplier[];
    unset: () => void;
}) => {
    const { t } = useTranslation("SupplierTableSavebar");
    const { updateSuppliers } = useUpdateSuppliers();
    const createQuestionnairesMutation = useCreateQuestionnaires();
    const closeOngoingQuestionnairesMutation = useCloseOngoingQuestionnaires();

    const [activeAction, setActiveAction] = useAtom(supplierSaveBarActionAtom);
    const [tagIds, setTagIds] = useState<string[]>([]);
    const [templateId, setTemplateId] = useState<string>();

    useEffect(() => {
        setTagIds([]);
        setActiveAction(undefined);
    }, []);
    return (
        <Floatbar
            open={suppliers.length > 0}
            primary={
                <ButtonSmall onClick={() => setActiveAction(Actions.SET_TAGS)}>
                    {t("set_tags_button")}
                </ButtonSmall>
            }
            secondary={
                <DropdownMenu>
                    <BaseTrigger>{t("more")}</BaseTrigger>
                    <DropdownContentLayout>
                        <BaseItem
                            onClick={() =>
                                setActiveAction(Actions.START_QUESTIONNAIRES)
                            }
                            IconLeft={() => (
                                <Eye className="text-green h-4 w-4" />
                            )}
                        >
                            {t("start_questionnaire_button")}
                        </BaseItem>
                        <BaseItem
                            onClick={() =>
                                setActiveAction(
                                    Actions.CLOSE_OPEN_QUESTIONNAIRE,
                                )
                            }
                            IconLeft={() => (
                                <EyeClosed className="text-green h-4 w-4" />
                            )}
                        >
                            {t("close_questionnaire_button")}
                        </BaseItem>
                        <BaseItem
                            onClick={() => setActiveAction(Actions.SEND_EMAIL)}
                            IconLeft={() => (
                                <EyeClosed className="text-green h-4 w-4" />
                            )}
                        >
                            {t("send_email")}
                        </BaseItem>
                    </DropdownContentLayout>
                </DropdownMenu>
            }
            actions={[
                {
                    id: Actions.SET_TAGS,
                    text: t("set_tags_text", { count: suppliers.length }),
                    selector: (
                        <div>
                            <TagsSetterFloatbar
                                type={TagType.Supplier}
                                tagIds={tagIds}
                                onTagIdsChange={setTagIds}
                            />
                        </div>
                    ),
                    apply: (
                        <ButtonSmall
                            onClick={() =>
                                updateSuppliers(
                                    suppliers.map((s) => s.id),
                                    {
                                        tagIds: tagIds,
                                    },
                                )
                            }
                        >
                            {t("apply")}
                        </ButtonSmall>
                    ),
                },
                {
                    id: Actions.START_QUESTIONNAIRES,
                    text: t("start_questionnaire_text", {
                        count: suppliers.length,
                    }),
                    selector: (
                        <TemplateSetterFloatbar
                            templateId={templateId}
                            onTemplateIdChange={setTemplateId}
                        />
                    ),
                    apply: (
                        <ButtonSmall
                            loading={createQuestionnairesMutation.isPending}
                            disabled={!templateId}
                            onClick={() =>
                                templateId &&
                                createQuestionnairesMutation.mutate(
                                    suppliers.map((s) => s.id),
                                    templateId,
                                )
                            }
                        >
                            {t("apply")}
                        </ButtonSmall>
                    ),
                },
                {
                    id: Actions.CLOSE_OPEN_QUESTIONNAIRE,
                    text: t("close_questionnaire_text", {
                        count: suppliers.length,
                    }),
                    // selector: (
                    //     <TagsSetter
                    //         type={TagType.Supplier}
                    //         tagIds={tagIds}
                    //         onTagIdsChange={setTagIds}
                    //     />
                    // ),
                    apply: (
                        <ButtonSmall
                            onClick={() =>
                                closeOngoingQuestionnairesMutation.mutate(
                                    suppliers.map((s) => s.id),
                                )
                            }
                            loading={
                                closeOngoingQuestionnairesMutation.isPending
                            }
                        >
                            {t("apply")}
                        </ButtonSmall>
                    ),
                },
                {
                    id: Actions.SEND_EMAIL,
                    text: t("send_email", {
                        count: suppliers.length,
                    }),
                    apply: (
                        <SupplierReminder
                            questionnaires={
                                suppliers
                                    .map((s) => s.lastQuestionnaire)
                                    .filter(
                                        (q) => q && q.status !== "CLOSED",
                                    ) as SupplierQuestionnaire[]
                            }
                            suppliers={suppliers.filter(
                                (s) =>
                                    s.lastQuestionnaire &&
                                    s.lastQuestionnaire?.status !== "CLOSED",
                            )}
                            Trigger={({ onClick }) => (
                                <ButtonSmall onClick={onClick}>
                                    {t("send_email")}
                                </ButtonSmall>
                            )}
                        />
                    ),
                },
            ]}
            activeAction={activeAction}
            setActiveAction={setActiveAction}
        ></Floatbar>
    );
};

export default ActionBar;
