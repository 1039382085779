import { graphql } from "@generated/client";
import {
    type DatapointUpsertInput,
    type ReportingExportFilesInput,
    type ReportingExportInput,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const UpsertDatapointV2Mutation = graphql(`
    mutation upsertDatapointV2($set: DatapointUpsertInput!) {
        upsertDatapointV2(set: $set) {
            id
            value
        }
    }
`);

const ExportReportingMutation = graphql(`
    mutation exportReporting($input: ReportingExportInput!) {
        exportReporting(input: $input) {
            signedUrl
        }
    }
`);

const ExportReportingFilesMutation = graphql(`
    mutation exportReportingFiles($input: ReportingExportFilesInput!) {
        exportReportingFiles(input: $input) {
            signedUrl
        }
    }
`);

export class GraphQLDatapointRepository {
    upsert = async (input: { set: DatapointUpsertInput }) => {
        return graphqlClientKoyeb.request(
            UpsertDatapointV2Mutation,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    export = async (input: ReportingExportInput) => {
        return graphqlClientKoyeb.request(
            ExportReportingMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    exportFiles = async (input: ReportingExportFilesInput) => {
        return graphqlClientKoyeb.request(
            ExportReportingFilesMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
