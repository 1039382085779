import { useDebouncedStableCallback } from "@app/shared/utils/debounce";
import { currentUserAtom } from "@app/store/userStore";
import { StatusTagCircular } from "@design-system/DataDisplay/StatusTagCircular";
import { Icon } from "@design-system/Icon";
import { Calendar } from "@design-system/Inputs/Calendar";
import Select from "@design-system/Inputs/Select";
import { Textfield } from "@design-system/Inputs/Textfield";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import {
    GetObjectiveQuery,
    ObjectiveTargetStatus,
} from "@generated/client/graphql";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@radix-ui/react-popover";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useAtomValue } from "jotai";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "./i18n";
import { useTarget } from "./useTarget";

export const Target: FC<{
    target: GetObjectiveQuery["objective"]["targets"][number];
    unitShortName: string;
    isLast: boolean;
    objectiveId: string;
}> = ({ target, unitShortName, isLast, objectiveId }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const currentUser = useAtomValue(currentUserAtom);
    const { targetState, handleChange, handleDelete, initState } = useTarget(
        target,
        objectiveId,
    );

    useEffect(() => {
        initState(target);
    }, [target]);

    const displayStatus =
        targetState.status === ObjectiveTargetStatus.Reached
            ? "validated"
            : targetState.status === ObjectiveTargetStatus.Ongoing
              ? "inProgress"
              : "missed";
    dayjs.extend(LocalizedFormat);

    const displayDate = dayjs(targetState.date).format("LL");

    const debounchedUpdateValue = useDebouncedStableCallback((value) => {
        handleChange({ value: { number: !!value ? Number(value) : null } });
    }, 300);

    const handleValueInputChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        if (event.target.value === "" || !isNaN(Number(event.target.value))) {
            targetState.setValue(
                !!event.target.value ? Number(event.target.value) : undefined,
            );
            debounchedUpdateValue(event.target.value);
        }
    };
    const [isDatePopoverOpen, setIsDatePopoverOpen] = useState(false);
    const [isValuePopoverOpen, setIsValuePopoverOpen] = useState(false);
    const handleStatusChange = (status: ObjectiveTargetStatus) => {
        targetState.setStatus(status);
        handleChange({ status: status });
    };
    const handleDateChange = (value: any) => {
        targetState.setDate(value);
        handleChange({ date: value });
    };

    const handleDeleteIconClick = () => {
        handleDelete();
    };

    return (
        <FlexRow gap="6" alignItems="center" w="full" alignSelf="stretch">
            <FlexRow gap="7" alignItems="center" className="grow" w="full">
                <FlexRow
                    justifyContent="center"
                    alignItems="center"
                    className="w-[28px] h-[28px] rounded-full border border-neutral-tertiary shrink-0"
                >
                    <Icon
                        name={isLast ? "target2" : "flag"}
                        className="text-neutral-500"
                        size="xs"
                    />
                </FlexRow>
                <FlexRow alignItems="center" gap="3" w="full">
                    <Select
                        value={targetState.status}
                        onValueChange={handleStatusChange}
                    >
                        <Select.Trigger asChild className="w-fit">
                            <StatusTagCircular status={displayStatus} />
                        </Select.Trigger>
                        <Select.Content>
                            <Select.Item value={ObjectiveTargetStatus.Reached}>
                                <FlexRow gap="2">
                                    <StatusTagCircular status="validated" />
                                    <Text variant="body">
                                        {t(keys.reached)}
                                    </Text>
                                </FlexRow>
                            </Select.Item>
                            <Select.Item value={ObjectiveTargetStatus.Ongoing}>
                                <FlexRow gap="2">
                                    <StatusTagCircular status="inProgress" />
                                    <Text variant="body">
                                        {t(keys.ongoing)}
                                    </Text>
                                </FlexRow>
                            </Select.Item>
                            <Select.Item value={ObjectiveTargetStatus.Missed}>
                                <FlexRow gap="2">
                                    <StatusTagCircular status="missed" />
                                    <Text variant="body">{t(keys.missed)}</Text>
                                </FlexRow>
                            </Select.Item>
                        </Select.Content>
                    </Select>

                    <FlexRow gap="0.5" alignItems="center">
                        <Popover
                            open={isValuePopoverOpen}
                            onOpenChange={setIsValuePopoverOpen}
                        >
                            <PopoverTrigger asChild className="cursor-pointer">
                                <FlexRow
                                    py="1"
                                    className={cn(
                                        (isValuePopoverOpen ||
                                            targetState.value === null) &&
                                            "bg-neutral-100",
                                        "hover:bg-neutral-100",
                                    )}
                                    minW="7"
                                    minH="6"
                                >
                                    <Text variant="body">
                                        {targetState.value}
                                    </Text>
                                </FlexRow>
                            </PopoverTrigger>
                            <PopoverContent
                                align="start"
                                side="bottom"
                                className="shadow-lg bg-white"
                            >
                                <FlexRow className="w-[150px] rounded-lg">
                                    <FlexRow p="2" alignItems="start">
                                        <Textfield
                                            value={targetState.value}
                                            onChange={handleValueInputChange}
                                            type="number"
                                            className="w-full"
                                        />
                                    </FlexRow>
                                </FlexRow>
                            </PopoverContent>
                        </Popover>

                        <Text variant="body" color="secondary">
                            {unitShortName}
                        </Text>
                    </FlexRow>
                </FlexRow>
            </FlexRow>

            <FlexRow gap="1" alignItems="center" w="full" className="grow">
                <Popover
                    open={isDatePopoverOpen}
                    onOpenChange={setIsDatePopoverOpen}
                >
                    <PopoverTrigger asChild className="cursor-pointer">
                        <FlexRow
                            w="fit"
                            className="group"
                            alignItems="center"
                            gap="1"
                        >
                            <Icon name="calendar" className="text-secondary" />
                            <FlexRow
                                className={cn(
                                    isDatePopoverOpen && "bg-neutral-100",
                                    "group-hover:bg-neutral-100 w-fit py-1 rounded-lg",
                                )}
                            >
                                <Text variant="body">{displayDate}</Text>
                            </FlexRow>
                        </FlexRow>
                    </PopoverTrigger>
                    <PopoverContent
                        align="start"
                        side="bottom"
                        className="shadow-lg bg-white"
                    >
                        <Calendar
                            mode="single"
                            defaultMonth={
                                targetState.date
                                    ? new Date(targetState.date)
                                    : new Date()
                            }
                            selected={
                                targetState.date
                                    ? new Date(targetState.date)
                                    : undefined
                            }
                            onSelect={handleDateChange}
                            className="rounded-md border"
                            locale={currentUser?.data?.currentUser?.locale}
                        />
                    </PopoverContent>
                </Popover>
            </FlexRow>

            <FlexRow
                alignItems="center"
                justifyContent="end"
                className="shrink"
            >
                <Box
                    w="fit"
                    onClick={handleDeleteIconClick}
                    className="cursor-pointer group"
                >
                    <Icon
                        name="trash"
                        size="sm"
                        className="group-hover:text-red-500"
                    />
                </Box>
            </FlexRow>
        </FlexRow>
    );
};
