import { adminPaths } from "@app/routing/paths";
import { useBreadcrumb } from "@app/shared/components/Breadcrumb/useBreadcrumb";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useObjectives } from "../data";

export function useObjectivesBreadcrumb(
    entityId: string | undefined,
    themeId: string | undefined,
) {
    const { objective_id } = useParams();
    const { objectives } = useObjectives({ entityId, themeId });

    const breadcrumbArgs = useMemo(() => {
        const options =
            objectives?.map((objective) => ({
                name: objective.name ?? "...",
                param: objective.id,
            })) ?? [];
        const currentObjective = objective_id
            ? objectives?.find((objective) => objective.id === objective_id)
            : null;

        return [
            adminPaths.objective_id_param,
            currentObjective?.name ?? "...",
            options,
        ] as const;
    }, [objective_id, objectives]);

    const setBreadcumb = useBreadcrumb();

    useEffect(() => {
        const [path, name, options] = breadcrumbArgs;
        setBreadcumb(path, name, options);
    }, [breadcrumbArgs]);
}
