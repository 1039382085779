import { Box } from "@design-system/Layout/Box";
import { cn } from "@design-system/Utilities";
import { type FC, FormEvent, KeyboardEvent, useRef } from "react";

type EditableTextProps = {
    value: string | undefined;
    onInput?: (event: FormEvent<HTMLDivElement>) => void;
    onBlur: () => void;
    placeholder?: string;
    variant?: "header1" | "body";
};

export const EditableText: FC<EditableTextProps> = ({
    value,
    onInput,
    onBlur,
    placeholder,
    variant = "body",
}) => {
    const divRef = useRef<HTMLDivElement>(null);
    const valueRef = useRef(value || placeholder);

    const handleInputBlur = () => {
        valueRef.current = value || placeholder;
        if (divRef.current) {
            divRef.current.textContent = valueRef.current || null;
        }
        onBlur();
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            divRef.current?.blur();
        }
    };

    const handleInputFocus = () => {
        if (!value && divRef.current) {
            divRef.current.textContent = "";
        }
    };

    return (
        <Box className="flex items-start w-full">
            <div
                ref={divRef}
                contentEditable
                autoFocus
                onBlur={handleInputBlur}
                onKeyDown={handleKeyDown}
                onInput={onInput}
                onFocus={handleInputFocus}
                className={cn(
                    "p-0 border-none w-full focus:ring-0 focus:text-secondary outline-none whitespace-pre-wrap",
                    !value && "text-secondary",
                    variant === "header1" && "text-2xl font-bold",
                    variant === "body" && "text-base",
                )}
                suppressContentEditableWarning
            >
                {valueRef.current}
            </div>
        </Box>
    );
};
