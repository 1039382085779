import { AccessHeadersHolder } from "@app/repositories/AccessHeadersHolder";
import { graphqlClientKoyeb } from "@app/repositories/clients";
import { graphql } from "@generated/client";

const GetVectorStoreQuery = graphql(`
    query GetVectorStore {
        aiVectorStore {
            openAiStoreId
        }
    }
`);
const GetAiThreadsQuery = graphql(`
    query GetAiThreads {
        aiThreads {
            id
            openAiThreadId
            updatedAt
        }
    }
`);

const GetAiThreadQuery = graphql(`
    query GetAiThread($id: String!) {
        aiThread(id: $id) {
            id
            openAiThreadId
            updatedAt
            messages {
                openAiId
                role
                content {
                    text {
                        value
                        annotations {
                            text
                            openAiFileId
                        }
                    }
                }
            }
        }
    }
`);

const GetAnnotationMetadataQuery = graphql(`
    query GetAnnotationMetadata($openAiFileId: String!) {
        aiFileMetadata(openAiFileId: $openAiFileId) {
            openAiFileId
            document {
                id
                name
                type
                uniqueUrl
            }
        }
    }
`);

const CreateThreadMutation = graphql(`
    mutation CreateAiThread {
        createAiThread {
            openAiThreadId
            updatedAt
            id
        }
    }
`);

const DeleteThreadMutation = graphql(`
    mutation DeleteAiThread($id: String!) {
        deleteAiThread(id: $id)
    }
`);

const InitializeVectorStoreWithSharedDocumentsMutation = graphql(`
    mutation InitializeVectorStoreWithSharedDocuments {
        initializeVectorStoreWithSharedDocuments {
            openAiStoreId
        }
    }
`);

const InitializeVectorStoreWithTenantDocumentsMutation = graphql(`
    mutation InitializeVectorStoreWithTenantDocuments {
        initializeVectorStoreWithTenantDocuments {
            openAiStoreId
        }
    }
`);

export class GraphQLAiRepository {
    getVectorStore = async () => {
        return graphqlClientKoyeb.request(
            GetVectorStoreQuery,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    };

    createThread = async () => {
        return graphqlClientKoyeb.request(
            CreateThreadMutation,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getUserThreads = async () => {
        return graphqlClientKoyeb.request(
            GetAiThreadsQuery,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getThread = async (id: string) => {
        return graphqlClientKoyeb.request(
            GetAiThreadQuery,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    deleteThread = async (id: string) => {
        return graphqlClientKoyeb.request(
            DeleteThreadMutation,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    initializeVectorStoreWithSharedDocuments = async () => {
        return graphqlClientKoyeb.request(
            InitializeVectorStoreWithSharedDocumentsMutation,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    };

    initializeVectorStoreWithTenantDocuments = async () => {
        return graphqlClientKoyeb.request(
            InitializeVectorStoreWithTenantDocumentsMutation,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getAnnotationMetadata = async (openAiFileId: string) => {
        return graphqlClientKoyeb.request(
            GetAnnotationMetadataQuery,
            { openAiFileId },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
