import EntitySelect from "@app/shared/components/EntitySelect";
import { useEntities } from "@app/store/entityStore";
import { useThemes } from "@app/store/themeStore";
import { Icon } from "@design-system/Icon";
import SearchBar from "@design-system/Inputs/SearchBar";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParam } from "use-query-params";
import { activeEntityIdAtom, activeTabAtom } from "../../ctx";
import { ObjectivesPageSidebarSkeleton } from "../Skeletons/ObjectivesPageSidebarSkeleton";
import { keys, RESOURCE_NAME } from "./i18n";

export const ObjectivesPageSideBar = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { entities, isPending: isEntitiesPending } = useEntities();
    const [activeEntityId, setActiveEntityId] = useAtom(activeEntityIdAtom);
    const [urlEntity, setUrlEntity] = useQueryParam("entity", StringParam);
    const [activeTab, setActiveTab] = useAtom(activeTabAtom);
    const { themes, isPending: isThemesPending } = useThemes();

    // Initialize the entity
    useEffect(() => {
        if (
            !!entities?.length &&
            (!activeEntityId ||
                !entities.map((e) => e.id).includes(activeEntityId))
        ) {
            const urlMatchedEntity = entities.find(
                (entity) => entity.name === urlEntity,
            );
            const defaultEntity = entities.find((entity) => !!entity.isDefault);
            setActiveEntityId(
                urlMatchedEntity
                    ? urlMatchedEntity.id
                    : defaultEntity
                      ? defaultEntity.id
                      : entities[0].id,
            );
        }
    }, [entities, setActiveEntityId]);

    // Update the url entity
    useEffect(() => {
        const entity = entities?.find((entity) => entity.id === activeEntityId);
        if (entity) setUrlEntity(entity.name);
    }, [entities, activeEntityId]);

    useEffect(() => {
        if (!activeTab && themes) {
            const themeId = themes.find((theme) => !!theme.id)?.id;
            if (themeId) setActiveTab(themeId);
        }
    }, [themes, setActiveTab]);

    const [urlSearchString, setUrlSearchString] = useQueryParam(
        "search",
        StringParam,
    );
    const handleThemeClick = (themeId: string | undefined | null) => {
        return () => {
            if (themeId) setActiveTab(themeId);
        };
    };
    const isActiveTheme = (themeId: string) => {
        return activeTab === themeId;
    };
    return isEntitiesPending || isThemesPending ? (
        <ObjectivesPageSidebarSkeleton />
    ) : (
        <FlexCol
            justifyContent="center"
            alignItems="start"
            gap="4"
            className="w-[240px]"
        >
            {!!entities?.length && (
                <EntitySelect
                    entityId={activeEntityId}
                    setEntityId={setActiveEntityId}
                    className="w-full"
                />
            )}
            <SearchBar
                searchString={urlSearchString || undefined}
                setSearchString={setUrlSearchString}
                placeholder={t(keys.search_placeholder)}
                className="w-[210px]"
            />
            <FlexCol alignItems="start" gap="0.5" w="full">
                <FlexRow alignItems="center" gap="2.5" px="2" py="1.5">
                    <Text
                        className="uppercase"
                        color="secondary"
                        variant="body-xs-bold"
                    >
                        {t(keys.themes)}
                    </Text>
                </FlexRow>
                {themes?.map((theme) => (
                    <FlexRow
                        key={theme.id}
                        alignItems="center"
                        gap="2.5"
                        px="2"
                        py="1.5"
                        w="full"
                        className={cn(
                            "cursor-pointer rounded-lg hover:bg-secondary",
                            isActiveTheme(theme.id || "") &&
                                "bg-tertiary text-beavrGreen",
                        )}
                        onClick={handleThemeClick(theme.id)}
                        style={{
                            color: theme.color,
                        }}
                    >
                        <Icon name="tagCircle" size="xs" />

                        <Text
                            className={cn(
                                isActiveTheme(theme.id || "")
                                    ? "text-beavrGreen"
                                    : "text-primary",
                            )}
                        >
                            {theme.name}
                        </Text>
                    </FlexRow>
                ))}
            </FlexCol>
        </FlexCol>
    );
};
