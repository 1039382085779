import { HeaderFilter } from "@design-system/Table";
import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { NavArrowDown, Xmark } from "iconoir-react";
import { atom, useAtom } from "jotai";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    GetThemesWithSubthemesDocument,
    Theme,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import { cn } from "../../../../lib/utils";
import { ButtonType } from "../../../Button/ButtonShared";
import ButtonSmall from "../../../Button/ButtonSmall";
import Checkbox from "../../../Checkbox";
import "./i18n";

export const filterSubthemeIdsAtom = atom<string[] | undefined>(undefined);

const ThemeActionList = ({ theme }: { theme: Theme }) => {
    const [filterSubThemeIds, setFilterSubThemeIds] = useAtom(
        filterSubthemeIdsAtom,
    );

    const themeChecked = theme.subthemes.every((st) =>
        filterSubThemeIds?.includes(st.id),
    )
        ? true
        : theme.subthemes.some((st) => filterSubThemeIds?.includes(st.id))
          ? "partial"
          : false;
    const onSubthemeIdCheckedChange = (id: string, checked: boolean) => {
        if (checked) {
            setFilterSubThemeIds([...(filterSubThemeIds || []), id]);
        } else {
            setFilterSubThemeIds(
                filterSubThemeIds?.filter((subthemeId) => subthemeId !== id),
            );
        }
    };
    const onThemeCheckedChange = (checked: boolean) => {
        if (checked) {
            setFilterSubThemeIds([
                ...(filterSubThemeIds || []),
                ...theme.subthemes.map((st) => st.id),
            ]);
        } else {
            setFilterSubThemeIds(
                filterSubThemeIds?.filter((id) =>
                    theme.subthemes.every((st) => st.id !== id),
                ),
            );
        }
    };
    return (
        <>
            <DropdownPrimitive.CheckboxItem
                checked={themeChecked === true ? true : false}
                onSelect={(e) => e.preventDefault()}
                className="px-2 py-1.5 flex space-x-2.5"
                onCheckedChange={(checked) => onThemeCheckedChange(checked)}
            >
                <Checkbox
                    color={theme?.color || undefined}
                    checked={themeChecked || false}
                    onCheckedChange={(checked) => onThemeCheckedChange(checked)}
                />
                <div
                    className={cn(
                        "text-sm",
                        themeChecked === true
                            ? "font-bold"
                            : themeChecked === "partial"
                              ? ""
                              : "font-regular",
                    )}
                >
                    {theme.name}
                </div>
            </DropdownPrimitive.CheckboxItem>

            {theme.subthemes.map((st) => (
                <div key={st.id} className="flex">
                    <div className="w-px bg-neutral-100 mr-2 ml-4" />
                    <DropdownPrimitive.CheckboxItem
                        className="flex-grow px-2 py-1.5 flex space-x-2.5"
                        checked={filterSubThemeIds?.includes(st.id)}
                        onSelect={(e) => e.preventDefault()}
                        onCheckedChange={(checked) =>
                            onSubthemeIdCheckedChange(st.id, checked)
                        }
                        color={theme?.color || undefined}
                    >
                        <Checkbox
                            checked={
                                filterSubThemeIds?.includes(st.id) || false
                            }
                            onCheckedChange={(checked) =>
                                onSubthemeIdCheckedChange(st.id, checked)
                            }
                            color={theme?.color || undefined}
                        />
                        <div
                            className={cn(
                                "text-sm",
                                filterSubThemeIds?.includes(st.id)
                                    ? "font-bold"
                                    : "font-regular",
                            )}
                        >
                            {st.name}
                        </div>
                    </DropdownPrimitive.CheckboxItem>
                </div>
            ))}
        </>
    );
};

type SubthemeFilterProps = {
    isHeader?: boolean;
};

export const SubthemeFilter: FC<SubthemeFilterProps> = ({ isHeader }) => {
    const { t } = useTranslation("SubthemeFilter");

    const [filterSubThemeIds, setFilterSubThemeIds] = useAtom(
        filterSubthemeIdsAtom,
    );

    const { data } = useGraphQL(GetThemesWithSubthemesDocument);

    useEffect(() => () => setFilterSubThemeIds(undefined), []);

    const active = !!filterSubThemeIds?.length;

    return (
        <>
            <DropdownPrimitive.Root>
                <DropdownPrimitive.Trigger asChild>
                    {isHeader ? (
                        <HeaderFilter active={active}>
                            {t("placeholder", {
                                context: active && "active",
                                count: filterSubThemeIds?.length,
                            })}
                        </HeaderFilter>
                    ) : (
                        <ButtonSmall
                            variant={ButtonType.OUTLINED}
                            active={filterSubThemeIds !== undefined}
                            text={t("placeholder")}
                            IconRight={
                                filterSubThemeIds !== undefined
                                    ? Xmark
                                    : NavArrowDown
                            }
                            onRightClick={
                                filterSubThemeIds !== undefined
                                    ? () => setFilterSubThemeIds(undefined)
                                    : undefined
                            }
                            className="h-[28px]"
                        />
                    )}
                </DropdownPrimitive.Trigger>

                <DropdownPrimitive.Portal>
                    <DropdownPrimitive.Content
                        className="bg-white shadow-dropdown p-1 rounded-lg max-h-[300px] overflow-auto"
                        align="start"
                        sideOffset={5}
                    >
                        {data?.themes.map((t) => (
                            <ThemeActionList key={t.id} theme={t as Theme} />
                        ))}
                    </DropdownPrimitive.Content>
                </DropdownPrimitive.Portal>
            </DropdownPrimitive.Root>
        </>
    );
};
