import { graphql } from "@generated/client";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const GetUnitsQuery = graphql(`
    query getUnits {
        units {
            id
            name
            type
            bvId
        }
    }
`);

export class GraphQLUnitRepository {
    getAll = async () => {
        return graphqlClientKoyeb.request(
            GetUnitsQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
