import { graphql } from "@generated/client";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const GetAllMyFilesQuery = graphql(`
    query GetAllMyFiles {
        evidenceFiles {
            id
            name
            createdAt
            filename
            signedUrl
            fileSize
            mimeType
            extension
            documentVersions {
                id
                document {
                    name
                    uniqueUrl
                    cmsDocumentId
                    type
                }
                status
            }
            csrdDatapoints {
                id
                name
                cmsId
                esrsId
                cmsDrId
                cmsPillarId
                status
                validationStatus
            }
            withEditor
        }
    }
`);

export class GraphQLAllMyFilesRepository {
    async getEvidenceFiles() {
        return graphqlClientKoyeb.request(
            GetAllMyFilesQuery,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    }
}
