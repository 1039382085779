import { graphql } from "@generated/client";
import { type UpsertDashboardPlanStepMutationVariables } from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const GetDashboardPlanStepsQuery = graphql(`
    query GetDashboardPlanSteps {
        dashboardPlanSteps {
            id
            stepType
            checked
            dueDate
        }
    }
`);

const UpsertDashboardPlanStepMutation = graphql(`
    mutation UpsertDashboardPlanStep(
        $id: String
        $stepType: DashboardPlanStepType!
        $dueDate: DateTime
        $checked: Boolean
    ) {
        upsertDashboardPlanStep(
            id: $id
            stepType: $stepType
            checked: $checked
            dueDate: $dueDate
        ) {
            id
            stepType
            checked
            dueDate
        }
    }
`);

export class GraphQLDashboardRepository {
    getPlanSteps = async () => {
        return graphqlClientKoyeb.request(
            GetDashboardPlanStepsQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    upsertPlanStep = async (
        payload: UpsertDashboardPlanStepMutationVariables,
    ) => {
        return graphqlClientKoyeb.request(
            UpsertDashboardPlanStepMutation,
            payload,
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
