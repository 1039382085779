import { GraphQLOrganizationRepository } from "@app/repositories/GraphQLRepositories/OrganizationRepository";
import { useMutation, useQuery } from "@tanstack/react-query";

const OrganizationRepository = new GraphQLOrganizationRepository();

export const orgCustomizationKeys = {
    all: ["orgCustomization"] as const,
};

export const useOrgCustomization = () =>
    useQuery({
        queryKey: orgCustomizationKeys.all,
        queryFn: OrganizationRepository.getOrg,
    });

export const useUpdateOrgCustomization = () =>
    useMutation({
        mutationFn: OrganizationRepository.updateOrgCustomization,
    });
