import { useAtomValue } from "jotai";
import { ExportSupplierDatapointsDocument } from "../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import ButtonRegular from "../../../../components/Button/ButtonRegular";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import { filterSupplierIdsAtom } from "../../../../components/Dropdown/dropdowns/Supplier/filter";
import { browserDownload } from "../../../../lib/utils-browser";

const Export = () => {
    const filterSupplierIds = useAtomValue(filterSupplierIdsAtom);

    const exportMutation = useGraphQLMutation(ExportSupplierDatapointsDocument);
    const exportData = (withFiles: boolean) => {
        exportMutation
            .mutateAsync({
                supplierIds: filterSupplierIds,
                withFiles,
            })
            .then((res) => {
                if (res.exportDatapoints.signedUrl) {
                    browserDownload(res?.exportDatapoints.signedUrl);
                }
            });
    };

    return (
        <div className="flex space-x-4">
            <ButtonRegular
                loading={exportMutation.isPending}
                variant={ButtonType.TEXT}
                onClick={() => {
                    exportData(true);
                }}
            >
                <span className="text-green">Export with files</span>
            </ButtonRegular>
            <ButtonRegular
                loading={exportMutation.isPending}
                variant={ButtonType.TEXT}
                onClick={() => {
                    exportData(false);
                }}
            >
                <span className="text-green">Export datapoints only</span>
            </ButtonRegular>
        </div>
    );
};

export default Export;
