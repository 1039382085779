import { atom } from "jotai";

export const searchAtom = atom<string | undefined>(undefined);

export const linkType = {
    Policy: "Policy",
    Measure: "Measure",
    Custom: "Custom",
    Csrd: "Csrd",
} as const;
export type LinkType = keyof typeof linkType;
export const selectedUsedInAtom = atom<(string | null)[]>([]);

export const fileSourceType = {
    Platform: "Platform",
    Uploaded: "Uploaded",
} as const;
export type FileSourceType = keyof typeof fileSourceType;
export const selectedFileSourceAtom = atom<(string | null)[]>([]);
