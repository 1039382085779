import { type FC } from "react";
import ErrorPage from "@app/shared/components/ErrorPage";
import { ReactComponent as RobotIllustration } from "@app/assets/illustrations/robot-broken.svg";
import "../i18n";
import { keys, RESOURCE_NAME } from "../i18n";
import { useTranslation } from "react-i18next";

export const AiErrorMessage: FC = () => {
    const { t } = useTranslation(RESOURCE_NAME);

    const reloadPage = () => window?.location.reload();

    return (
        <ErrorPage className="h-full w-full">
            <ErrorPage.Body>
                <ErrorPage.Image Image={RobotIllustration} />
                <ErrorPage.Text
                    text={t(keys.error_title)}
                    type="errorMessage"
                />
                <ErrorPage.Text
                    text={t(keys.error_description)}
                    type="errorDescription"
                />
                <ErrorPage.CTA onClick={reloadPage}>
                    {t(keys.error_cta)}
                </ErrorPage.CTA>
            </ErrorPage.Body>
        </ErrorPage>
    );
};
