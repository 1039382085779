import { StatusTagCircular } from "@design-system/DataDisplay/StatusTagCircular";
import Select from "@design-system/Inputs/Select";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ObjectiveStatus } from "@generated/client/graphql";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME, keys } from "./i18n";
import { displayStatus } from "@app/usecases/ObjectivesUseCases";
import { Icon } from "@design-system/Icon";

export const StatusTagSelect: FC<{
    status: ObjectiveStatus;
    setStatus: (status: ObjectiveStatus) => void;
}> = ({ status, setStatus }) => {
    const statusesToDisplay = [ObjectiveStatus.Draft, ObjectiveStatus.Defined];
    const filteredStatus = useMemo(
        () =>
            Object.values(ObjectiveStatus).filter((s) =>
                statusesToDisplay.includes(s),
            ) || [],
        [status],
    );

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <Select value={status || undefined} onValueChange={setStatus}>
            <Select.Trigger className="w-fit">
                <FlexRow
                    p="0.5"
                    br="xl"
                    elevation="b-100"
                    className="border border-neutral-200"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatusTagCircular
                        status={displayStatus(status)}
                        size="sm"
                        className="p-0.5"
                    />
                    <Text variant="body-sm" color="primary" className="px-1">
                        {t(keys.status, { context: status })}
                    </Text>
                </FlexRow>
            </Select.Trigger>
            <Select.Content>
                <Box className="max-h-[320px] overflow-auto">
                    {filteredStatus.map((st) => (
                        <Select.Item key={st} value={st} className="h-max">
                            <FlexRow
                                alignItems="center"
                                justifyContent="between"
                                className="w-full"
                            >
                                <FlexRow
                                    gap="2.5"
                                    alignItems="center"
                                    className="group"
                                >
                                    <StatusTagCircular
                                        status={displayStatus(st)}
                                        size="sm"
                                    />
                                    <Text
                                        variant="body"
                                        className="group-hover:text-green"
                                    >
                                        {t(keys.status, { context: st })}
                                    </Text>
                                </FlexRow>
                                {status === st && (
                                    <Icon
                                        name="check"
                                        size="sm"
                                        className="w-fit text-beavrGreen"
                                    />
                                )}
                            </FlexRow>
                        </Select.Item>
                    ))}
                </Box>
            </Select.Content>
        </Select>
    );
};
