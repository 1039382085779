import { useUpdateObjective } from "@app/screens/Objectives/data";
import { GetObjectiveQuery } from "@generated/client/graphql";
import { FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditableText } from "../EditableText";
import { RESOURCE_NAME, keys } from "./i18n";

type ObjectiveDescriptionProps = {
    objective: GetObjectiveQuery["objective"];
};

export const ObjectiveDescription: FC<ObjectiveDescriptionProps> = ({
    objective,
}) => {
    const [description, setDescription] = useState<string | undefined>(
        objective?.description || undefined,
    );
    const { mutate } = useUpdateObjective(objective?.id);

    const handleDescriptionSave = () => {
        if (description !== objective.description) {
            const newDescription = description || null;
            mutate({ id: objective.id, description: newDescription });
        }
    };

    const onInput = (event: FormEvent<HTMLDivElement>) => {
        setDescription(event.currentTarget.textContent || undefined);
    };

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <EditableText
            value={description}
            onInput={onInput}
            onBlur={handleDescriptionSave}
            placeholder={t(keys.description_placeholder)}
            variant="body"
        />
    );
};
