import { FC } from "react";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Box } from "@design-system/Layout/Box";
import { Button } from "@design-system/Inputs/Button";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";

type SidebarProps = {
    selectedYear: number;
    setSelectedYear: (year: number) => void;
};

export const Sidebar: FC<SidebarProps> = ({
    selectedYear,
    setSelectedYear,
}) => {
    //This is the earliest year that someone is using in reporting
    //2018 and 2019 are used only by test orgs
    const yearsFromNowTo2020 = Array.from(
        { length: new Date().getFullYear() - 2019 },
        (_, i) => new Date().getFullYear() - i,
    );
    const handleYearClick = (year: number) => () => {
        setSelectedYear(year);
    };

    return (
        <FlexCol className="w-[224px]">
            <Box p="4" pb="3">
                <Text variant="header3" color="primary">
                    Year
                </Text>
            </Box>
            <FlexCol px="2">
                {yearsFromNowTo2020.map((year) => (
                    <Button
                        variant="text"
                        size="sm"
                        className={cn(
                            "h-[36px] cursor-pointer p-2 text-primary hover:shadow-none justify-start",
                            selectedYear === year &&
                                "text-green bg-neutral-100 hover:bg-neutral-100",
                        )}
                        onClick={handleYearClick(year)}
                        active={selectedYear === year}
                        key={year}
                        asChild
                    >
                        <FlexRow gap="2.5" alignItems="center">
                            <Text variant="body">{year}</Text>
                        </FlexRow>
                    </Button>
                ))}
            </FlexCol>
        </FlexCol>
    );
};
