import { normalizeString } from "@app/utils/string";
import { SortingFn, compareBasic } from "@design-system/Table";
import { Row } from "@tanstack/react-table";

/**
 * Sorting function that sorts by the first tag name alphabetically.
 * It normalizes the tag name before comparing.
 */
export const firstTagAlphabetical: SortingFn<any> = (rowA, rowB) => {
    const tagsA = rowA.original?.tags ?? [];

    const tagsB = rowB.original?.tags ?? [];

    if (tagsA.length === 0 && tagsB.length === 0) {
        return 0;
    }
    if (tagsA.length === 0) {
        return 1;
    }
    if (tagsB.length === 0) {
        return -1;
    }
    return compareBasic(
        normalizeString(tagsA[0].name),
        normalizeString(tagsB[0].name),
    );
};

/**
 * Sorting function that sorts by the first tag name alphabetically.
 * It normalizes the tag name before comparing.
 */

export const makeStringSortingFn =
    <T>(lambda: (row: Row<T>) => string | undefined): SortingFn<T> =>
    (rowA: Row<T>, rowB: Row<T>) => {
        return compareBasic(
            normalizeString(lambda(rowA) ?? ""),
            normalizeString(lambda(rowB) ?? ""),
        );
    };

export const nameNormalized: SortingFn<any> = makeStringSortingFn(
    (row) => row.original.name,
);

export const makeFloatSortingFn =
    <T>(lambda: (row: Row<T>) => number | undefined): SortingFn<T> =>
    (rowA: Row<T>, rowB: Row<T>) => {
        return compareBasic(lambda(rowA) ?? 0, lambda(rowB) ?? 0);
    };

/**
 * Helper function to create a sorting function by user.
 * It sorts by the user's first name and last name.
 * It normalizes the user's name before comparing.
 *
 * IT IS NOT THE SORTING FUNCTION ITSELF, IT CANNOT BE PASSED
 * DIRECTLY TO THE `sortingFn` FIELD OF THE COLUMN DEFINITION,
 * JUST LIKE `compareBasic`. You need to first create the two
 * users you want to compare and then pass them to this function.
 */
export const compareUser = <
    User extends { firstName?: string; lastName?: string } | null | undefined,
>(
    userA: User,
    userB: User,
) => {
    const ownerA = (userA?.firstName ?? "") + (userA?.lastName ?? "");
    const ownerB = (userB?.firstName ?? "") + (userB?.lastName ?? "");

    return compareBasic(normalizeString(ownerA), normalizeString(ownerB));
};
