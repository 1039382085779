import {
    GetIndicators2Query,
    GetObjectiveQuery,
    UpdateObjectiveInput,
} from "@generated/client/graphql";

export function makeOptimisticObjectiveUpdate(
    indicators: GetIndicators2Query["indicators2"],
) {
    return function (
        patch: UpdateObjectiveInput,
        previousQuery: GetObjectiveQuery,
    ) {
        const updatedQuery: GetObjectiveQuery = {
            ...previousQuery,
            objective: {
                ...previousQuery.objective,
                ...(patch.themeId
                    ? {
                          themeId: patch.themeId,
                          subthemeId: undefined,
                          indicator: undefined,
                      }
                    : {}),
                ...(patch.subthemeId
                    ? { subthemeId: patch.subthemeId, indicator: undefined }
                    : {}),
                ...(patch.indicatorId
                    ? {
                          indicator: indicators?.find(
                              (indicator) => indicator.id === patch.indicatorId,
                          ),
                          indicatorId: patch.indicatorId,
                          subthemeId: indicators?.find(
                              (indicator) => indicator.id === patch.indicatorId,
                          )?.subthemev2?.id,
                      }
                    : {}),
                ...(patch.ownerId ? { ownerId: patch.ownerId } : {}),
                ...(patch.status ? { status: patch.status } : {}),
            },
        };
        return updatedQuery;
    };
}
