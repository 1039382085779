import i18next from "@app/i18n";

const enResources = {
    delete: "Delete",
    no_target: "No target defined",
    no_user: "Unassigned",
    default_title_placeholder: "Your objective title",
    status: "Unknown status",
    status_DRAFT: "Draft",
    status_DEFINED: "Defined",
    status_COMMITTED: "Committed",
    status_ARCHIVED: "Archived",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    delete: "Supprimer",
    no_target: "Aucune cible définie",
    no_user: "Non attribué",
    default_title_placeholder: "Votre objectif",
    status: "Statut inconnu",
    status_DRAFT: "Brouillon",
    status_DEFINED: "Défini",
    status_COMMITTED: "Commis",
    status_ARCHIVED: "Archivé",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "ObjectiveCard";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
