import i18next from "i18next";

const enResources = {
    title: "Select an indicator",
    subtitle:
        "Link one of your key performance metrics used to evaluate and track progress",
    cancel: "Cancel",
    assign: "Assign",
    indicators: "Indicators",
    unit: "Unit",
    subtheme: "Subtheme",
    update_success: "Objective updated",
    update_error: "Error updating objective",
} as const;

type ResourceKeys = keyof typeof enResources;

const frResources = {
    title: "Sélectionner un indicateur",
    subtitle:
        "Liez l'un de vos indicateurs clés utilisés pour évaluer et suivre la progression",
    cancel: "Annuler",
    assign: "Assigner",
    indicators: "Indicateurs",
    unit: "Unité",
    subtheme: "Sous-thème",
    update_success: "Objectif mis à jour",
    update_error: "Erreur lors de la mise à jour de l'objectif",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourceKeys, ResourceKeys>;
export const RESOURCE_NAME = "IndicatorSelectModal";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
