import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { FlexCol } from "@design-system/Layout/Flex";

export const ObjectivesPageSidebarSkeleton = () => {
    return (
        <FlexCol
            gap="4"
            justifyContent="center"
            alignItems="start"
            className="w-[240px]"
        >
            <Skeleton h="4" w="full" br="lg" className="my-[7px]" />
            <Skeleton h="4" w="full" br="lg" my="2" className="w-[210px]" />

            <FlexCol alignItems="start" gap="0.5" w="full">
                <Skeleton h="2" w="12" br="lg" my="2.5" />
                <Skeleton h="4" w="20" br="lg" my="2" />
                <Skeleton h="4" w="28" br="lg" my="2" />
                <Skeleton h="4" w="20" br="lg" my="2" />
                <Skeleton h="4" w="20" br="lg" my="2" />
                <Skeleton h="4" w="28" br="lg" my="2" />
                <Skeleton h="4" w="20" br="lg" my="2" />
                <Skeleton h="4" w="28" br="lg" my="2" />
            </FlexCol>
        </FlexCol>
    );
};
