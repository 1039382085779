import UserSelect from "@app/components/Dropdown/dropdowns/User";
import { StatusTagCircular } from "@design-system/DataDisplay/StatusTagCircular";
import { Icon } from "@design-system/Icon";
import { DropdownMenu } from "@design-system/Inputs/DropdownMenu";
import { Switch } from "@design-system/Inputs/Switch";
import { Textarea } from "@design-system/Inputs/Textarea";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import {
    BooleanEnum,
    CsrdDatapointPatch,
    CsrdDatapointStatus,
    CsrdDatapointType,
    MaterialityStatus,
    ValidationStatus,
} from "@generated/client/graphql";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { RefObject, useCallback, type FC } from "react";
import { useTranslation } from "react-i18next";

import { FileIcon } from "@app/shared/components/FileIcon";
import { useDebouncedStableCallback } from "@app/shared/utils/debounce";
import {
    useConditionalScrollTo,
    useOnScrollIntoView,
} from "@app/shared/utils/scroll";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { intervalToDuration } from "date-fns";
import { CsrdDatapoint } from "../../../types";
import {
    activeDatapointIdAtom,
    activeDisclosureRequirementIdAtom,
    documentModalActiveDatapoint,
    lastUrlUpdateByClickTimestampAtom,
    openMaterialityModalAtom,
    setScrollAnchorAtom,
} from "../../ctx";
import { PillarDatapoint } from "../../types";
import { Prefill } from "../Prefill";
import { DatapointValueInput } from "./DatapointValueInput";
import "./i18n";
import { UseDatapointStateType, useDatapoint } from "./useDatapoint";

export const DatapointForm: FC<{
    datapoint: PillarDatapoint;
    pillarBodyContainerRef?: RefObject<HTMLElement>;
}> = ({ datapoint, pillarBodyContainerRef }) => {
    const { t } = useTranslation("FrameworkDatapoint");
    const { datapointState: state, handleChange } = useDatapoint(datapoint);

    const selectedDatapointId = useAtomValue(activeDatapointIdAtom);

    const selectedDisclosureRequirementId = useAtomValue(
        activeDisclosureRequirementIdAtom,
    );

    const [lastUrlUpdateByClickTimestamp, setLastUrlUpdateByClickTimestamp] =
        useAtom(lastUrlUpdateByClickTimestampAtom);

    const scrollToDatapointRef = useConditionalScrollTo(
        selectedDatapointId === datapoint.cmsId &&
            lastUrlUpdateByClickTimestamp &&
            (intervalToDuration({
                start: lastUrlUpdateByClickTimestamp,
                end: new Date(),
            }).seconds ?? 0) <= 3,
    );

    const setNavigationAtom = useSetAtom(setScrollAnchorAtom);

    const scrollIntoViewCb = useCallback(() => {
        if (
            lastUrlUpdateByClickTimestamp &&
            (selectedDisclosureRequirementId === datapoint.drId ||
                !selectedDisclosureRequirementId)
        ) {
            setLastUrlUpdateByClickTimestamp(undefined);
        }
        if (
            (!lastUrlUpdateByClickTimestamp ||
                (intervalToDuration({
                    start: lastUrlUpdateByClickTimestamp,
                    end: new Date(),
                }).seconds ?? 0) >= 3) &&
            selectedDisclosureRequirementId !== datapoint.drId
        ) {
            setNavigationAtom({
                drId: datapoint.drId,
            });
        }
    }, [
        lastUrlUpdateByClickTimestamp,
        selectedDisclosureRequirementId,
        datapoint.cmsId,
    ]);

    const scrollIntoViewRef = useOnScrollIntoView(
        scrollIntoViewCb,
        pillarBodyContainerRef?.current,
    );

    const handleOwnerChange = (userId: string | undefined) => {
        handleChange({ ownerId: userId ?? null });
    };
    const handleValidationChange = (validated: boolean) => {
        handleChange({
            status: validated
                ? ValidationStatus.Validated
                : ValidationStatus.Notvalidated,
        });
    };

    const debounchedUpdateComment = useDebouncedStableCallback((comment) => {
        handleChange({ comment: comment });
    }, 300);

    const handleCommentChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        state.setComment(event.target.value);
        state.setCharCount(event.target.value.length);
        debounchedUpdateComment(event.target.value);
        if (
            datapoint.type === CsrdDatapointType.Narrative &&
            !event.target.value
        ) {
            state.setCommentError(t("required_comment_error"));
        } else {
            state.setCommentError(undefined);
        }
    };

    const setDocumentModalDatapoint = useSetAtom(documentModalActiveDatapoint);
    const handleAddDocumentClick = (datapoint: PillarDatapoint) => {
        return () => {
            setDocumentModalDatapoint(datapoint);
        };
    };

    const isNotMaterial = datapoint.status === CsrdDatapointStatus.NotMaterial;
    const materialityComment = datapoint?.materiality?.comment ?? "";
    const commentPlaceholder = isNotMaterial
        ? t("materiality_comment")
        : t("comment", {
              charCount: state.charCount,
          });
    const commentValue = isNotMaterial ? materialityComment : state.comment;
    const handleClickOnCollapsedDatapoint = () => {
        state.setIsCollapsed(!state.isCollapsed);
        setLastUrlUpdateByClickTimestamp(new Date());
        setNavigationAtom({
            drId: datapoint.drId,
            dpId: datapoint.cmsId,
        });
    };

    const handleRefs = (el: HTMLElement | null) => {
        scrollToDatapointRef(el);
        scrollIntoViewRef.current = el;
    };

    const handleDeleteDocumentClick = (fileId: string) => {
        return () => {
            handleChange({ disconnectEvidenceFileIds: [fileId] });
        };
    };

    return (
        <Box w="full" ref={handleRefs}>
            {state.isCollapsed ? (
                <FlexRow
                    px="4"
                    alignItems="start"
                    gap="4"
                    alignSelf="stretch"
                    className={cn(
                        "border-b border-tertiary min-h-20",
                        isNotMaterial && "bg-neutral-100 hover:bg-secondary",
                    )}
                >
                    <Box py="5">
                        <StatusTagCircular status={state.status} size="sm" />
                    </Box>
                    <FlexCol
                        w="full"
                        h="full"
                        py="4"
                        className=" cursor-pointer"
                        onClick={handleClickOnCollapsedDatapoint}
                    >
                        <Text variant="body-lg-bold">{datapoint?.name}</Text>
                    </FlexCol>
                    <Box py="4">
                        <ActionDropdown
                            datapoint={datapoint}
                            datapointState={state}
                            handleChange={handleChange}
                        />
                    </Box>
                </FlexRow>
            ) : (
                <FlexRow
                    gap="4"
                    px="4"
                    alignItems="start"
                    w="full"
                    className={cn(
                        "flex-1 border-b border-tertiary",
                        isNotMaterial && "bg-secondary",
                    )}
                >
                    <Box py="5">
                        <StatusTagCircular status={state.status} size="sm" />
                    </Box>

                    <FlexCol gap="4" alignItems="start" w="full" py="4">
                        <FlexCol
                            w="full"
                            justifyContent="start"
                            className="cursor-pointer"
                            onClick={() =>
                                state.setIsCollapsed(!state.isCollapsed)
                            }
                        >
                            <Text variant="body-lg-bold">
                                {datapoint?.name}
                            </Text>
                        </FlexCol>
                        {isNotMaterial ? null : (
                            <FlexRow alignItems="center" gap="6">
                                <Text variant="body-sm" color="primary">
                                    {datapoint.mandatory === BooleanEnum.True
                                        ? t("mandatory")
                                        : t("voluntary")}
                                </Text>
                                {datapoint.phaseInAll === 0 &&
                                    datapoint.phaseInLess750 > 0 && (
                                        <Text variant="body-sm" color="primary">
                                            {`Phase-in: ${t(
                                                "phaseInLessThan750",
                                                {
                                                    count: datapoint.phaseInLess750,
                                                },
                                            )}`}
                                        </Text>
                                    )}
                                {datapoint.phaseInAll > 0 &&
                                    datapoint.phaseInLess750 === 0 && (
                                        <Text variant="body-sm" color="primary">
                                            {`Phase-in: ${t("phaseInYears", {
                                                count: datapoint.phaseInAll,
                                            })}`}
                                        </Text>
                                    )}
                                {datapoint.phaseInAll > 0 &&
                                    datapoint.phaseInLess750 > 0 &&
                                    (datapoint.phaseInAll ===
                                    datapoint.phaseInLess750 ? (
                                        <Text variant="body-sm" color="primary">
                                            {`Phase-in: ${t("phaseInYears", {
                                                count: datapoint.phaseInAll,
                                            })}`}
                                        </Text>
                                    ) : (
                                        <Text variant="body-sm" color="primary">
                                            {`Phase-in: ${t("phaseInYears", {
                                                count: datapoint.phaseInAll,
                                            })} (${t("phaseInLessThan750", {
                                                count: datapoint.phaseInLess750,
                                            })})`}
                                        </Text>
                                    ))}
                            </FlexRow>
                        )}
                        <FlexRow
                            className="h-[28px]"
                            alignItems="center"
                            justifyContent="between"
                            w="full"
                        >
                            <FlexRow className="gap-[6px]" alignItems="center">
                                <Text variant="body-sm" color="secondary">
                                    {t("owner")}
                                </Text>
                                <UserSelect
                                    selectedUserId={state.ownerId ?? undefined}
                                    onChange={handleOwnerChange}
                                />
                            </FlexRow>
                            <Prefill
                                datapointId={datapoint.id}
                                hasIndicator={!!datapoint.indicatorCmsId}
                                onConfirm={handleChange}
                            />
                        </FlexRow>
                        {!isNotMaterial && (
                            <DatapointValueInput
                                datapoint={datapoint}
                                state={state}
                                handleChange={handleChange}
                            />
                        )}
                        <FlexRow w="full" className="relative">
                            <Textarea
                                placeholder={commentPlaceholder}
                                value={commentValue}
                                onChange={handleCommentChange}
                                className="w-full"
                                disabled={
                                    datapoint?.validationStatus ===
                                        ValidationStatus.Validated ||
                                    isNotMaterial
                                }
                                error={state.commentError}
                            />
                            {state.commentIsUpdating !== undefined && (
                                <Icon
                                    name={
                                        state.commentIsUpdating
                                            ? "circleLoading"
                                            : "check"
                                    }
                                    className={cn(
                                        "absolute right-2 top-2",
                                        state.commentIsUpdating
                                            ? "text-secondary"
                                            : "text-beavrGreen",
                                    )}
                                    size="sm"
                                />
                            )}
                        </FlexRow>
                        {!(
                            datapoint?.validationStatus ===
                                ValidationStatus.Validated || isNotMaterial
                        ) && (
                            <FlexRow
                                alignItems="center"
                                gap="1"
                                className="cursor-pointer"
                                onClick={handleAddDocumentClick(datapoint)}
                            >
                                <Text variant="body-bold" color="brand">
                                    {t("add_documents")}
                                </Text>
                                <Icon
                                    name="circlePlus"
                                    className="text-brand_hover"
                                    size="sm"
                                ></Icon>
                            </FlexRow>
                        )}
                        {datapoint?.evidenceFiles?.map((file) => (
                            <FlexRow
                                alignItems="center"
                                justifyContent="between"
                                gap="3"
                                alignSelf="stretch"
                                key={file.id}
                            >
                                <a
                                    href={file?.signedUrl || undefined}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="hover:no-underline text-inherit"
                                >
                                    <FlexRow
                                        alignItems="center"
                                        gap="3"
                                        alignSelf="stretch"
                                    >
                                        <FileIcon
                                            mimeType={file.mimeType || ""}
                                        />
                                        <Text variant="body-bold" color="brand">
                                            {(!!file.documentVersions?.length &&
                                                file.documentVersions[0]
                                                    ?.document?.name) ||
                                                file.name}
                                        </Text>
                                    </FlexRow>
                                </a>
                                <Button
                                    size="sm"
                                    variant="tonal"
                                    onClick={handleDeleteDocumentClick(file.id)}
                                    // onClick={handleClick}
                                >
                                    <Icon name="trash" size="sm" />
                                </Button>
                            </FlexRow>
                        ))}
                        <Switch
                            checked={state.validated}
                            onChange={handleValidationChange}
                            label={t("validate")}
                            labelPosition="right"
                            disabled={
                                !!state.valueError || !!state.commentError
                            }
                        />
                    </FlexCol>
                    <Box py="4">
                        <ActionDropdown
                            datapoint={datapoint}
                            datapointState={state}
                            handleChange={handleChange}
                        />
                    </Box>
                </FlexRow>
            )}
        </Box>
    );
};

const ActionDropdown: FC<{
    datapoint: CsrdDatapoint;
    datapointState: UseDatapointStateType;
    handleChange: (patch: CsrdDatapointPatch) => void;
}> = ({ datapoint, datapointState, handleChange }) => {
    const { t } = useTranslation("FrameworkDatapoint");
    const openMaterialityModal = useSetAtom(openMaterialityModalAtom);

    const handleMaterialityModalSelect = () => {
        openMaterialityModal(
            datapoint?.materiality?.status as MaterialityStatus,
            datapoint?.materiality?.comment || "",
            datapoint?.id,
            undefined,
            datapoint?.name,
        );
    };

    const handleNoDataChange = (_: Event) => {
        datapointState.setValueNoData(!datapointState.valueNoData);
        handleChange({
            value: {
                noData:
                    datapointState.valueNoData === true
                        ? BooleanEnum.False
                        : datapointState.valueNoData === false
                          ? BooleanEnum.True
                          : BooleanEnum.Unknown,
            },
        });
    };

    return (
        <DropdownMenu modal={false}>
            <DropdownMenu.Trigger>
                <Icon name="more" />
            </DropdownMenu.Trigger>
            <DropdownMenu.Content align="end">
                {
                    <Tooltip delayDuration={0}>
                        <Tooltip.Trigger>
                            <DropdownMenu.Item
                                onSelect={handleNoDataChange}
                                disabled={
                                    datapoint?.mandatory === BooleanEnum.True
                                }
                            >
                                <DropdownMenu.ItemIcon
                                    name={
                                        datapointState.valueNoData
                                            ? "edit"
                                            : "editCrossed"
                                    }
                                />

                                <FlexRow w="full">
                                    <Text
                                        variant="body"
                                        color={
                                            datapoint?.mandatory ===
                                            BooleanEnum.True
                                                ? "disabled"
                                                : "dark"
                                        }
                                    >
                                        {datapointState.valueNoData
                                            ? t("data_available")
                                            : t("no_data")}
                                    </Text>
                                </FlexRow>
                            </DropdownMenu.Item>
                        </Tooltip.Trigger>
                        {datapoint?.mandatory === BooleanEnum.True && (
                            <Tooltip.Content>
                                {t("data_is_required")}
                            </Tooltip.Content>
                        )}
                    </Tooltip>
                }
                <DropdownMenu.Item onSelect={handleMaterialityModalSelect}>
                    <DropdownMenu.ItemIcon name="eyeOff" />
                    <FlexRow alignItems="center">
                        <Text variant="body" color="dark">
                            {t("manage_materiality")}
                        </Text>
                    </FlexRow>
                </DropdownMenu.Item>
            </DropdownMenu.Content>
        </DropdownMenu>
    );
};
