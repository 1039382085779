import StatusBlock from "@app/components/TipTap/components/menus/StatusBlock";
import { Icon } from "@design-system/Icon";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { DocumentVersionStatus } from "@generated/client/graphql";
import { EditorContent } from "@tiptap/react";
import { FC, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import FooterLayout from "../../../../components/Layouts/FullFrame/FooterLayout";
import ImageBlockMenu from "../../../../components/TipTap/extensions/ImageBlock/components/ImageBlockMenu";
import {
    TableColumnMenu,
    TableRowMenu,
} from "../../../../components/TipTap/extensions/Table/menus";
import "../../../../components/TipTap/styles/index.css";
import { EditorMode, useEditorContext } from "../hooks/useEditor";
import { PDFViewer } from "./PDFViewer";

const BottomLeftBlock = () => {
    const { collabState, editor, editable, editorMode, isPdf, version } =
        useEditorContext();
    const { t } = useTranslation("EditDocument");
    const menuContainerRef = useRef<HTMLDivElement | null>(null);
    const canShowEditor = editor && version?.withEditor;

    const downloadDocument = () => {
        version?.finalFile?.signedUrl &&
            window.open(version?.finalFile?.signedUrl, "_blank");
    };

    const showBanner =
        version?.status === DocumentVersionStatus.PendingBeavrReview ||
        editorMode === EditorMode.HISTORY ||
        version?.status === DocumentVersionStatus.Approved;
    const bannerText =
        version?.status === DocumentVersionStatus.Approved
            ? t("approved_version_banner")
            : version?.status === DocumentVersionStatus.PendingBeavrReview
              ? t("pending_review_warning")
              : t("historic_version_warning");

    if (isPdf && !version?.withEditor) {
        return (
            <FlexCol className="h-full">
                <Banner
                    show={version?.status === DocumentVersionStatus.Approved}
                    variant="approved"
                    text={t("approved_version_banner")}
                />
                {version?.finalFile && (
                    <PDFViewer
                        name={version.finalFile.name ?? ""}
                        url={version.finalFile.signedUrl ?? undefined}
                    />
                )}
                <FooterLayout />
            </FlexCol>
        );
    }

    if (canShowEditor) {
        return (
            <FlexCol className="min-h-0 flex-shrink flex-grow">
                <div
                    className="min-h-0 flex-grow flex-shrink flex flex-col"
                    ref={menuContainerRef}
                >
                    <>
                        <TableRowMenu
                            editor={editor}
                            appendTo={menuContainerRef}
                        />

                        <TableColumnMenu
                            editor={editor}
                            appendTo={menuContainerRef}
                        />
                        <ImageBlockMenu
                            editor={editor}
                            appendTo={menuContainerRef}
                        />
                    </>

                    <div className="min-h-0 flex-shrink flex-grow flex">
                        <div
                            className={cn(
                                "flex-grow h-full flex flex-col ",
                                editable ? "bg-secondary" : "bg-tertiary",
                            )}
                        >
                            <Banner
                                show={showBanner}
                                variant={
                                    version?.status ===
                                    DocumentVersionStatus.Approved
                                        ? "approved"
                                        : "warn"
                                }
                                text={bannerText}
                            />
                            <FlexRow
                                className={
                                    editable ? "bg-secondary" : "bg-tertiary"
                                }
                                justifyContent="end"
                                pt="2"
                                w="full"
                            >
                                <StatusBlock collabState={collabState} />
                            </FlexRow>
                            <div
                                className={cn(
                                    "min-h-0 flex-shrink flex-grow p-8 flex flex-col items-center overflow-auto scrollbar-hide ",
                                    editable ? "bg-secondary" : "bg-tertiary",
                                )}
                            >
                                <div
                                    className=" w-[45rem] p-8 bg-white"
                                    id="content"
                                >
                                    <EditorContent
                                        editor={editor}
                                        disabled={!editable}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterLayout />
            </FlexCol>
        );
    }

    return (
        <FlexCol w="full" h="full">
            <Banner
                show={version?.status === DocumentVersionStatus.Approved}
                variant="approved"
                text={t("approved_version_banner")}
            />
            <FlexCol
                alignItems="center"
                className="bg-neutral-500"
                h="full"
                w="full"
                pt="9"
            >
                <FlexCol
                    className="bg-neutral-600"
                    alignItems="center"
                    gap="2"
                    p="6"
                    h="fit"
                    w="fit"
                >
                    <Text
                        as="h2"
                        variant="body-sm-bold"
                        className="text-white text-center"
                    >
                        <Trans t={t} i18nKey={"no_preview"} />
                    </Text>
                    <a
                        className="inline-flex text-brand-400 text-sm font-bold gap-1 justify-center items-center cursor-pointer"
                        onClick={downloadDocument}
                    >
                        {t("download_document")}
                        <Icon size="sm" name="download" />
                    </a>
                </FlexCol>
            </FlexCol>
        </FlexCol>
    );
};

export default BottomLeftBlock;

const Banner: FC<{
    show: boolean;
    variant: "approved" | "warn";
    text: string;
}> = ({ show, variant, text }) => {
    return show ? (
        <FlexRow
            justifyContent="center"
            alignItems="center"
            alignSelf="stretch"
            px="3"
            py="1.5"
            h="8"
            className={cn(
                variant === "approved" ? "bg-beavrGreen" : "bg-warning-500",
            )}
        >
            <Text
                variant="body-sm-bold"
                color={variant === "approved" ? "white" : "dark"}
            >
                {text}
            </Text>
        </FlexRow>
    ) : null;
};
