import {
    RegularTabs,
    useEditorContext,
} from "@app/screens/Document/Edit/hooks/useEditor";
import {
    approveAtom,
    editValidationTypeAtom,
    rejectDocumentVersionAtom,
    sendForReviewAtom,
    versionChecksRatioAtom,
} from "@app/store/versionStore";
import { Icon } from "@design-system/Icon";
import { DropdownMenu } from "@design-system/Inputs/DropdownMenu";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { useAtomValue, useSetAtom } from "jotai/react";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { keys } from "./i18n";

import { browserDownload } from "@app/lib/utils-browser";
import {
    ValidationModal,
    validationModalAtom,
} from "@app/screens/Document/Edit/ctx";
import { usePDFGenerator } from "@app/screens/Document/Edit/hooks";
import { currentUserRoleAtom } from "@app/store/userStore";
import { EditValidationType } from "@app/usecases/VersionUseCases";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { Text } from "@design-system/Typography/Text";
import { DocumentVersion, UserRole } from "@generated/client/graphql";
import { useUnsendReviewRequest } from "../../../screens/Document/Edit/data";
import "./i18n";

export const ValidationButton: FC = () => {
    const { t } = useTranslation("VersionValidation");
    const setModal = useSetAtom(validationModalAtom);
    const { setRegularModeActiveTab, version } = useEditorContext();
    const {
        data: { ratio },
        isPending: isRatioPending,
    } = useAtomValue(versionChecksRatioAtom);

    const validationType = useAtomValue(editValidationTypeAtom);

    const { role } = useAtomValue(currentUserRoleAtom);

    const { isPending: isRequestForApprovalPending } =
        useAtomValue(sendForReviewAtom);

    const { mutate: rejectMutation, isPending: isRejectRequestPending } =
        useAtomValue(rejectDocumentVersionAtom);
    const reject = () => rejectMutation();

    const { isPending: isUnsendReviewRequestPending } =
        useUnsendReviewRequest();

    const { mutate: approveMutation, isPending: isApproveMutationPending } =
        useAtomValue(approveAtom);
    const approve = () => approveMutation();

    const openChecklist = () => setRegularModeActiveTab(RegularTabs.CHECKS);

    const handleSendForReviewClick = () => {
        setModal(ValidationModal.sendForReview);
    };

    const handleUnsendReviewRequestClick = () => {
        setModal(ValidationModal.unsendReviewRequest);
    };

    const isLoading =
        isRatioPending ||
        isRequestForApprovalPending ||
        isRejectRequestPending ||
        isApproveMutationPending ||
        isUnsendReviewRequestPending;

    const { generatePdf, isPending } = usePDFGenerator(
        version as DocumentVersion,
    );

    const downloadFile = () => {
        if (!!version?.withEditor) {
            generatePdf();
        } else {
            if (!version?.finalFile?.signedUrl) return;
            browserDownload(version?.finalFile?.signedUrl, true);
        }
    };

    return validationType === EditValidationType.SendForReview ? (
        <FlexRow w="full" br="lg" className="border border-secondary">
            {ratio.num === 1 ? (
                <Button
                    size="sm"
                    loading={isLoading}
                    className="w-full rounded-none "
                    onClick={handleSendForReviewClick}
                >
                    {t(keys.send_for_review, {
                        count: ratio.total,
                        ratio: ratio.str,
                    })}
                </Button>
            ) : (
                <Tooltip delayDuration={0}>
                    <Tooltip.Trigger asChild>
                        <Button
                            className="border-none rounded-none w-full"
                            loading={isLoading}
                            onClick={openChecklist}
                            size="sm"
                            variant="plain"
                        >
                            {t(keys.send_for_review, {
                                count: ratio.total,
                                ratio: ratio.str,
                            })}
                        </Button>
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                        {t(keys.send_for_review_tooltip)}
                    </Tooltip.Content>
                </Tooltip>
            )}

            <DropdownMenu>
                <DropdownMenu.Trigger asChild>
                    <Button
                        className="[&>div]:p-0 border-l border-l-secondary rounded-none"
                        loading={isLoading}
                        size="sm"
                        variant="text"
                    >
                        <Icon name="angleDown" />
                    </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content align="end" side="bottom" sideOffset={10}>
                    <DropdownMenu.Item onSelect={approve}>
                        <DropdownMenu.ItemIcon
                            name="warning"
                            className="text-warning-500"
                        />
                        {t(keys.force_approve)}
                    </DropdownMenu.Item>
                    {role === UserRole.BeavrAdmin && (
                        <DropdownMenu.Item onSelect={reject}>
                            <DropdownMenu.ItemIcon
                                name="close"
                                className="text-danger"
                            />
                            {t(keys.reject)}
                        </DropdownMenu.Item>
                    )}
                </DropdownMenu.Content>
            </DropdownMenu>
        </FlexRow>
    ) : validationType === EditValidationType.PendingCsReview ? (
        <FlexRow
            w="full"
            br="lg"
            className="overflow-hidden border border-secondary"
        >
            <Box h="full" w="full">
                <Button
                    className="w-full"
                    size="sm"
                    variant="outlinedDanger"
                    onClick={handleUnsendReviewRequestClick}
                >
                    <Text variant="body-sm-bold">
                        {t(keys.cancel_review_request)}
                    </Text>
                    <Icon name="close" size="sm" />
                </Button>
            </Box>
        </FlexRow>
    ) : validationType === EditValidationType.Approved ? (
        <FlexCol
            p="1"
            w="full"
            h="fit"
            alignItems="start"
            gap="0.5"
            alignSelf="stretch"
            className="border border-brand_secondary rounded-lg bg-secondary"
        >
            <FlexRow
                p="1"
                justifyContent="center"
                alignSelf="stretch"
                w="full"
                gap="0.5"
            >
                <Text variant="body-sm-bold">{t(keys.approved)}</Text>
                <Icon
                    name="doubleCheck"
                    size="sm"
                    className="text-beavrGreen"
                ></Icon>
            </FlexRow>
            <Button
                size="sm"
                className="w-full"
                onClick={downloadFile}
                loading={isPending}
            >
                <Text variant="body-sm-bold" color="white">
                    {t(keys.download)}
                </Text>
                <Icon name="download" size="sm" className="text-white"></Icon>
            </Button>
        </FlexCol>
    ) : validationType === EditValidationType.CsReview ? (
        <FlexRow
            w="full"
            br="lg"
            className="overflow-hidden border border-secondary"
        >
            <Button
                className="border-none rounded-none w-full"
                disabled={ratio.num != 1}
                loading={isLoading}
                onClick={approve}
                size="sm"
                variant="plain"
            >
                {t(keys.approve)}
            </Button>
            <DropdownMenu>
                <DropdownMenu.Trigger asChild>
                    <Button
                        className="[&>div]:p-0 border-l border-l-secondary rounded-none"
                        loading={isLoading}
                        size="sm"
                        variant="text"
                    >
                        <Icon name="angleDown" />
                    </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content
                    hasPortal
                    align="end"
                    side="bottom"
                    sideOffset={10}
                >
                    {ratio.num < 1 && (
                        <DropdownMenu.Item onSelect={approve}>
                            <DropdownMenu.ItemIcon
                                name="cloudWithValidate"
                                className="text-warning-500"
                            />
                            {t(keys.force_approve)}
                        </DropdownMenu.Item>
                    )}
                    {role === UserRole.BeavrAdmin && (
                        <DropdownMenu.Item onSelect={reject}>
                            <DropdownMenu.ItemIcon
                                name="close"
                                className="text-danger"
                            />
                            {t(keys.reject)}
                        </DropdownMenu.Item>
                    )}
                </DropdownMenu.Content>
            </DropdownMenu>
        </FlexRow>
    ) : validationType === EditValidationType.ApproveDirectly ? (
        <Button
            loading={isLoading}
            size="sm"
            className="w-full"
            onClick={approve}
        >
            {t(keys.approve)}
        </Button>
    ) : (
        <></>
    );
};
