import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
    DocumentVersion,
    DocumentVersionStatus,
    SaveTemplateDocument,
} from "../../../../../generated/client/graphql";

import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ClockRotateRight } from "iconoir-react";
import { Copy, Download } from "lucide-react";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import { DropdownTrigger } from "../../../../components/Dropdown/components";
import BaseItem from "../../../../components/Dropdown/components/dropdowns/items/BaseItem";
import DropdownContentLayout from "../../../../components/Dropdown/components/dropdowns/layout";
import HeaderLayout from "../../../../components/Layouts/FullFrame/HeaderLayout";
import { usePDFGenerator, useSaveDocumentData } from "../hooks";
import { EditorMode, useEditorContext } from "../hooks/useEditor";

export const TEMPLATE_FR_UNAME = "template_fr";
export const TEMPLATE_EN_UNAME = "template_en";

const Header = () => {
    const { t } = useTranslation("EditDocument");
    const { saveDocumentData } = useSaveDocumentData();
    const navigate = useNavigate();
    const { version, editor, editorMode, setEditorMode } = useEditorContext();
    const { generatePdf } = usePDFGenerator(version as DocumentVersion);

    const { org_uname } = useParams();
    const displaySaveTemplate =
        org_uname === TEMPLATE_FR_UNAME || org_uname === TEMPLATE_EN_UNAME;
    const { mutate: saveTemplate } = useGraphQLMutation(SaveTemplateDocument);

    const handleSaveAndCloseClick = async () => {
        await saveDocumentData();
        navigate("../..");
    };

    const handleCloseClick = () => {
        navigate("../..");
    };

    const handleSetHistoryMode = () => {
        saveDocumentData();
        if (editor) editor.setOptions({ editable: false });
        setEditorMode(EditorMode.HISTORY);
    };
    const handleSetRegularMode = () => {
        setEditorMode(EditorMode.REGULAR);
        if (!editor) return;
        editor.setOptions({ editable: true });
        version?.checkpoints &&
            version?.checkpoints?.length > 0 &&
            editor.commands.setContent(version?.checkpoints[0]?.data || "{}");
    };

    return (
        <HeaderLayout title={version?.document?.name || ""}>
            {editorMode !== EditorMode.HISTORY ? (
                <FlexRow gap="3">
                    {version?.withEditor && (
                        <DropdownMenu modal={false}>
                            <DropdownTrigger asChild>
                                <Button variant="outlined" size="sm">
                                    <Text variant="body-sm-bold">
                                        {t("more")}
                                    </Text>
                                    <Icon name="angleDown" size="sm"></Icon>
                                </Button>
                            </DropdownTrigger>
                            <DropdownContentLayout
                                align="end"
                                className="w-full"
                            >
                                <BaseItem
                                    onClick={handleSetHistoryMode}
                                    IconLeft={ClockRotateRight}
                                >
                                    {t("view_history")}
                                </BaseItem>
                                <BaseItem
                                    onClick={generatePdf}
                                    IconLeft={Download}
                                >
                                    {t("pdf_generate")}
                                </BaseItem>
                                {displaySaveTemplate && (
                                    <BaseItem
                                        className="w-full"
                                        onClick={() =>
                                            version?.document?.cmsDocumentId &&
                                            saveTemplate({
                                                cmsId: version?.document
                                                    ?.cmsDocumentId,
                                            })
                                        }
                                        IconLeft={Copy}
                                    >
                                        {t("save_template_to_cms")}
                                    </BaseItem>
                                )}
                            </DropdownContentLayout>
                        </DropdownMenu>
                    )}
                    {version?.status === DocumentVersionStatus.Approved ? (
                        <Button
                            onClick={handleCloseClick}
                            variant="tonal"
                            size="sm"
                        >
                            <Text variant="body-sm-bold">{t("close")}</Text>
                            <Icon name="close" size="sm"></Icon>
                        </Button>
                    ) : (
                        <Button
                            onClick={handleSaveAndCloseClick}
                            variant="flatPrimary"
                            size="sm"
                        >
                            {t("save_and_close")}
                        </Button>
                    )}
                </FlexRow>
            ) : (
                <FlexRow>
                    <Button
                        size="sm"
                        onClick={handleSetRegularMode}
                        variant="tonal"
                    >
                        <Text variant="body-sm-bold">
                            {t("close_historic_mode")}
                        </Text>
                        <Icon name="close" size="sm"></Icon>
                    </Button>
                </FlexRow>
            )}
        </HeaderLayout>
    );
};

export default Header;
