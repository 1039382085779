import i18next from "../../i18n";

i18next.addResourceBundle("en", "Navbar", {
    dashboard: "Dashboard",
    frameworks: "Frameworks",
    requirements: "Requirements",
    sustainability_center: "Sustainability center",

    proof_system: "Proof system",
    policies: "Policies",
    objectives: "Objectives",
    measures: "Measures",
    custom_documents: "Custom documents",
    all_files: "My files",

    reporting: "Reporting",
    indicators: "Indicators",
    campaigns: "Campaigns",
    raw_data: "Raw data",
    documents: "Documents",
    reporting_overview: "Reporting",
    reporting_progress: "Progress",

    supplier: "Suppliers",
    suppliers: "Suppliers",
    supplier_datapoints: "Datapoints",

    questionnaires: "Questionnaires",
    settings: "Settings",
    setting_general: "General",
    setting_material_topics: "Material topics",
    setting_sites: "Entities",
    setting_users: "Users",
    setting_units: "Units",

    organizations: "Organizations",
    count_members_other: "{{count}} Members",
    count_members_one: "{{count}} Member",
});

i18next.addResourceBundle("fr", "Navbar", {
    dashboard: "Tableau de bord",
    requirements: "Contrôles",
    frameworks: "Référentiels",
    sustainability_center: "Centre de conformité",

    proof_system: "Système de preuve",
    policies: "Politiques",
    objectives: "Objectifs",
    measures: "Mesures",
    custom_documents: "Documents spécifiques",
    all_files: "Mes fichiers",

    reporting: "Reporting",
    campaigns: "Campagnes",
    raw_data: "Données brutes",
    indicators: "Indicateurs",
    documents: "Bibliothèque",
    reporting_overview: "Reporting",
    reporting_progress: "Progression",

    questionnaires: "Questionnaires",
    supplier: "Fournisseurs",
    suppliers: "Fournisseurs",
    supplier_datapoints: "Données fournisseurs",

    settings: "Paramètres",
    setting_general: "Général",
    setting_material_topics: "Enjeux matériels",
    setting_sites: "Entités",
    setting_users: "Utilisateurs",
    setting_units: "Unités",

    organizations: "Organisations",
    count_members_other: "{{count}} Membres",
    count_members_one: "{{count}} Membre",
});
