// inspired by this wonderful article 😍 https://medium.com/@aylo.srd/server-side-pagination-and-sorting-with-tanstack-table-and-react-bd493170125e
import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { Icon } from "@design-system/Icon";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { cn, type ClassValue } from "@design-system/Utilities";
import {
    ColumnPinningState,
    flexRender,
    getCoreRowModel,
    useReactTable,
    type Column,
    type ColumnDef,
    type PaginationState,
    type SortingState,
    type TableOptions,
} from "@tanstack/react-table";
import {
    forwardRef,
    useEffect,
    useMemo,
    useRef,
    useState,
    type ButtonHTMLAttributes,
    type CSSProperties,
    type PropsWithChildren,
} from "react";
import {
    Table as HTMLTable,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
    Variant,
} from "./HTMLTable";
import { Pagination } from "./Pagination";

export {
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    type ColumnDef,
    type ColumnFiltersState,
    type PaginationState,
    type SortingFn,
    type SortingState,
} from "@tanstack/react-table";

type Options<TData> = Omit<
    TableOptions<TData>,
    "columns" | "data" | "getCoreRowModel"
>;
type PaginationType = {
    state: PaginationState;
    onPaginationChange: TableOptions<any>["onPaginationChange"];
};
type SortingType = {
    state: SortingState;
    onSortingChange: TableOptions<any>["onSortingChange"];
};

type ColumnPinningType = {
    state: ColumnPinningState;
    onColumnPinningChange: TableOptions<any>["onColumnPinningChange"];
};

type TableProps<TData extends unknown, TValue = unknown> = {
    /*
     * The class name for the table.
     * It applies to the outermost container of the table (a div).
     * To target the innermost table element, use the .htmltable class name.
     */
    className?: ClassValue;
    /*
     * The column definitions for the table, based on tanstack's table definition.
     */
    columns: ColumnDef<TData, TValue>[];
    /*
     * The data for the table.
     */
    data: TData[];
    /*
     * Whether the table is currently loading data.
     */
    loading?: boolean;
    /*
     * Additional options for the table that you would typically pass to useReactTable (except "columns" | "data" | "getCoreRowModel"); You can use it to override the tabs behavior.
     */
    options?: Options<TData>;
    /*
     * The pagination state and change handler for the table.
     */
    pagination?: PaginationType;
    /*
     * The sorting state and change handler for the table.
     */
    sorting?: SortingType;
    /*
     * The column pinning state and change handler for the table.
     */
    columnPinning?: ColumnPinningType;
    /*
     * The number of rows in the table.  It's primarily used for managing pagination. Typically, this count is retrieved from an API, which also depends on the data that the 'usePagination' hook uses. However, since the 'rowCount' is usually dependent on the data from the API, it's not possible to pass it to the 'usePagination' hook for it to include it in the pagination object. To maintain clarity and avoid circular dependencies, we've chosen to define 'rowCount' separately.
     */
    rowCount?: number;
    /*
     * Whether the table is compact or regular. Compact will have smaller padding and font size.
     */
    variant?: Variant;
};
/**
 * Table component that uses the react-table library for rendering and functionality.
 *
 * @template TData The type of data that the table will be displaying.
 * @template TValue The type of value that the table cells will be displaying.
 *
 * @param {Object} props The properties for the table.
 * @param {ColumnDef<TData, TValue>[]} props.columns The column definitions for the table.
 * @param {ClassValue} [props.className] The class name for the table. It applies to the outermost container of the table (a div). To target the innermost table element, use the .htmltable class name.
 * @param {TData[]} props.data The data for the table.
 * @param {boolean} [props.loading=false] Whether the table is currently loading data.
 * @param {Options<TData>} [props.options={}] Additional options for the table that you would typically pass to useReactTable (except "columns" | "data" | "getCoreRowModel"); You can use it to override the tabs behavior.
 * @param {PaginationType} [props.pagination] The pagination state and change handler for the table.
 * @param {SortingType} [props.sorting] The sorting state and change handler for the table.
 * @param {ColumnPinningType} [props.columnPinning] The column pinning state and change handler for the table.
 * @param {number} [props.rowCount] The number of rows in the table.
 * @param {Variant} [props.variant="regular"] Whether the table is compact or regular. Compact will have smaller padding and font size.
 *
 * @returns {JSX.Element} The table component.
 */
export const Table = <TData, TValue>({
    className,
    columns,
    data,
    loading,
    options = {},
    columnPinning,
    pagination,
    sorting,
    rowCount,
    variant = "regular",
}: TableProps<TData, TValue>) => {
    const tableOptions: TableOptions<TData> = useMemo(() => {
        const { state: optsState, ...optionsProps } = options;
        let state = optsState;
        let columnPinningOpts;
        let paginationOpts;
        let sortingOpts;

        if (pagination) {
            const { state: paginationState, onPaginationChange } = pagination;
            state = { ...(state ?? {}), pagination: paginationState };
            paginationOpts = {
                manualPagination: true,
                onPaginationChange,
            };
        }

        if (sorting) {
            const { state: sortingState, onSortingChange } = sorting;
            state = { ...(state ?? {}), sorting: sortingState };
            sortingOpts = { manualSorting: true, onSortingChange };
        }

        if (columnPinning) {
            const { state: columnPinningState, onColumnPinningChange } =
                columnPinning;
            state = { ...(state ?? {}), columnPinning: columnPinningState };
            columnPinningOpts = { onColumnPinningChange };
        }

        const opts: TableOptions<TData> = {
            data,
            columns,
            getCoreRowModel: getCoreRowModel(),
            ...(state ? { state } : {}),
            ...(paginationOpts ?? {}),
            ...(sortingOpts ?? {}),
            ...(columnPinningOpts ?? {}),
            rowCount,
            ...optionsProps,
        };
        return opts;
    }, [
        data,
        columns,
        getCoreRowModel,
        pagination,
        sorting,
        columnPinning,
        rowCount,
        options,
    ]);

    const table = useReactTable(tableOptions);

    // all the folloing just for handling the pinned column shadow 😅
    const containerRef = useRef<HTMLDivElement>(null);
    const [scrollIsZero, setScrollIsZero] = useState(true);
    const hasPinnedColumns = useMemo(() => {
        return (
            (columnPinning?.state.left?.length ?? 0) > 0 ||
            (columnPinning?.state.right?.length ?? 0) > 0
        );
    }, [columnPinning]);

    useEffect(() => {
        if (!containerRef.current || !hasPinnedColumns) {
            return;
        }

        function handleScroll() {
            if (!containerRef.current) {
                return;
            }
            const { scrollLeft } = containerRef.current;
            setScrollIsZero(scrollLeft === 0);
        }

        containerRef.current.addEventListener("scroll", handleScroll);
        containerRef.current.addEventListener("scrollend", handleScroll);

        return () => {
            containerRef.current?.removeEventListener("scroll", handleScroll);
            containerRef.current?.removeEventListener(
                "scrollend",
                handleScroll,
            );
        };
    }, [containerRef, columnPinning, hasPinnedColumns, setScrollIsZero]);

    return (
        <FlexCol gap="4" w="full">
            <HTMLTable
                className={hasPinnedColumns ? "relative" : undefined}
                containerClassName={cn(
                    hasPinnedColumns ? "sticky z-0" : undefined,
                    className,
                )}
                containerRef={containerRef}
            >
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                const style = getCommonStyles(header.column);
                                return (
                                    <TableHead
                                        className={cn(
                                            header.column.columnDef.meta
                                                ?.headerClassName,
                                            header.column.getIsPinned() &&
                                                "bg-secondary",
                                            getCommonPinningClasses(
                                                header.column,
                                                {
                                                    parentScrollIsZero:
                                                        scrollIsZero,
                                                },
                                            ),
                                        )}
                                        key={header.id}
                                        {...(Object.keys(style).length > 0
                                            ? { style }
                                            : {})}
                                        variant={variant}
                                    >
                                        {header.column.getCanSort() ? (
                                            <FlexRow
                                                alignItems="center"
                                                className="sortable-header-wrapper text-sm font-bold"
                                                gap="2"
                                                h="full"
                                                w="full"
                                            >
                                                <button
                                                    className={cn(
                                                        "cursor-pointer rounded-full border min-h-5 min-w-5 grid place-items-center",
                                                        "text-primary",
                                                        "bg-secondary border-secondary",
                                                        "hover:bg-tertiary hover:border-primary",
                                                    )}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    {!header.column.getIsSorted() && (
                                                        <Icon
                                                            name="angleUpDown"
                                                            size="xs"
                                                        />
                                                    )}
                                                    {header.column.getIsSorted() ===
                                                        "desc" && (
                                                        <Icon
                                                            name="arrowUp"
                                                            size="xs"
                                                        />
                                                    )}
                                                    {header.column.getIsSorted() ===
                                                        "asc" && (
                                                        <Icon
                                                            name="arrowDown"
                                                            size="xs"
                                                        />
                                                    )}
                                                </button>
                                                {flexRender(
                                                    header.column.columnDef
                                                        .header,
                                                    header.getContext(),
                                                )}
                                            </FlexRow>
                                        ) : (
                                            flexRender(
                                                header.column.columnDef.header,
                                                header.getContext(),
                                            )
                                        )}
                                    </TableHead>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {loading
                        ? Array.from({
                              length: table.getState().pagination.pageSize,
                          }).map((_, i) => (
                              <TableRow key={i}>
                                  {table.getHeaderGroups().map((headerGroup) =>
                                      headerGroup.headers.map((cell, idx) => {
                                          const style = getCommonWidthStyles(
                                              cell.column,
                                          );
                                          return (
                                              <TableCell
                                                  {...(Object.keys(style)
                                                      .length > 0
                                                      ? { style }
                                                      : {})}
                                                  className={cn(
                                                      cell.column.columnDef.meta
                                                          ?.cellClassName ?? "",
                                                  )}
                                                  key={idx}
                                                  variant={variant}
                                              >
                                                  <Skeleton
                                                      br="xl"
                                                      h="2"
                                                      w="full"
                                                  />
                                              </TableCell>
                                          );
                                      }),
                                  )}
                              </TableRow>
                          ))
                        : table.getRowModel().rows.map((row) => (
                              <TableRow key={row.id}>
                                  {row.getVisibleCells().map((cell) => {
                                      const style = getCommonStyles(
                                          cell.column,
                                      );
                                      return (
                                          <TableCell
                                              className={cn(
                                                  cell.column.columnDef.meta
                                                      ?.cellClassName ?? "",
                                                  cell.column.getIsPinned() &&
                                                      "bg-primary",
                                                  getCommonPinningClasses(
                                                      cell.column,
                                                      {
                                                          parentScrollIsZero:
                                                              scrollIsZero,
                                                      },
                                                  ),
                                              )}
                                              key={cell.id}
                                              {...(Object.keys(style).length > 0
                                                  ? { style }
                                                  : {})}
                                              variant={variant}
                                          >
                                              {flexRender(
                                                  cell.column.columnDef.cell,
                                                  cell.getContext(),
                                              )}
                                          </TableCell>
                                      );
                                  })}
                              </TableRow>
                          ))}
                </TableBody>
            </HTMLTable>
            <Pagination loading={loading} table={table} />
        </FlexCol>
    );
};

/**
 * HeaderFilter component for the table header.
 * This component is used to display a filter in the table header.
 * It is used as a trigger for any selection component (e.g. MultiSelect).
 * In this way the user has full control over the selection component, and
 * can customize it as needed (type of selection, dropdown content etc.).
 */
export const HeaderFilter = forwardRef<
    HTMLButtonElement,
    PropsWithChildren<
        ButtonHTMLAttributes<HTMLButtonElement> & {
            /**
             * Whether the filter is active.
             * If active, the text will be green.
             * If not active, the text will be the primary color.
             * @default false
             * */
            active?: boolean;
        }
    >
>(({ active, children, className, ...props }, ref) => {
    return (
        <button
            {...props}
            className={cn(
                "h-full w-full",
                "flex items-center justify-between",
                "text-primary text-sm font-bold",
                "hover:bg-tertiary hover:rounded-lg",
                active && "text-green",
                className,
            )}
            ref={ref}
        >
            {children}
            <Icon className="text-primary" name="angleDown" size="sm" />
        </button>
    );
});

/**
 * Hook for managing pagination state.
 *
 * @param {number} [initialSize=10] The initial page size.
 * @param {number} [initialIndex=0] The initial page index.
 *
 * @returns {Object} The pagination state and change handler, and the current take and skip values (suited for a common pagination pattern in many APIs).
 */
export function usePagination(initialSize = 10, initialIndex = 0) {
    const [paginationState, setPaginationState] = useState({
        pageSize: initialSize,
        pageIndex: initialIndex,
    });
    const { pageSize, pageIndex } = paginationState;

    return {
        // table state
        pagination: {
            state: paginationState,
            onPaginationChange: setPaginationState,
        },
        // API
        take: pageSize,
        skip: pageSize * pageIndex,
    };
}

/**
 * Hook for managing sorting state according to the Table component.
 *
 * @template TField The type of field that the sorting is based on (cooresponds to the accessorKey of the column).
 *
 * @param {TField} initialField The initial field to sort by.
 * @param {"asc" | "desc"} [initialOrder="asc"] The initial order of the sorting.
 *
 * @returns {Object} The sorting state and change handler, and the current order and field.
 */
export function useSorting<TField extends string>(
    initialField: TField,
    initialOrder: "asc" | "desc" = "asc",
) {
    const [sortingState, setSorting] = useState([
        { id: initialField, desc: initialOrder === "desc" },
    ]);

    const order: "asc" | "desc" = !sortingState.length
        ? initialOrder
        : sortingState[0].desc
          ? "desc"
          : "asc";

    const sorting: SortingType = {
        state: sortingState,
        // @ts-ignore
        onSortingChange: setSorting,
    };
    return {
        sorting,
        order,
        field: sortingState.length ? sortingState[0].id : initialField,
    };
}

/**
 * `useColumnPinning` is a custom React hook for managing the state of column pinning in a table.
 *
 * Column pinning is a feature that allows the user to "pin" or "freeze" a column in place.
 * This is useful in a table with horizontal scrolling, as the pinned column will stay in place while other columns can be scrolled horizontally.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Partial<ColumnPinningState>} params.initialColumnPinningState - An object representing the initial state of column pinning. This object can have two properties: `left` and `right`, which are arrays of column identifiers. The `left` array represents the columns pinned to the left side of the table, and the `right` array represents the columns pinned to the right side of the table.
 *
 * @returns {Object} An object with a single property `columnPinning`, which is an object with two properties: `state` and `onColumnPinningChange`.
 * - `state` is an object representing the current state of column pinning. It has the same structure as `initialColumnPinningState`.
 * - `onColumnPinningChange` is a function used by the table to change the state of column pinning. It accepts a new state object as its parameter.
 */
export function useColumnPinning({
    initialColumnPinningState,
}: {
    initialColumnPinningState: Partial<ColumnPinningState>;
}) {
    const [columnPinningState, setColumnPinningState] =
        useState<ColumnPinningState>({
            ...{
                left: [],
                right: [],
            },
            ...initialColumnPinningState,
        });

    return {
        columnPinning: {
            state: columnPinningState,
            onColumnPinningChange: setColumnPinningState,
        },
    };
}

/**
 * Returns the common styles (for headers and cells) for columns with a fixed width.
 * If the column has a fixed width, it will be applied to the column.
 *
 * @template TData The type of data that the table will be displaying.
 *
 * @param {Column<TData>} column The column object.
 *
 * @returns {CSSProperties} The common styles for columns with a fixed width, or an empty object if thre's nothing to apply.
 */
function getCommonWidthStyles<TData>(column: Column<TData>): CSSProperties {
    const style =
        typeof column.columnDef.meta?.colWidth === "string"
            ? {
                  width: column.columnDef.meta.colWidth,
              }
            : {};
    return style;
}

type GetCommonPinningClassesOptions = {
    /**
     * Whether the parent scroll is zero (if not, it means it is being scrolled).
     * This is used to determine if the column should have a shadow.
     */
    parentScrollIsZero?: boolean;
};
/**
 * Returns the common styles (for headers and cells) for pinned columns.
 *
 * @template TData The type of data that the table will be displaying.
 *
 * @param {Column<TData>} column The column object.
 * @param {GetCommonPinningClassesOptions} [options={}] An object containing options for getting the common pinning styles.
 *
 * @returns {CSSProperties} The common styles for pinned columns, or an empty object if there's nothing to apply.
 */
function getCommonPinningStyles<TData>(column: Column<TData>): CSSProperties {
    const isPinned = column.getIsPinned();

    if (!isPinned) {
        return {};
    }

    return {
        left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
        right:
            isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
        position: "sticky",
        width: column.getSize(),
        zIndex: 1,
    };
}

/**
 * Returns the common classes (for headers and cells) for pinned columns.
 *
 * @template TData The type of data that the table will be displaying.
 *
 * @param {Column<TData>} column The column object.
 * @param {GetCommonPinningClassesOptions} [options={}] An object containing options for getting the common pinning styles.
 *
 * @returns {CSSProperties} The common styles for pinned columns, or an empty object if there's nothing to apply.
 */
function getCommonPinningClasses<TData>(
    column: Column<TData>,
    { parentScrollIsZero }: GetCommonPinningClassesOptions = {},
): ClassValue {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn =
        isPinned === "left" && column.getIsLastColumn("left");
    const isFirstRightPinnedColumn =
        isPinned === "right" && column.getIsFirstColumn("right");

    if (!isPinned) {
        return;
    }

    const classes = [];

    if (isLastLeftPinnedColumn) {
        classes.push(
            "after:block after:content-['_'] after:absolute after:top-0 after:right-[-4px] after:w-1 after:h-[calc(100%+1px)] after:transition-shadow after:duration-500",
        );
    }
    if (isLastLeftPinnedColumn && !parentScrollIsZero) {
        classes.push("after:shadow-[inset_2px_0px_4px_0px_rgba(0,0,0,0.3)] ");
    }
    if (isFirstRightPinnedColumn) {
        classes.push(
            "before:block before:content-['_'] before:absolute before:top-0 before:left-[-4px] before:w-1 before:h-[calc(100%+1px)]  before:transition-shadow before:duration-500",
        );
    }
    if (isFirstRightPinnedColumn && !parentScrollIsZero) {
        classes.push("before:shadow-[inset_-2px_0px_4px_0px_rgba(0,0,0,0.3)]");
    }

    return cn(...classes);
}
/**
 * Returns the Returns the common styles for headers and cells
 *
 * @template TData The type of data that the table will be displaying.
 *
 * @param {Column<TData>} column The column object.
 *
 * @returns {CSSProperties} The common styles for pinned columns, or an empty object if thre's nothing to apply.
 */
function getCommonStyles<TData>(column: Column<TData>): CSSProperties {
    const commonStyles = {
        ...getCommonWidthStyles(column),
        ...getCommonPinningStyles(column),
    };

    return commonStyles;
}

/**
 * The basic comparison function for creating
 * custom sorting functions in the colum definition.
 */
export function compareBasic<T>(a: T, b: T) {
    return a === b ? 0 : a > b ? 1 : -1;
}
