import {
    defaultProgress,
    type OnboardingProgress,
    type LocalStorageOnboardingProgressRepository as OnboardingProgressRepository,
} from "@app/repositories/LocalStorageRepositories/OnboardingProgressRepository";

export type OnboardingProgressEntity = {
    progress: OnboardingProgress;
    stats: {
        completedSteps: number;
        totalSteps: number;
        percentage: number;
    };
};

function getOnboardingProgress({
    onboardingProgressRepository,
}: {
    onboardingProgressRepository: OnboardingProgressRepository;
}): OnboardingProgressEntity {
    const progress = sanitizeProgress(
        onboardingProgressRepository.getOnboardingProgress(),
    );
    const stats = getProgressStats(progress);

    return { progress, stats };
}

function setOnboardingProgress(
    {
        onboardingProgressRepository,
    }: {
        onboardingProgressRepository: OnboardingProgressRepository;
    },
    progress: OnboardingProgress,
): OnboardingProgressEntity {
    const sanitizedProgress = sanitizeProgress(progress);
    onboardingProgressRepository.setOnboardingProgress(sanitizedProgress);
    return {
        progress: sanitizedProgress,
        stats: getProgressStats(sanitizedProgress),
    };
}

function toggleIsOnboardingSectionCollapsed({
    onboardingProgressRepository,
}: {
    onboardingProgressRepository: OnboardingProgressRepository;
}): boolean {
    const isCollapsed =
        onboardingProgressRepository.getIsOnboardingSectionCollapsed();
    onboardingProgressRepository.setIsOnboardingSectionCollapsed(!isCollapsed);
    return !isCollapsed;
}

/*
 * Helper functions
 */
function getProgressStats(progress: OnboardingProgress) {
    const { completed, total } = Object.values(progress).reduce(
        (acc, isCompleted) => {
            return {
                completed: isCompleted ? acc.completed + 1 : acc.completed,
                total: acc.total + 1,
            };
        },
        { completed: 0, total: 0 },
    );

    return {
        completedSteps: completed,
        totalSteps: total,
        percentage: Math.floor((completed / total) * 100),
    };
}

function sanitizeProgress(progress: OnboardingProgress) {
    return Object.keys(progress).reduce((acc, key) => {
        if (isValidOnboardingProgressKey(key)) {
            return { ...acc, [key]: progress[key] };
        }
        return acc;
    }, {} as OnboardingProgress);
}

function isValidOnboardingProgressKey(
    key: unknown,
): key is keyof OnboardingProgress {
    return Object.keys(defaultProgress).includes(key as string);
}

export {
    getOnboardingProgress,
    setOnboardingProgress,
    toggleIsOnboardingSectionCollapsed,
};
