import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { DropdownMenu } from "@design-system/Inputs/DropdownMenu";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { type FC } from "react";
import { useInitializeVectorStore } from "../data";

export const AiCsControls: FC = () => {
    const { initializeVectorStore, reimportSharedDocs, isLoading } =
        useInitializeVectorStore();

    const init = () => {
        if (isLoading) return;
        initializeVectorStore();
    };

    const reimport = () => {
        if (isLoading) return;
        reimportSharedDocs();
    };

    return (
        <DropdownMenu>
            <DropdownMenu.Trigger asChild>
                <Button size="sm" variant="tonal">
                    <FlexRow
                        p="1.5"
                        gap="2"
                        alignItems="center"
                        className="h-[26px]"
                    >
                        <Text variant="body-bold">CS Init Ai</Text>
                        <Button.Icon name="magicWand" />
                    </FlexRow>
                </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
                <DropdownMenu.Item disabled={isLoading} onSelect={init}>
                    <Icon name="magicWand" />
                    <Text variant="body-bold">
                        Initialize Vector Store and import shared docs (first
                        time only)
                    </Text>
                </DropdownMenu.Item>
                <DropdownMenu.Item disabled={isLoading} onSelect={reimport}>
                    <Icon name="file" />
                    <Text variant="body-bold">Re-import shared docs and</Text>
                </DropdownMenu.Item>
            </DropdownMenu.Content>
        </DropdownMenu>
    );
};
