import { Period } from "@app/utils/periodToUrl";
import { User } from "@generated/client/graphql";
import { atom } from "jotai";
import { atomFamily, atomWithStorage } from "jotai/utils";

export const inactiveIndicatorsModalIsOpenAtom = atom<boolean>(false);
export const createCustomIndicatorsModalIsOpenAtom = atom<boolean>(false);
export const exportModalIsOpenAtom = atom<boolean>(false);

export const activePeriodsAtom = atomWithStorage<Period[]>(
    "reportingPeriods",
    [],
);

export const activeReportingEntityIdAtom = atom<string>("");
export const usersToSendReminderAtom = atom<User[]>([]);
export const selectedUsersAtomFamily = atomFamily(
    (orgUname: string | undefined) =>
        atomWithStorage<(string | null)[]>(
            `reportingSelectedUsers_${orgUname}`,
            [],
        ),
);
export const openReminderModalAtom = atom<boolean>(false);
export const openPeriodManagementModalAtom = atom<boolean>(false);

export const reportingContributorsSearchFilterAtom = atom<string | undefined>(
    undefined,
);
export const toFillSwitchAtom = atom<boolean>(false);

export const activeReportingTableColumnsAtom = atomWithStorage<
    (string | null)[]
>("__beavr_activeReportingTableColumnsAtom", ["subtheme", "assignedTo"]);

export const selectedSubthemesAtomFamily = atomFamily(
    (orgUname: string | undefined) =>
        atomWithStorage<string[]>(`reportingSelectedSubthemes_${orgUname}`, []),
);

export const selectedTagsAtomFamily = atomFamily(
    (orgUname: string | undefined) =>
        atomWithStorage<(string | null)[]>(
            `reportingSelectedTags_${orgUname}`,
            [],
        ),
);

export const selectedInactiveSubthemesAtomFamily = atomFamily(
    (orgUname: string | undefined) =>
        atomWithStorage<(string | null)[]>(
            `reportingSelectedInactiveSubthemes_${orgUname}`,
            [],
        ),
);

export const searchThemesFilterAtom = atom<string>("");
export const searchThemesActiveFilterAtom = atom<string | undefined>(undefined);

export const selectedActiveFrameworkIdsAtomFamily = atomFamily(
    (orgUname: string | undefined) =>
        atomWithStorage<(string | null)[]>(
            `reportingSelectedActiveFramework_${orgUname}`,
            [],
        ),
);

export const selectedInactiveFrameworkIdsAtomFamily = atomFamily(
    (orgUname: string | undefined) =>
        atomWithStorage<(string | null)[]>(
            `reportingSelectedInactiveFramework_${orgUname}`,
            [],
        ),
);
