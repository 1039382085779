import { graphql } from "@generated/client";
import {
    CreateObjectiveTargetInput,
    ObjectiveTargetPatch,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const updateObjectiveTargetMutation = graphql(`
    mutation updateObjectiveTarget(
        $id: String!
        $patch: ObjectiveTargetPatch!
    ) {
        updateObjectiveTarget(id: $id, patch: $patch) {
            id
        }
    }
`);

const createObjectiveTargetMutation = graphql(`
    mutation createObjectiveTarget($input: CreateObjectiveTargetInput!) {
        createObjectiveTarget(input: $input) {
            id
        }
    }
`);

const deleteObjectiveTargetMutation = graphql(`
    mutation deleteObjectiveTarget($id: String!) {
        deleteObjectiveTarget(id: $id) {
            status
            error {
                code
                message
            }
        }
    }
`);

export class GraphQLObjectiveTargetRepository {
    update = async (input: { id: string; patch: ObjectiveTargetPatch }) => {
        return graphqlClientKoyeb.request(
            updateObjectiveTargetMutation,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    create = async (input: CreateObjectiveTargetInput) => {
        return graphqlClientKoyeb.request(
            createObjectiveTargetMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    delete = async (id: string) => {
        return graphqlClientKoyeb.request(
            deleteObjectiveTargetMutation,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
