import { RightModal, RightModalContent } from "@app/components/RightModal";
import { isExampleDrawerOpen } from "./ctx";
import { useAtom } from "jotai";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { RightModalTitle } from "@app/components/RightModal";
import { Text } from "@design-system/Typography/Text";
import { ThemeTagSelect } from "@app/shared/components/ThemeTagSelect";
import { IndicatorDisplay } from "../ObjectiveBody/IndicatorDisplay";
import { Objective } from "@generated/client/graphql";
import { TargetExampleDisplay } from "./TargetExampleDisplay";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME, keys } from "./i18n";
import { useExampleData } from "./useExampleData";
import { SubthemeTagSelect } from "@app/shared/components/SubthemeTagSelect";

export const ExampleDrawer = () => {
    const [isOpen, setIsOpen] = useAtom(isExampleDrawerOpen);
    const handleOpenChange = (open: boolean) => setIsOpen(open);

    const { objective, indicator, targets } = useExampleData();

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <RightModal open={isOpen} onOpenChange={handleOpenChange}>
            <RightModalContent>
                <FlexCol gap="5">
                    <RightModalTitle className="p-6 pb-0">
                        <FlexCol gap="5">
                            <Text
                                variant="body-xs"
                                color="secondary"
                                className="uppercase"
                            >
                                {t(keys.example_title)}
                            </Text>
                            <Text variant="header2" color="primary">
                                {objective.name}
                            </Text>
                        </FlexCol>
                    </RightModalTitle>
                    <FlexCol gap="5" px="6" pb="6">
                        <FlexRow gap="3">
                            <ThemeTagSelect
                                themeId={objective.themeId}
                                disabled
                            />
                            <SubthemeTagSelect
                                themeId={objective.themeId}
                                subthemeId={objective.subthemeId}
                                disabled
                            />
                        </FlexRow>
                        <IndicatorDisplay
                            objective={objective as unknown as Objective}
                        />
                        <FlexCol
                            gap="5"
                            p="5"
                            br="lg"
                            className="border border-secondary"
                        >
                            <Text variant="body-bold">
                                {t(keys.description_title)}
                            </Text>
                            <Text variant="body">{objective.description}</Text>
                        </FlexCol>
                        <FlexCol
                            gap="5"
                            p="5"
                            br="lg"
                            className="border border-secondary"
                        >
                            <Text variant="body-bold">
                                {t(keys.targets_title)}
                            </Text>
                            <FlexCol alignItems="start">
                                {targets.map((target, idx) => (
                                    <FlexCol
                                        key={target.id}
                                        gap="0"
                                        alignSelf="stretch"
                                    >
                                        <TargetExampleDisplay
                                            target={target}
                                            unitShortName={
                                                indicator.unit.shortName
                                            }
                                            isLast={idx === targets.length - 1}
                                        />
                                        {idx < targets.length - 1 && (
                                            <FlexRow
                                                alignItems="center"
                                                className="w-[14px] h-[16px]  border-r"
                                            />
                                        )}
                                    </FlexCol>
                                ))}
                            </FlexCol>
                        </FlexCol>
                    </FlexCol>
                </FlexCol>
            </RightModalContent>
        </RightModal>
    );
};
