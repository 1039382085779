import i18next from "../../../i18n";

i18next.addResourceBundle("en", "SupplierContribution", {
    campaign: "Loading questionnaire...",
    instructions:
        "Welcome ! Use the fields below to provide the requested information. Information and data will be automatically saved as you provide it. Please contact us using the chat if you have any question.",
    nothing_to_complete:
        "You have no pending requests to complete for this campaign.",
    hide_answered: "Hide answered",
    show_answered: "Show answered",
    send_questionnaire_for_review: "Send for review",
    send_questionnaire_for_review_title: "Are you sure?",
    send_questionnaire_for_review_content:
        "You will not be able to edit it anymore.",
    close_questionnaire_save_and_close: "Save and close",
});
i18next.addResourceBundle("fr", "SupplierContribution", {
    campaign: "Chargement du questionnaire...",
    instructions:
        "Bienvenue ! Utilisez les champs ci-dessous pour fournir les informations demandées. Les informations et les données seront automatiquement sauvegardées au fur et à mesure que vous les fournissez. Veuillez nous contacter via le chat si vous avez des questions.",
    nothing_to_complete:
        "Vous n'avez aucune demande en attente à compléter pour cette campagne.",
    hide_answered: "Masquer les questions répondues",
    show_answered: "Afficher tous",
    send_questionnaire_for_review: "Envoyer pour révision",
    send_questionnaire_for_review_title: "Êtes-vous sûr ?",
    send_questionnaire_for_review_content: "Vous ne pourrez plus le modifier.",
    close_questionnaire_save_and_close: "Enregistrer et fermer",
});
