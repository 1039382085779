import Checkbox from "@app/components/Checkbox";
import { orderPeriods } from "@app/usecases/ReportingUseCases";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ReportingPeriod } from "@generated/client/graphql";
import { useMemo, type FC, type PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";
import {
    getDisplayedPeriod,
    getPeriodsOfYear,
    getYearsOfPeriods,
} from "../../services";

import { Tooltip, TooltipProvider } from "@design-system/DataDisplay/Tooltip";
import { cn } from "@design-system/Utilities";
import { useAtom, useAtomValue } from "jotai";
import { openPeriodManagementModalAtom } from "../../context";
import { useDatapointGroupsOfEntity } from "../../data";
import {
    useActiveDatatpointGroupIds,
    useSetReportingFilters,
} from "../../hooks";
import { isAiChatModalOpenAtom } from "@app/screens/Global/AskAIModal/ctx";

const PeriodSelect: FC<PropsWithChildren> = () => {
    const { t } = useTranslation("ReportingPage");
    const [periodManagementModalIsOpen, setPeriodManagementModalIsOpen] =
        useAtom(openPeriodManagementModalAtom);
    const AiChatModalIsOpen = useAtomValue(isAiChatModalOpenAtom);
    const activeDatapointGroupsIds = useActiveDatatpointGroupIds();
    const { setActivePeriods } = useSetReportingFilters();
    const datapointGroupsOfEntity = useDatapointGroupsOfEntity();

    const orderedGroups = useMemo(() => {
        return datapointGroupsOfEntity.sort((a, b) =>
            orderPeriods(a.period, b.period),
        );
    }, [datapointGroupsOfEntity]);

    const yearsOfPeriods = useMemo(() => {
        return getYearsOfPeriods(datapointGroupsOfEntity);
    }, [datapointGroupsOfEntity]);

    const onActiveGroupIdsChange = (values: (string | null)[]) => {
        const periods = orderedGroups
            .filter((group) => values.includes(group.id))
            .map((group) => group.period);
        setActivePeriods(periods);
    };

    const filteredGroups = (year: number) => {
        return getPeriodsOfYear(datapointGroupsOfEntity, year);
    };

    const displayedPeriod = (period: ReportingPeriod) => {
        return getDisplayedPeriod(period, {
            yearLabel: t("year"),
            monthLabel: t(period.month ?? ""),
        });
    };
    const yearsWithAllPeriodsActive = yearsOfPeriods.filter((year) =>
        filteredGroups(year).every((group) =>
            activeDatapointGroupsIds.includes(group.id),
        ),
    );
    const yearsWithNoPeriodsActive = yearsOfPeriods.filter(
        (year) =>
            !filteredGroups(year).find((group) =>
                activeDatapointGroupsIds.includes(group.id),
            ),
    );
    const hasSubContent = (year: number) => {
        const periodsWithoutWholeYear = filteredGroups(year).filter(
            (group) => group.period.month || group.period.quarter,
        );
        return periodsWithoutWholeYear.length > 0;
    };
    const handleYearClick = (year: number) => () => {
        if (yearsWithAllPeriodsActive.includes(year)) {
            const newIds = activeDatapointGroupsIds.filter(
                (id) => !filteredGroups(year).find((group) => group.id === id),
            );
            onActiveGroupIdsChange(newIds);
            return;
        }
        const wholeYearId = filteredGroups(year)
            .filter((group) => !group.period.month && !group.period.quarter)
            ?.map((group) => group.id);
        if (wholeYearId?.length && yearsWithNoPeriodsActive.includes(year)) {
            onActiveGroupIdsChange([
                ...activeDatapointGroupsIds,
                ...wholeYearId,
            ]);
            return;
        }
        onActiveGroupIdsChange([
            ...activeDatapointGroupsIds,
            ...filteredGroups(year).map((group) => group.id),
        ]);
    };

    const handleSettingsClick = () => {
        setPeriodManagementModalIsOpen(true);
    };

    const getChechboxState = (year: number) => {
        if (yearsWithAllPeriodsActive.includes(year)) {
            return true;
        }
        if (yearsWithNoPeriodsActive.includes(year)) {
            return false;
        }
        return "partial";
    };

    return (
        <FlexRow alignItems="center" w="full" className="h-8">
            <Tooltip
                delayDuration={0}
                defaultOpen={true}
                open={
                    datapointGroupsOfEntity?.length === 0 &&
                    !periodManagementModalIsOpen &&
                    !AiChatModalIsOpen
                }
            >
                <Tooltip.Trigger asChild>
                    <Button
                        variant="tonal"
                        size="sm"
                        className="border-r-0 rounded-r-none h-[30px]"
                        onClick={handleSettingsClick}
                    >
                        <Icon name="settingsGear" className="h-4" />
                    </Button>
                </Tooltip.Trigger>

                <Tooltip.Content
                    className="bg-warning-500 text-primary"
                    arrowClassName="fill-warning-500"
                    side="top"
                >
                    {t("add_periods_warn")}
                </Tooltip.Content>
            </Tooltip>

            <TooltipProvider>
                <Tooltip
                    delayDuration={0}
                    defaultOpen={true}
                    open={
                        datapointGroupsOfEntity?.length > 0 &&
                        activeDatapointGroupsIds?.length === 0 &&
                        !periodManagementModalIsOpen &&
                        !AiChatModalIsOpen
                    }
                >
                    <Tooltip.Trigger>
                        <MultiSelect
                            values={activeDatapointGroupsIds}
                            onValuesChange={onActiveGroupIdsChange}
                        >
                            <MultiSelect.Trigger>
                                <Button
                                    variant="tonal"
                                    size="sm"
                                    className="shadow-sm cursor-pointer focus:outline-none rounded-l-none"
                                >
                                    <FlexRow
                                        alignItems="center"
                                        justifyContent="center"
                                        gap="1"
                                    >
                                        <div
                                            className={cn(
                                                " h-4 w-4 bg-beavrGreen text-white text-[11px] font-bold rounded-lg flex items-center justify-center",
                                                activeDatapointGroupsIds?.length ===
                                                    0 &&
                                                    "bg-warning-500 text-primary",
                                            )}
                                        >
                                            {activeDatapointGroupsIds.length}
                                        </div>
                                        <Text variant="body-sm-bold">
                                            {t("select_periods")}
                                        </Text>
                                        <Icon name="calendar" className="h-4" />
                                    </FlexRow>
                                </Button>
                            </MultiSelect.Trigger>

                            <MultiSelect.Content>
                                {yearsOfPeriods.map((year) => (
                                    <MultiSelect.Sub key={year}>
                                        <MultiSelect.SubTrigger>
                                            <Button
                                                variant="text"
                                                size="sm"
                                                className="h-[36px] cursor-pointer hover:shadow-none hover:bg-secondary hover:text-green justify-between"
                                                onClick={handleYearClick(year)}
                                            >
                                                <FlexRow
                                                    gap="2.5"
                                                    alignItems="center"
                                                >
                                                    <Checkbox
                                                        checked={getChechboxState(
                                                            year,
                                                        )}
                                                    />
                                                    <Text variant="body">
                                                        {year}
                                                    </Text>
                                                </FlexRow>
                                                {hasSubContent(year) && (
                                                    <Icon
                                                        name="angleRight"
                                                        size="sm"
                                                    />
                                                )}
                                            </Button>
                                        </MultiSelect.SubTrigger>
                                        {hasSubContent(year) && (
                                            <MultiSelect.SubContent>
                                                {filteredGroups(year).map(
                                                    (group) => (
                                                        <MultiSelect.Item
                                                            value={group.id}
                                                            key={group.id}
                                                        >
                                                            {({
                                                                isSelected,
                                                            }) => (
                                                                <FlexRow
                                                                    h="7"
                                                                    alignItems="center"
                                                                    pl="6"
                                                                    py="2"
                                                                    gap="2"
                                                                    className="hover:bg-secondary hover:text-green"
                                                                >
                                                                    <Checkbox
                                                                        checked={
                                                                            isSelected
                                                                        }
                                                                    />
                                                                    <Text
                                                                        variant="body"
                                                                        color="primary"
                                                                        key={
                                                                            group.id
                                                                        }
                                                                    >
                                                                        {displayedPeriod(
                                                                            group.period,
                                                                        )}
                                                                    </Text>
                                                                </FlexRow>
                                                            )}
                                                        </MultiSelect.Item>
                                                    ),
                                                )}
                                            </MultiSelect.SubContent>
                                        )}
                                    </MultiSelect.Sub>
                                ))}
                            </MultiSelect.Content>
                        </MultiSelect>
                    </Tooltip.Trigger>

                    <Tooltip.Content
                        className="bg-warning-500 text-black"
                        arrowClassName="fill-warning-500"
                        side="top"
                    >
                        {t("select_periods_warn")}
                    </Tooltip.Content>
                </Tooltip>
            </TooltipProvider>
        </FlexRow>
    );
};

export default PeriodSelect;
