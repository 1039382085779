import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";

import { Check } from "iconoir-react";
import { ReactNode } from "react";

import { cn } from "../../../../../lib/utils";
import BaseItem from "./BaseItem";

export default function SimpleItem({
    children,
    ...props
}: {
    children: ReactNode;
} & DropdownPrimitive.DropdownMenuCheckboxItemProps) {
    return (
        <BaseItem
            {...props}
            className="group cursor-pointer"
            IconLeft={({ className }) => (
                <Check
                    className={cn(
                        className,
                        "text-green ",
                        props.checked ? "visible" : "invisible",
                    )}
                />
            )}
            {...props}
        >
            {children}
        </BaseItem>
    );
}
