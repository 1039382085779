import { ValidationButton } from "@app/pages/Editor/ValidationButton";
import { Flex } from "@design-system/Layout/Flex";
import { cn } from "@design-system/Utilities";

const TopRightBlock = () => (
    <Flex
        alignItems="center"
        p="4"
        className={cn("border-b border-b-tertiary h-fit")}
    >
        <ValidationButton />
    </Flex>
);

export default TopRightBlock;
