import { atom } from "jotai";

export const navigationButtonType = {
    dashoard: "dashoard",
    frameworks: "frameworks",
    requirements: "requirements",
    sustainability_center: "sustainability_center",
    policies: "policies",
    objectives: "objectives",
    measures: "measures",
    custom_documents: "custom_documents",
    all_files: "all_files",
    reporting_overview: "reporting_overview",
    reporting_progress: "reporting_progress",
    reporting_indicators: "reporting_indicators",
    reporting_documents: "reporting_documents",
    supplier_questionnaires: "supplier_questionnaires",
    suppliers: "suppliers",
    supplier_datapoints: "supplier_datapoints",
    setting_general: "setting_general",
    setting_material_topics: "setting_material_topics",
    setting_sites: "setting_sites",
    setting_users: "setting_users",
    setting_units: "setting_units",
} as const;

export type NavigationButtonTypeKey = keyof typeof navigationButtonType;

export const activeNavigationButtonKey = atom<
    NavigationButtonTypeKey | undefined
>(undefined);
