import { GraphQLOrganizationRepository } from "@app/repositories/GraphQLRepositories/OrganizationRepository";
import { getOrgs } from "@app/usecases/OrganizationUseCases";
import { type GetUserOrganisationsQueryVariables } from "@generated/client/graphql";
import { useMutation, useQuery } from "@tanstack/react-query";

const organizationRepository = new GraphQLOrganizationRepository();

export const userOrganizationsKeys = {
    all: ["userOrganizations"] as const,
    filtered: (filter: GetUserOrganisationsQueryVariables) =>
        [...userOrganizationsKeys.all, filter] as const,
    beavrInternal: () =>
        [...userOrganizationsKeys.all, "beavrInternalUsers"] as const,
};

export function useOrganizations(
    input: GetUserOrganisationsQueryVariables = { skip: 0, take: 10 },
) {
    return useQuery({
        queryKey: userOrganizationsKeys.filtered(input),
        queryFn: () => getOrgs({ organizationRepository }, input),
    });
}

export function useBeavrInternalUsers() {
    return useQuery({
        queryKey: userOrganizationsKeys.beavrInternal(),
        queryFn: () => organizationRepository.getBeavrInternalUsers(),
    });
}

export function useMutateOrganization() {
    return useMutation({
        mutationFn: (input: any) =>
            organizationRepository.updateOrganization(input),
    });
}
