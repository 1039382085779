import { GetSuppliersQuery } from "@generated/client/graphql";
import { useQuery } from "@tanstack/react-query";
import { GraphQLSupplierRepository } from "../../../repositories/GraphQLRepositories/SupplierRepository";

const supplierRepository = new GraphQLSupplierRepository();

export type Supplier = Exclude<
    GetSuppliersQuery["suppliers"],
    null | undefined
>[number];

export const useSuppliers = () => {
    const query = useQuery({
        queryKey: ["suppliers"],
        queryFn: () => supplierRepository.getSuppliers(),
    });
    return {
        suppliers: query.data?.suppliers,
        isLoading: query.isLoading,
        refetch: query.refetch,
    };
};
