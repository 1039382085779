import { useSetToast } from "@app/components/Toast";
import {
    months,
    orderPeriods,
    quarters,
} from "@app/usecases/ReportingUseCases";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useAtomValue } from "jotai";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { activeReportingEntityIdAtom } from "../../context";
import {
    useCreateDatapointGroup,
    useDatapointGroupsOfEntity,
    useDeleteDatapointGroup,
    useDeleteManyDatapointGroups,
} from "../../data";
import { RESOURCE_NAME, keys } from "./i18n";

type PeriodBoxProps = {
    type: "year" | "month" | "quarter";
    selectedYear: number;
};

export const PeriodBox: FC<PeriodBoxProps> = ({ type, selectedYear }) => {
    const { setToastSuccess, setToastError } = useSetToast();
    const datapointGroupsOfEntity = useDatapointGroupsOfEntity();
    const activeReportingEntityId = useAtomValue(activeReportingEntityIdAtom);
    const { mutate: mutateDelete } = useDeleteDatapointGroup();
    const { mutate: mutateCreate } = useCreateDatapointGroup();
    const { mutate: mutateDeleteMany } = useDeleteManyDatapointGroups();
    const isMonth = type === "month";
    const isQuarter = type === "quarter";
    const isYear = type === "year";
    const datapointGroupsOfSelectedPeriodType = datapointGroupsOfEntity
        ?.filter(
            (dg) =>
                dg.period.year === selectedYear &&
                ((isMonth && dg.period.month) ||
                    (isQuarter && dg.period.quarter) ||
                    (isYear && !dg.period.month && !dg.period.quarter)),
        )
        .sort((a, b) => orderPeriods(a.period, b.period));
    const canDeleteAllDatapointGroupsOfPeriodType =
        !datapointGroupsOfSelectedPeriodType.some((dg) => !dg.isDeletable);
    const nonActivePeriods = isMonth
        ? Object.values(months).filter(
              (month) =>
                  !datapointGroupsOfSelectedPeriodType.find(
                      (dg) => dg.period.month === month,
                  ),
          )
        : isQuarter
          ? Object.values(quarters).filter(
                (quarter) =>
                    !datapointGroupsOfSelectedPeriodType.find(
                        (dg) => dg.period.quarter === quarter,
                    ),
            )
          : [];
    const handleDeleteClick = (id: string) => () => {
        mutateDelete(id, {
            onSuccess: (data) => {
                if (data?.deleteDatapointGroup?.error) {
                    setToastError(data?.deleteDatapointGroup?.error?.message);
                } else {
                    setToastSuccess(t(keys.delete_period_success));
                }
            },
            onError: () => {
                setToastError(t(keys.delete_period_error));
            },
        });
    };

    const handleDeleteAllClick = (ids: string[]) => {
        mutateDeleteMany(ids, {
            onSuccess: (data) => {
                if (data?.deleteManyDatapointGroups?.error) {
                    setToastError(
                        data?.deleteManyDatapointGroups?.error?.message,
                    );
                } else {
                    setToastSuccess(t(keys.delete_period_success_all));
                }
            },
            onError: () => {
                setToastError(t(keys.delete_period_error_all));
            },
        });
    };

    const handleCreatePeriodClick = (period: string) => () => {
        mutateCreate(
            {
                entityId: activeReportingEntityId,
                period: {
                    year: selectedYear ?? 0,
                    ...(isMonth && { month: period }),
                    ...(isQuarter && { quarter: period }),
                },
            },
            {
                onSuccess: () => {
                    setToastSuccess(t(keys.add_period_success));
                },
                onError: () => {
                    setToastError(t(keys.add_period_error));
                },
            },
        );
    };
    const multiSelectItemValues = {
        delete: "delete",
        deleteNotAllowed: "deleteNotAllowed",
    } as const;

    const [multiSelectValues, setMultiSelectValues] = useState<
        (string | null)[]
    >([]);
    const onMultiSelectValuesChange = (values: (string | null)[]) => {
        if (
            values?.length === 1 &&
            values[0] === multiSelectItemValues.delete &&
            canDeleteAllDatapointGroupsOfPeriodType
        ) {
            const ids = datapointGroupsOfSelectedPeriodType.map((dg) => dg.id);
            handleDeleteAllClick(ids);
        }
        setMultiSelectValues([]);
    };

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        !!datapointGroupsOfSelectedPeriodType?.length && (
            <FlexCol
                p="1"
                className="bg-neutral-25 border border-neutral-150"
                br="lg"
            >
                <FlexRow
                    p="2"
                    mb="1"
                    alignItems="center"
                    justifyContent="between"
                >
                    <FlexRow gap="2.5" alignItems="center">
                        <Icon name="calendar" size="sm" />
                        <Text variant="body" color="primary">
                            {t("period_box_title", {
                                context: type,
                            })}
                        </Text>
                    </FlexRow>
                    <MultiSelect
                        values={multiSelectValues}
                        onValuesChange={onMultiSelectValuesChange}
                    >
                        <MultiSelect.Trigger>
                            <Button
                                variant="plain"
                                size="xs"
                                className="p-0 min-h-4"
                            >
                                <Icon name="more" size="sm" />
                            </Button>
                        </MultiSelect.Trigger>
                        <MultiSelect.Content>
                            {canDeleteAllDatapointGroupsOfPeriodType && (
                                <MultiSelect.Item
                                    value={multiSelectItemValues.delete}
                                >
                                    <FlexRow
                                        gap="2.5"
                                        p="2"
                                        alignItems="center"
                                        className="text-primary hover:text-error-500 hover:bg-[#FEEFEC]"
                                    >
                                        <Icon
                                            name="trash"
                                            size="sm"
                                            className="text-error-500"
                                        />
                                        <Text variant="body">
                                            {t("delete_all_periods")}
                                        </Text>
                                    </FlexRow>
                                </MultiSelect.Item>
                            )}
                            {!canDeleteAllDatapointGroupsOfPeriodType && (
                                <MultiSelect.Item
                                    value={
                                        multiSelectItemValues.deleteNotAllowed
                                    }
                                >
                                    <FlexRow
                                        p="2"
                                        alignItems="center"
                                        justifyContent="between"
                                        className="text-neutral-300 cursor-default"
                                    >
                                        <FlexRow gap="2.5" alignItems="center">
                                            <Icon
                                                name="trash"
                                                size="sm"
                                                className="text-neutral-300"
                                            />
                                            <Text variant="body">
                                                {t(keys.delete_all_periods)}
                                            </Text>
                                        </FlexRow>
                                        <Tooltip delayDuration={0}>
                                            <Tooltip.Trigger>
                                                <Icon
                                                    name="info"
                                                    size="sm"
                                                    className="text-neutral-300"
                                                />
                                            </Tooltip.Trigger>
                                            <Tooltip.Content>
                                                {t(
                                                    keys.delete_all_periods_tooltip,
                                                )}
                                            </Tooltip.Content>
                                        </Tooltip>
                                    </FlexRow>
                                </MultiSelect.Item>
                            )}
                        </MultiSelect.Content>
                    </MultiSelect>
                </FlexRow>
                {datapointGroupsOfSelectedPeriodType.map((dg) => (
                    <FlexRow
                        key={dg.id}
                        px="2"
                        py="1.5"
                        alignItems="center"
                        justifyContent="between"
                    >
                        <FlexRow gap="2" alignItems="center">
                            <Box
                                className="bg-neutral-150 text-secondary text-sm w-[28px] text-center"
                                br="full"
                            >
                                {dg.datapoints.length > 99
                                    ? "+99"
                                    : dg.datapoints.length}
                            </Box>
                            <Text variant="body" color="primary">
                                {isMonth &&
                                    t("month", { context: dg.period.month })}
                                {isQuarter && dg.period.quarter}
                                {isYear && t(keys.full_year)}
                            </Text>
                        </FlexRow>
                        {!dg.isDeletable ? (
                            <Tooltip delayDuration={0}>
                                <Tooltip.Trigger asChild>
                                    <Button
                                        variant="text"
                                        size="xs"
                                        className="p-0 min-h-4 hover:bg-neutral-25 cursor-default text-neutral-300 hover:shadow-none"
                                    >
                                        <Icon name="trash" size="sm" />
                                    </Button>
                                </Tooltip.Trigger>
                                <Tooltip.Content>
                                    {t(keys.delete_period_not_allowed_tooltip)}
                                </Tooltip.Content>
                            </Tooltip>
                        ) : (
                            <Button
                                variant="text"
                                size="sm"
                                className="p-0 min-h-4 hover:bg-neutral-25 text-secondary hover:shadow-none hover:text-error-500"
                                onClick={handleDeleteClick(dg.id)}
                            >
                                <Icon name="trash" size="sm" />
                            </Button>
                        )}
                    </FlexRow>
                ))}

                {!!nonActivePeriods.length && <hr className="my-3 px-2" />}
                {nonActivePeriods.map((period) => (
                    <Button
                        variant="text"
                        size="sm"
                        className="px-2 py-1.5 min-h-4 hover:bg-neutral-100 text-secondary justify-start hover:shadow-none hover:text-green"
                        key={period}
                        onClick={handleCreatePeriodClick(period)}
                    >
                        <FlexRow gap="2" alignItems="center">
                            <Icon name="circlePlus" size="sm" />
                            <Text variant="body" color="primary">
                                {isMonth && t("month", { context: period })}
                                {isQuarter && period}
                            </Text>
                        </FlexRow>
                    </Button>
                ))}
            </FlexCol>
        )
    );
};
