import { type GraphQLOrganizationRepository as OrganizationRepository } from "@app/repositories/GraphQLRepositories/OrganizationRepository";
import { GetUserOrganisationsQueryVariables } from "@generated/client/graphql";
import { type Organization } from "./types";
export async function getOrgs(
    {
        organizationRepository,
    }: { organizationRepository: OrganizationRepository },
    input: GetUserOrganisationsQueryVariables,
) {
    const data = await organizationRepository.getUserOrgs(input);
    const organizations: Organization[] = data.orgs?.data ?? [];
    const count = data.orgs?.count ?? 0;
    return { organizations, count };
}

export type { Organization };
