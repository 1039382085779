import { useUpdateObjective } from "@app/screens/Objectives/data";
import { FlexRow } from "@design-system/Layout/Flex";
import { GetObjectiveQuery } from "@generated/client/graphql";
import { FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditableText } from "../EditableText";
import { RESOURCE_NAME, keys } from "./i18n";

type NameInputProps = {
    objective: GetObjectiveQuery["objective"];
};

export const NameInput: FC<NameInputProps> = ({ objective }) => {
    const [name, setName] = useState<string | undefined>(
        objective?.name || undefined,
    );
    const { mutate } = useUpdateObjective(objective?.id);

    const handleNameSave = () => {
        if (name !== objective.name) {
            const newName = name || null;
            mutate({ id: objective.id, name: newName });
        }
    };

    const handleNameChange = (event: FormEvent<HTMLDivElement>) => {
        setName(event.currentTarget.textContent || undefined);
    };

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexRow py="1.5" className="min-h-8">
            <EditableText
                value={name}
                onInput={handleNameChange}
                onBlur={handleNameSave}
                placeholder={t(keys.name_placeholder)}
                variant="header1"
            />
        </FlexRow>
    );
};
