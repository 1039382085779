import { reportingKeys } from "@app/pages/Reporting/data";
import { GraphQLIndicatorRepository } from "@app/repositories/GraphQLRepositories/indicator/IndicatorRepository";
import { GraphQLObjectiveRepository } from "@app/repositories/GraphQLRepositories/ObjectiveRepository";
import { tanstackUnitaryOptimisticUpdateOptionsMaker } from "@app/shared/utils/optimisticUpdates";
import { Indicator2Filter, ObjectivesFilter } from "@generated/client/graphql";
import {
    skipToken,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { makeOptimisticObjectiveUpdate } from "./services";

export const objectiveKeys = {
    all: () => ["objectives"] as const,
    objectiveDetails: (id?: string) =>
        [...objectiveKeys.all(), "objectiveDetails", id] as const,
    filteredObjectives: (filter?: ObjectivesFilter) =>
        [...objectiveKeys.all(), "filter", filter] as const,
    indicatorsFiltered: (filter: Indicator2Filter, entityId: string) =>
        [
            ...objectiveKeys.all(),
            "indicatorsFiltered",
            filter,
            entityId,
        ] as const,
    objectiveIndicator: (id: string) =>
        [...objectiveKeys.all(), "objectiveIndicator", id] as const,
    objectiveTargets: (id: string) =>
        [...objectiveKeys.all(), "objectiveTargets", id] as const,
    createObjective: () => [...objectiveKeys.all(), "createObjective"] as const,
    updateObjective: (id: string) =>
        [...objectiveKeys.all(), "updateObjective", id] as const,
    deleteObjective: (id: string) =>
        [...objectiveKeys.all(), "deleteObjective", id] as const,
};

const objectiveRepository = new GraphQLObjectiveRepository();
const indicatorRepository = new GraphQLIndicatorRepository();

export const useObjective = (id?: string) => {
    const { data, isPending } = useQuery({
        queryKey: objectiveKeys.objectiveDetails(id),
        queryFn: !!id ? () => objectiveRepository.getObjective(id) : skipToken,
    });
    return { objective: data?.objective, isPending };
};

export const useObjectives = (filter?: ObjectivesFilter) => {
    const { data, isPending } = useQuery({
        queryKey: objectiveKeys.filteredObjectives(filter),
        queryFn: () => objectiveRepository.getObjectives(filter),
    });
    return { objectives: data?.objectives, isPending };
};

export const useCreateObjective = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: [...objectiveKeys.createObjective()],
        mutationFn: objectiveRepository.create,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: objectiveKeys.all(),
            });
        },
    });

    return mutation;
};

export const useEntityIndicators = (entityId: string | undefined) => {
    const { data } = useQuery({
        queryKey: reportingKeys.indicatorsByEntityId(entityId || ""),
        queryFn: entityId ? () => indicatorRepository.get(entityId) : skipToken,
    });
    return data?.indicators2;
};

export const useUpdateObjective = (id: string | undefined) => {
    const queryClient = useQueryClient();
    const { objective } = useObjective(id);
    const indicators = useEntityIndicators(objective?.entityId);

    const mutation = useMutation({
        mutationKey: [...objectiveKeys.updateObjective(id ?? "")],
        mutationFn: objectiveRepository.update,
        ...tanstackUnitaryOptimisticUpdateOptionsMaker(
            () => objectiveKeys.objectiveDetails(id ?? ""),
            makeOptimisticObjectiveUpdate(indicators),
        ),

        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: objectiveKeys.objectiveDetails(id ?? ""),
            });
        },
    });

    return mutation;
};

export const useDeleteObjective = (id: string) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: [...objectiveKeys.deleteObjective(id)],
        mutationFn: objectiveRepository.delete,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: objectiveKeys.all(),
            });
        },
    });

    return mutation;
};
