import { GraphQLThemeRepository } from "@app/repositories/GraphQLRepositories/ThemeRepository";
import { atom } from "jotai";
import { atomWithQuery } from "jotai-tanstack-query";
import { themeKeys } from "./queryKeys";
import { useQuery } from "@tanstack/react-query";

const themeRepository = new GraphQLThemeRepository();

export const activeReportingEntityIdAtom = atom<string>("");

export const themesAtom = atomWithQuery(() => {
    return {
        queryKey: [themeKeys.themes],
        queryFn: async () => themeRepository.get(),
    };
});

export const useThemes = () => {
    const { data, isPending } = useQuery({
        queryKey: ["themes"],
        queryFn: themeRepository.get,
    });

    const activeThemes = data?.themes.filter((theme) => theme.active && theme.subthemes.some((subtheme) => subtheme.active));
    const sortedThemes = activeThemes?.sort((a, b) => a.order - b.order);

    return {
        themes: sortedThemes,
        isPending,
    };
};

export const useTheme = (themeId: string) => {
    const { data } = useQuery({
        queryKey: ["theme", themeId],
        queryFn: () => themeRepository.find(themeId),
    });
    const activeSubthemes = data?.theme?.subthemes.filter((subtheme) => subtheme.active);
    const themeWithSortedSubthemes = {
        ...data?.theme,
        subthemes: activeSubthemes?.sort((a, b) => a.order - b.order),
    };

    return themeWithSortedSubthemes;
};
