import { useTranslation } from "react-i18next";

import { Text } from "@design-system/Typography/Text";
import { FlexCol } from "@design-system/Layout/Flex";

import "./i18n";
import { RESOURCE_NAME, keys } from "./i18n";
import { ReactComponent as Logo } from "../../../../../assets/top-logo.svg";

export const AskAiPlaceholder = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    return (
        <>
            <Logo className={`text-green mb-2`} />
            <FlexCol ml="1">
                <Text variant="header3" color="primary">
                    {t(keys.title)}
                </Text>
                <Text variant="body" color="secondary" mt="0.5">
                    {t(keys.text)}
                </Text>
            </FlexCol>
        </>
    );
};
