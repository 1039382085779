import { useSetToast } from "@app/components/Toast";
import { useNavigation } from "@app/lib/navigation";
import BodyHeader from "@app/shared/components/BodyHeader";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { Text } from "@design-system/Typography/Text";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParam } from "use-query-params";
import { ObjectiveCard } from "./components/ObjectiveCard";
import { ObjectivesPageSideBar } from "./components/ObjectivesPageSideBar";
import { ObjectiveCardSkeleton } from "./components/Skeletons/ObjectiveCardSkeleton";
import { activeEntityIdAtom, activeTabAtom } from "./ctx";
import { useCreateObjective, useObjectives } from "./data";
import { RESOURCE_NAME, keys } from "./i18n";

export default function Objectives() {
    const activeEntityId = useAtomValue(activeEntityIdAtom);

    const activeTab = useAtomValue(activeTabAtom);
    const { objectives, isPending: isObjectivesPending } = useObjectives({
        entityId: activeEntityId,
        themeId: activeTab,
    });

    const { t } = useTranslation(RESOURCE_NAME);
    const { setToastSuccess, setToastError } = useSetToast();
    const { mutate, isPending: isCreateObjectivePending } =
        useCreateObjective();
    const { navigateFromHome } = useNavigation();
    const handleCreateObjectiveClick = () => {
        if (!activeEntityId) {
            setToastError(t(keys.toast_error_entity));
            return;
        }
        if (!activeTab) {
            setToastError(t(keys.toast_error_theme));
            return;
        }
        mutate(
            {
                themeId: activeTab,
                entityId: activeEntityId,
            },
            {
                onSuccess: (data) => {
                    setToastSuccess(t(keys.toast_success));
                    const objectiveId = data.createObjective.id;
                    navigateFromHome(`objectives/${objectiveId}`);
                },
                onError: () => {
                    setToastError(t(keys.toast_error_unknown));
                },
            },
        );
    };

    const [urlSearchString, _] = useQueryParam("search", StringParam);

    const objectivesFiltered = useMemo(
        () =>
            !urlSearchString
                ? objectives
                : objectives?.filter(
                      (objective) =>
                          objective?.name
                              ?.toLowerCase()
                              .includes(urlSearchString || "") ||
                          objective?.description
                              ?.toLowerCase()
                              .includes(urlSearchString || ""),
                  ),
        [objectives, urlSearchString],
    );
    return (
        <PageContainer>
            <BodyHeader
                title={t(keys.page_title)}
                subTitle={t(keys.page_subtitle)}
                action={
                    <Button
                        size="md"
                        onClick={handleCreateObjectiveClick}
                        loading={isCreateObjectivePending}
                    >
                        <Text variant="body-bold">
                            {t(keys.create_objective)}
                        </Text>
                    </Button>
                }
            />
            <FlexCol gap="4" p="4" alignItems="center" w="full" h="full">
                <FlexRow alignItems="start" gap="4" w="full" h="full">
                    <ObjectivesPageSideBar />
                    <FlexCol
                        alignItems="start"
                        className="border border-tertiary bg-tertiary"
                        w="full"
                        h="full"
                    >
                        <Box
                            display="grid"
                            p="4"
                            className="grid-cols-2 gap-x-5 gap-y-4 w-full"
                        >
                            {objectivesFiltered?.map((objective, idx) => (
                                <ObjectiveCard
                                    key={idx}
                                    objectiveId={objective.id}
                                />
                            ))}
                            {isObjectivesPending &&
                                Array.from({ length: 5 }).map((_, idx) => (
                                    <ObjectiveCardSkeleton key={idx} />
                                ))}
                        </Box>
                        {!objectives?.length &&
                            !isObjectivesPending &&
                            activeTab &&
                            !urlSearchString && (
                                <FlexCol
                                    gap="4"
                                    alignItems="center"
                                    justifyContent="center"
                                    w="full"
                                    h="full"
                                    p="11"
                                    pt="8"
                                >
                                    <Icon
                                        name="circleBig"
                                        size="lg"
                                        className="h-40 w-40"
                                    />
                                    <FlexCol gap="4" className="max-w-[522px]">
                                        <Text variant="body-lg-bold">
                                            {t(keys.empty_state_title)}
                                        </Text>
                                        <Text variant="body-lg">
                                            {t(keys.empty_state_subtitle)}
                                        </Text>
                                        <Button
                                            size="md"
                                            onClick={handleCreateObjectiveClick}
                                            className="w-fit"
                                        >
                                            <Text variant="body-bold">
                                                {t(keys.empty_state_button)}
                                            </Text>
                                        </Button>
                                    </FlexCol>
                                </FlexCol>
                            )}
                        {!objectivesFiltered?.length &&
                            activeTab &&
                            !isObjectivesPending &&
                            urlSearchString && (
                                <FlexCol
                                    gap="4"
                                    alignItems="center"
                                    justifyContent="center"
                                    w="full"
                                    h="full"
                                    p="11"
                                    pt="8"
                                >
                                    <Icon name="search" />
                                    <FlexCol gap="1" alignItems="center">
                                        <Text variant="body-sm-bold">
                                            {t(keys.no_objectives_found_title)}
                                        </Text>
                                        <Text
                                            variant="body-sm"
                                            className="font-light"
                                        >
                                            {t(
                                                keys.no_objectives_found_subtitle,
                                            )}
                                        </Text>
                                    </FlexCol>
                                </FlexCol>
                            )}
                    </FlexCol>
                </FlexRow>
            </FlexCol>
        </PageContainer>
    );
}
