import { queryClient } from "@app/QueryClientWithHeaders";
import { GraphQLAiRepository } from "@app/repositories/GraphQLRepositories/ai";
import { RestAiRepository } from "@app/repositories/RestRepositories/aiRepository";
// We are using the same fn that handles optimistic updates, as it takes care of
// canceling outgoing refetchings, getting previous query values and setting the cache
import { tanstackUnitaryOptimisticUpdate as updateCache } from "@app/shared/utils/optimisticUpdates";
import { AiThreadMessageRole } from "@generated/client/graphql";
import { skipToken, useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { AssistantStream } from "openai/lib/AssistantStream";
import { type TextDelta } from "openai/resources/beta/threads/messages";
import { useEffect, useMemo, useState } from "react";
import { isStreamingAtom } from "./ctx";
import {
    filterAnnotation,
    makeAnnotation,
    makeThread,
    prependMessageToThreadQuery,
    updateFirstMessageOfThreadQuery,
} from "./services";
import { Thread } from "./types";

const graphqlAiRepository = new GraphQLAiRepository();
const restAiRepository = new RestAiRepository();

const askAiKeys = {
    all: ["askAi"] as const,
    threads: () => [...askAiKeys.all, "threads"] as const,
    thread: (id?: string) => [...askAiKeys.all, "thread", id] as const,
    vectorStore: () => [...askAiKeys.all, "vectorStore"] as const,
    annotationDocument: (openAiFileId?: string) =>
        [...askAiKeys.all, "annotationDocument", openAiFileId] as const,
};

export function useThreads() {
    const { data, ...query } = useQuery({
        queryKey: askAiKeys.threads(),
        queryFn: graphqlAiRepository.getUserThreads,
    });

    const threads = useMemo(
        () => (data?.aiThreads ?? []).map(makeThread),
        [data],
    );

    return { threads, ...query };
}

export function useHasVectorStore({ skip }: { skip?: boolean }) {
    const { data } = useQuery({
        queryKey: askAiKeys.vectorStore(),
        queryFn: skip ? skipToken : graphqlAiRepository.getVectorStore,
    });

    return data?.aiVectorStore.openAiStoreId != null;
}

export function useInitializeVectorStore() {
    const initializeVectorStoreWithSharedDocsMutation = useMutation({
        mutationFn:
            graphqlAiRepository.initializeVectorStoreWithSharedDocuments,
    });

    const initializeVectorStoreWithTenantDocsMutation = useMutation({
        mutationFn:
            graphqlAiRepository.initializeVectorStoreWithTenantDocuments,
    });

    const isLoading =
        initializeVectorStoreWithSharedDocsMutation.isPending ||
        initializeVectorStoreWithTenantDocsMutation.isPending;

    return {
        initializeVectorStore:
            initializeVectorStoreWithTenantDocsMutation.mutate,
        reimportSharedDocs: initializeVectorStoreWithSharedDocsMutation.mutate,
        isLoading,
    };
}

export function useThread(id?: string) {
    const { data, ...query } = useQuery({
        queryKey: askAiKeys.thread(id),
        queryFn: id ? () => graphqlAiRepository.getThread(id) : skipToken,
    });

    const thread = useMemo(() => {
        if (!data) return undefined;
        return makeThread(data.aiThread);
    }, [data]);

    return { thread, ...query };
}

export function useCreateThread() {
    const { mutateAsync, ...mutation } = useMutation({
        mutationFn: graphqlAiRepository.createThread,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: askAiKeys.threads() });
        },
    });

    return { createThreadAsync: mutateAsync, ...mutation };
}

export function useAsyncCreateEmptyThread() {
    const { mutateAsync, ...mutation } = useMutation({
        mutationFn: async () => {
            const { createAiThread } = await graphqlAiRepository.createThread();

            // Cancel any outgoing refetches for all queries that depend on the threads
            await queryClient.cancelQueries({
                queryKey: askAiKeys.threads(),
            });

            const [threads, thread] = await Promise.all([
                graphqlAiRepository.getUserThreads(),
                graphqlAiRepository.getThread(createAiThread.id),
            ]);

            queryClient.setQueryData(askAiKeys.threads(), threads);
            queryClient.setQueryData(
                askAiKeys.thread(createAiThread.id),
                thread,
            );

            return createAiThread;
        },
    });

    return { createThreadAsync: mutateAsync, ...mutation };
}

export function useSendPrompt() {
    const [isStreaming, setIsStreaming] = useAtom(isStreamingAtom);

    const { mutate: sendPrompt, ...mutation } = useMutation({
        mutationFn: ({
            prompt,
            thread,
        }: {
            prompt: string;
            thread: Thread;
        }) => {
            return restAiRepository.sendMessage(prompt, thread.openAiThreadId);
        },
        onMutate: async ({ prompt, thread }) => {
            await updateCache(
                { role: AiThreadMessageRole.User, text: prompt },
                () => askAiKeys.thread(thread.id),
                prependMessageToThreadQuery,
            );
        },
        onSuccess: async (stream, { thread }) => {
            const handleTextDelta = async ({
                value,
                annotations,
            }: TextDelta) => {
                if (value == null) return;
                const formattedAnnotations =
                    annotations
                        ?.map(makeAnnotation)
                        ?.filter(filterAnnotation) ?? [];

                await updateCache(
                    { text: value, annotations: formattedAnnotations },
                    () => askAiKeys.thread(thread.id),
                    updateFirstMessageOfThreadQuery,
                );
                if (value.length > 0) setIsStreaming(false);
            };

            const handleTextCreated = () => {
                updateCache(
                    { role: AiThreadMessageRole.Assistant, text: "" },
                    () => askAiKeys.thread(thread.id),
                    prependMessageToThreadQuery,
                );
            };

            const handleRunCompleted = () => {
                queryClient.invalidateQueries({
                    queryKey: askAiKeys.thread(thread.id),
                });
            };

            /**
             * TODO: I am executing some event handlers that are actually async;
             * I am counting on the fact that the async functions will always take
             * the same amount of time, but what if they don't? We should come up
             * with a way to queue the event handlers here, so that the new ones
             * await the completion of the previous ones.
             */
            const handleReadableStream = (stream: AssistantStream) => {
                setIsStreaming(true);
                // Create an empty message from the assistant
                stream.on("textCreated", handleTextCreated);
                // Populate the message with the text as it comes
                stream.on("textDelta", handleTextDelta);
                // When the run is completed, refetch the thread
                stream.on("event", ({ event }) => {
                    if (event === "thread.run.completed") handleRunCompleted();
                });
            };

            handleReadableStream(stream);
        },
    });

    return { sendPrompt, isStreaming, ...mutation };
}

export function useDeleteThreadMutation() {
    return useMutation({
        mutationFn: graphqlAiRepository.deleteThread,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: askAiKeys.threads() });
        },
    });
}

export function useDeleteEmptyThreads_temporary() {
    const { threads, isFetching } = useThreads();
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        async function deleteAllEmptyThreads() {
            if (isFetching) return;
            const emptyThreads = threads.filter(
                (thread) => thread.messages?.length === 0,
            );
            if (emptyThreads.length === 0) return;

            setIsDeleting(true);
            const promises = emptyThreads.map((thread) =>
                graphqlAiRepository.deleteThread(thread.id),
            );

            await Promise.allSettled(promises);
            await queryClient.invalidateQueries({
                queryKey: askAiKeys.threads(),
            });
            setIsDeleting(false);
        }

        deleteAllEmptyThreads();
    }, [setIsDeleting, threads]);

    return { isDeleting };
}

export function useAnnotationDocument(openAiFileId?: string) {
    const { data, ...query } = useQuery({
        queryKey: askAiKeys.annotationDocument(openAiFileId),
        queryFn: openAiFileId
            ? () => graphqlAiRepository.getAnnotationMetadata(openAiFileId)
            : skipToken,
    });

    const annotationDocument = data?.aiFileMetadata.document;

    return { annotationDocument, ...query };
}
