import { useNavigation } from "@app/lib/navigation";
import { SubthemeTag } from "@app/shared/components/SubthemeTag";
import { UserTag } from "@app/shared/components/UserTag";
import { displayStatus } from "@app/usecases/ObjectivesUseCases";
import { StatusTagCircular } from "@design-system/DataDisplay/StatusTagCircular";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useObjective } from "../../data";
import { ObjectiveCardSkeleton } from "../Skeletons/ObjectiveCardSkeleton";
import { keys, RESOURCE_NAME } from "./i18n";
import { TargetDisplay } from "./TargetDisplay";
import { Box } from "@design-system/Layout/Box";

type ObjectiveCardProps = {
    objectiveId: string;
};

export const ObjectiveCard: FC<ObjectiveCardProps> = ({ objectiveId }) => {
    const { navigateFromHome } = useNavigation();
    const { objective, isPending } = useObjective(objectiveId);

    const handleCardClick = () => {
        navigateFromHome(`objectives/${objectiveId}`);
    };

    const { t } = useTranslation(RESOURCE_NAME);

    return isPending ? (
        <ObjectiveCardSkeleton />
    ) : (
        <FlexRow
            p="4"
            br="md"
            elevation="b-200"
            justifyContent="between"
            className="cursor-pointer border border-secondary hover:border-beavrGreen bg-white h-[164px]"
            onClick={handleCardClick}
        >
            <FlexCol
                alignItems="start"
                justifyContent="between"
                className="w-11/12"
            >
                {objective?.name ? (
                    <Ellipsis asChild hasTooltip lineClamp={2}>
                        <Text variant="body-lg">{objective?.name}</Text>
                    </Ellipsis>
                ) : (
                    <Text variant="body-lg" color="tertiary">
                        {t(keys.default_title_placeholder)}
                    </Text>
                )}
                <FlexCol gap="4" className="w-full">
                    <TargetDisplay objective={objective} />
                    <FlexRow gap="2" alignItems="center">
                        <UserTag
                            userId={objective?.ownerId ?? undefined}
                            displayFullName={false}
                            noUserText={t(keys.no_user)}
                        />
                        {objective && (
                            <SubthemeTag
                                themeId={objective.themeId}
                                subthemeId={objective.subthemeId || undefined}
                                className="max-w-full truncate"
                            />
                        )}
                    </FlexRow>
                </FlexCol>
            </FlexCol>
            <Box className="w-fit">
                <Tooltip delayDuration={0}>
                    <Tooltip.Trigger>
                        <StatusTagCircular
                            status={displayStatus(objective?.status)}
                            size="lg"
                        />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                        <Text variant="body">
                            {t(keys.status, { context: objective?.status })}
                        </Text>
                    </Tooltip.Content>
                </Tooltip>
            </Box>
        </FlexRow>
    );
};
