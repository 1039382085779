import chroma from "chroma-js";
import { Eye, EyeClosed } from "iconoir-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    GetSupplierQuestionnaireDataRequestsForContributionDocument,
    SupplierQuestionnaire,
} from "../../../../generated/client/graphql";
import { useGraphQL } from "../../../QueryClientWithHeaders";
import { ButtonType } from "../../../components/Button/ButtonShared";
import ButtonSmall from "../../../components/Button/ButtonSmall";
import GroupBlock from "../components/GroupBlock";
import GroupsLoading from "../components/GroupBlock/loading";
import { useUpdateRequest } from "../shared/hooks";
import { RequestSubthemeEntityGroup } from "../utils/group";

const DataEntry = ({
    color,
    supplierQuestionnaire,
    groups,
    loading,
}: {
    color: chroma.Color;
    supplierQuestionnaire?: SupplierQuestionnaire;
    groups?: RequestSubthemeEntityGroup[];
    loading: boolean;
}) => {
    const { t } = useTranslation("SupplierContribution");

    const [hideAnswered, setHideAnswered] = useState(false);
    const supplierRequestsKey = useGraphQL.getKey(
        GetSupplierQuestionnaireDataRequestsForContributionDocument,
        {
            questionnaireId: supplierQuestionnaire?.id!,
        },
    );
    const { updateRequest } = useUpdateRequest(supplierRequestsKey);

    return (
        <div className="py-9 flex flex-col items-center space-y-6">
            <div className="flex flex-col items-center max-w-[720px] space-y-3">
                <h2>{loading ? t("campaign") : supplierQuestionnaire?.name}</h2>
                <div className="text-sm font-regular">{t("instructions")}</div>
            </div>
            <ButtonSmall
                IconLeft={hideAnswered ? Eye : EyeClosed}
                variant={ButtonType.TONAL}
                onClick={() => setHideAnswered(!hideAnswered)}
            >
                {hideAnswered ? t("show_answered") : t("hide_answered")}
            </ButtonSmall>
            <div className="max-w-[880px] space-y-5">
                {loading && <GroupsLoading />}
                {groups && groups.length > 0 ? (
                    groups.map((group, idx) => (
                        <GroupBlock
                            color={color}
                            group={group}
                            key={idx}
                            idx={idx}
                            updateRequest={updateRequest}
                            hideWrongContributor={true}
                            hideAnswered={hideAnswered}
                        />
                    ))
                ) : (
                    <div className="font-bold p-4 text-center">
                        🎉 🎉 🎉
                        <br />
                        {t("nothing_to_complete")}
                        <br />
                        🎉 🎉 🎉
                    </div>
                )}
            </div>
        </div>
    );
};

export default DataEntry;
