import {
    AdminPath,
    ReportingPath,
    SettingPaths,
    SupplierPath,
} from "@app/routing/paths";
import {
    navigationButtonType,
    type NavigationButtonTypeKey,
} from "@app/store/routingStore";
import { type IconName } from "@design-system/Icon";

export const navigationButtons: Record<
    NavigationButtonTypeKey,
    {
        icon: IconName;
        i18Key: string;
        path:
            | AdminPath
            | `${AdminPath}/${ReportingPath}`
            | `${AdminPath}/${SupplierPath}`
            | `${AdminPath}/${SettingPaths}`
            | "";
    }
> = {
    dashoard: {
        i18Key: "dashboard",
        icon: "dashboard",
        path: "",
    },
    frameworks: {
        i18Key: "frameworks",
        icon: "file",
        path: "frameworks",
    },
    requirements: {
        i18Key: "requirements",
        icon: "cloudWithValidate",
        path: "requirements",
    },
    sustainability_center: {
        i18Key: "sustainability_center",
        icon: "megaphone",
        path: "sustainability_center",
    },
    policies: {
        i18Key: "policies",
        icon: "scale",
        path: "policies",
    },
    objectives: {
        i18Key: "objectives",
        icon: "target2",
        path: "objectives",
    },
    measures: {
        i18Key: "measures",
        icon: "pencil",
        path: "measures",
    },
    custom_documents: {
        i18Key: "custom_documents",
        icon: "duplicate",
        path: "custom_documents",
    },
    all_files: {
        i18Key: "all_files",
        icon: "document",
        path: "all_files",
    },
    reporting_overview: {
        i18Key: "reporting_overview",
        icon: "megaphone",
        path: "reporting/overview",
    },
    reporting_progress: {
        i18Key: "reporting_progress",
        icon: "users",
        path: "reporting/progress",
    },
    reporting_indicators: {
        i18Key: "indicators",
        icon: "list",
        path: "reporting/indicators",
    },
    reporting_documents: {
        i18Key: "documents",
        icon: "document",
        path: "reporting_documents",
    },
    supplier_questionnaires: {
        i18Key: "questionnaires",
        icon: "document",
        path: "supplier/questionnaires",
    },
    suppliers: {
        i18Key: "suppliers",
        icon: "company",
        path: "supplier/suppliers",
    },
    supplier_datapoints: {
        i18Key: "supplier_datapoints",
        icon: "listOrder",
        path: "supplier/datapoints",
    },
    setting_general: {
        i18Key: "setting_general",
        icon: "listOrder",
        path: "settings/general",
    },
    setting_material_topics: {
        i18Key: "setting_material_topics",
        icon: "table",
        path: "settings/themes",
    },
    setting_sites: {
        i18Key: "setting_sites",
        icon: "building",
        path: "settings/sites",
    },
    setting_users: {
        i18Key: "setting_users",
        icon: "users",
        path: "settings/users",
    },
    setting_units: {
        i18Key: "setting_units",
        icon: "ruler",
        path: "settings/units",
    },
};

export interface NavigationGroup {
    i18Key?: string;
    items: NavigationButtonTypeKey[];
}

export const getAdminNavigation = (
    isSustainainbilityCenterFlagTrue?: boolean,
    isSupplierFlagTrue?: boolean,
    isLegacyReportingFlagTrue?: boolean,
    isObjectivesFlagTrue?: boolean,
): NavigationGroup[] => [
    {
        items: [
            navigationButtonType.dashoard,
            navigationButtonType.frameworks,
            navigationButtonType.requirements,
            ...(isSustainainbilityCenterFlagTrue
                ? [navigationButtonType.sustainability_center]
                : []),
            navigationButtonType.all_files,
        ],
    },
    {
        i18Key: "proof_system",
        items: [
            ...(isObjectivesFlagTrue ? [navigationButtonType.objectives] : []),
            navigationButtonType.policies,
            navigationButtonType.measures,
            navigationButtonType.custom_documents,
        ],
    },
    {
        i18Key: "reporting",
        items: [
            ...(isLegacyReportingFlagTrue
                ? [
                      navigationButtonType.reporting_indicators,
                      navigationButtonType.reporting_documents,
                  ]
                : [
                      navigationButtonType.reporting_overview,
                      navigationButtonType.reporting_progress,
                  ]),
        ],
    },
    ...(isSupplierFlagTrue
        ? [
              {
                  i18Key: "supplier",
                  items: [
                      navigationButtonType.supplier_questionnaires,
                      navigationButtonType.suppliers,
                      navigationButtonType.supplier_datapoints,
                  ],
              },
          ]
        : []),
    {
        i18Key: "settings",
        items: [
            navigationButtonType.setting_general,
            navigationButtonType.setting_material_topics,
            navigationButtonType.setting_sites,
            navigationButtonType.setting_users,
            navigationButtonType.setting_units,
        ],
    },
];

export const getContributorNavigation = (): NavigationGroup[] => [
    {
        i18Key: "proof_system",
        items: [
            navigationButtonType.policies,
            navigationButtonType.measures,
            navigationButtonType.custom_documents,
        ],
    },
    {
        i18Key: "reporting",
        items: [navigationButtonType.reporting_overview],
    },
];
