import i18next from "i18next";

const enResources = {
    modal_title: "Period settings",
    modal_subtitle: "Activate the periods you need for each year",
    save_button: "Save",
    sidebar_title: "Years",
    add_new_periods_start: "Start by adding a type of period",
    period_box_title_year: "Yearly period",
    period_box_title_quarter: "Quarterly period",
    period_box_title_month: "Monthly period",
    full_year: "Full year",
    month_January: "January",
    month_February: "February",
    month_March: "March",
    month_April: "April",
    month_May: "May",
    month_June: "June",
    month_July: "July",
    month_August: "August",
    month_September: "September",
    month_October: "October",
    month_November: "November",
    month_December: "December",
    add_period_success: "Period added successfully",
    add_period_error: "An error occurred while adding the period",
    delete_period_success: "Period deleted successfully",
    delete_period_error: "An error occurred while deleting the period",
    delete_period_success_all: "All periods deleted successfully",
    delete_period_error_all: "An error occurred while deleting periods",
    delete_all_periods: "Delete all periods",
    delete_all_periods_tooltip:
        "The periods cannot be deleted as some of them contain data",
    delete_period_not_allowed_tooltip:
        "The period cannot be deleted as it contains data",
    add_months: "Monthly",
    add_quarters: "Quarterly",
    add_years: "Yearly",
} as const;

type ResourceKeys = keyof typeof enResources;

const frResources = {
    modal_title: "Paramètres de période",
    modal_subtitle:
        "Activez les périodes dont vous avez besoin pour chaque année",
    save_button: "Enregistrer",
    sidebar_title: "Années",
    add_new_periods_start: "Commencez par ajouter un type de période",
    period_box_title_year: "Periodicité annuelle",
    period_box_title_quarter: "Periodicité trimestrielle",
    period_box_title_month: "Periodicité mensuelle",
    full_year: "Année complète",
    month_January: "Janvier",
    month_February: "Février",
    month_March: "Mars",
    month_April: "Avril",
    month_May: "Mai",
    month_June: "Juin",
    month_July: "Juillet",
    month_August: "Août",
    month_September: "Septembre",
    month_October: "Octobre",
    month_November: "Novembre",
    month_December: "Décembre",
    add_period_success: "Période ajoutée avec succès",
    add_period_error: "Une erreur s'est produite lors de l'ajout de la période",
    delete_period_success: "Période supprimée avec succès",
    delete_period_error:
        "Une erreur s'est produite lors de la suppression de la période",
    delete_period_success_all:
        "Toutes les périodes ont été supprimées avec succès",
    delete_period_error_all:
        "Une erreur s'est produite lors de la suppression des périodes",
    delete_all_periods: "Supprimer toutes les périodes",
    delete_all_periods_tooltip:
        "Les périodes ne peuvent pas être supprimées car certaines d'entre elles contiennent des données",
    delete_period_not_allowed_tooltip:
        "La période ne peut pas être supprimée car elle contient des données",
    add_months: "Mensuel",
    add_quarters: "Trimestriel",
    add_years: "Annuel",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourceKeys, ResourceKeys>;

export const RESOURCE_NAME = "PeriodManagementModal";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
