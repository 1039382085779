import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";

export const ObjectiveBodySkeleton = () => {
    return (
        <FlexCol gap="8">
            <IndicatorDisplaySkeleton />
            <ObjectiveDescriptionSkeleton />
            <ObjectiveTargetsSkeleton />
        </FlexCol>
    );
};

const IndicatorDisplaySkeleton = () => {
    return (
        <FlexCol
            gap="3"
            p="5"
            w="full"
            br="lg"
            className="border border-secondary"
        >
            <FlexRow gap="1.5" alignItems="center">
                <Skeleton h="4" w="12" br="lg" />
                <Skeleton h="4" w="40" br="lg" />
            </FlexRow>
            <FlexRow gap="1.5" alignItems="center">
                <Skeleton h="4" w="12" br="lg" />
                <Skeleton h="4" w="8" br="lg" />
            </FlexRow>
        </FlexCol>
    );
};

const ObjectiveDescriptionSkeleton = () => {
    return (
        <FlexCol gap="5">
            <Skeleton h="4" w="40" br="lg" />
            <hr />
            <FlexRow
                px="3"
                py="2.5"
                gap="3"
                br="lg"
                className="bg-accent-100 border border-accent-450"
            >
                <Skeleton h="2" w="4" br="lg" />
                <Skeleton h="4" w="40" br="lg" />
            </FlexRow>
            <FlexCol gap="2">
                <Skeleton h="2" w="full" br="lg" />
                <Skeleton h="2" w="full" br="lg" />
                <Skeleton h="2" w="full" br="lg" />
                <Skeleton h="2" w="full" br="lg" />
                <Skeleton h="2" w="full" br="lg" />
                <Skeleton h="2" w="full" br="lg" />
                <Skeleton h="2" w="40" br="lg" />
            </FlexCol>
        </FlexCol>
    );
};

const ObjectiveTargetsSkeleton = () => {
    return (
        <FlexCol gap="5">
            <FlexRow justifyContent="between">
                <Skeleton h="4" w="40" br="lg" />
                <Skeleton h="4" w="12" br="lg" />
            </FlexRow>
            <hr />
            <FlexRow
                px="3"
                py="2.5"
                gap="3"
                br="lg"
                className="bg-accent-100 border border-accent-450"
            >
                <Skeleton h="2" w="4" br="lg" />
                <Skeleton h="4" w="40" br="lg" />
            </FlexRow>
            <FlexCol gap="4">
                <FlexRow justifyContent="between">
                    <Skeleton h="2" w="20" br="lg" />
                    <Skeleton h="2" w="40" br="lg" />
                    <Skeleton h="2" w="4" br="lg" />
                </FlexRow>
                <FlexRow justifyContent="between">
                    <Skeleton h="2" w="20" br="lg" />
                    <Skeleton h="2" w="40" br="lg" />
                    <Skeleton h="2" w="4" br="lg" />
                </FlexRow>
                <FlexRow justifyContent="between">
                    <Skeleton h="2" w="20" br="lg" />
                    <Skeleton h="2" w="40" br="lg" />
                    <Skeleton h="2" w="4" br="lg" />
                </FlexRow>
            </FlexCol>
        </FlexCol>
    );
};
